import React, { useState, useRef } from "react";
import "./ChatbotPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { Configuration, OpenAIApi } from "openai";
import howToFlyContextData from "../howToFlyContextData.js";

import { db } from "../firebase/config";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, Pagination]);

const inspirationCards = [
  {
    title: "Start Business",
    text: "Let's say for example, I'm starting a business in non-alcoholic wines, how can HowToFly help?",
  },
  {
    title: "Engage Employees",
    text: "I'm Innovation Manager at a large international firm, how can HowToFly help me involve more people of my organization in the innovation process?",
  },
  {
    title: "Clarifying Example",
    text: "Can you explain in very simple terms and with a clarifying example, for example I'm a manager of a warhouse in the harbor, what HowToFly does?",
  },
  {
    title: "Desirability Check",
    text: "I've a start-up idea but not sure if there's a need for it... Can HowToFly help?",
  },
  {
    title: "Project Duration",
    text: "How long does a project with HowToFly typically take?",
  },
  {
    title: "Website or Webshop",
    text: "Can HowToFly help me build a branded website and webshop?",
  },
];

/* Start of ChatbotPage component --------------------------------------------------------
---------------------------------------------------------
-------------------------------------------------------
------------------------------------------------------*/

function ChatbotPage() {
  const [input, setInput] = useState("");
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const handleChangeSwiperIndex = (i) => {
    setSwiperIndex(i);
  };

  const resizeTextArea = (ref) => {
    ref.style.height = "inherit"; // Reset the height
    ref.style.height = `${ref.scrollHeight}px`; // Set the height based on scrollHeight
  };

  const uploadToFirestore = async (prompt, response) => {
    try {
      await db.collection("chatbotlogs").add({
        prompt,
        response,
        timestamp: Date(),
      });
    } catch (error) {
      console.error(
        "Something went wrong, when trying to upload the data to firestore",
        error
      );
    }
  };

  const handleInput = (e) => {
    setInput(e.target.value);
    resizeTextArea(e.target);
  };

  const handleInspirationCardClick = (e) => {
    const cardText = e.currentTarget.getElementsByTagName("p")[0].textContent;
    setInput(cardText);
    // After a short delay, resize the textarea
    setTimeout(() => {
      if (inputRef.current) {
        resizeTextArea(inputRef.current);
      }
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const configuration = new Configuration({
      organization: "org-h1bJK5iOzVXDfN6bVEer5gaP",
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    setLogs([
      ...logs,
      {
        prompt: input,
        answer:
          "Our AI assistant is preparing a custom answer. Wait 10-30 seconds...",
      },
    ]);
    try {
      const openai = new OpenAIApi(configuration);
      const trainingDataStr = howToFlyContextData
        .map((data) => data.prompt + data.completion)
        .join("");
      const finalMessage =
        trainingDataStr +
        input +
        "please explain me how HowToFly can help me and provide an answer of max 2000 characters in HTML format.";
      setInput("");
      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: finalMessage }],
      });
      const AIresponse = completion.data.choices[0].message.content;
      setLogs([...logs, { prompt: input, answer: AIresponse }]);
      await uploadToFirestore(input, AIresponse);
    } catch (error) {
      console.error("Something went wrong, please try again later", error);
      setLogs([
        ...logs,
        {
          prompt: input,
          answer:
            "We're very sorry. Something went wrong, please try again later",
        },
      ]);
      await uploadToFirestore(
        input,
        "Something went wrong when trying to catch a response from OpenAI."
      );
    }
    setIsLoading(false);
  };

  return (
    <div className='ChatbotPage' id='howToAssistant'>
      <div className='ChatbotPage-content'>
        <h1 className='ChatbotPage-title'>How to...</h1>
        {!logs[0] && (
          <p className='ChatbotPage-text'>
            Explaining all potential use cases where we can help is almost
            impossible. So just state your problem, question, or situation, and
            our generative AI assistant will immediately provide you with an
            answer on how we can help.
          </p>
        )}
        {logs[0] && (
          <p className='ChatbotPage-text'>
            Hit our AI-assistant with another question or problem,...
          </p>
        )}
        <div className='ChatbotPage-answers'>
          {logs.map((log, index) => {
            return (
              <div key={index} className='ChatbotPage-answers-answerBox'>
                <h1>{log.prompt}</h1>
                <p dangerouslySetInnerHTML={{ __html: log.answer }}></p>
              </div>
            );
          })}
        </div>
        <form onSubmit={handleSubmit} className='ChatbotPage-form'>
          <textarea
            ref={inputRef}
            type='text'
            value={input}
            onChange={handleInput}
            placeholder='Type your question or problem here...'
          />
          <button type='submit'>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </form>
        <div className='ChatbotPage-inspiration'>
          <h1 className='ChatbotPage-inspiration-title'>
            <FontAwesomeIcon
              className='ChatbotPage-inspiration-title-icon'
              icon={faLightbulb}
            />
            Some inspiration for questions
          </h1>
          <div className='ChatbotPage-inspiration-cards'>
            {inspirationCards.map((inspirationCard, cardIndex) => (
              <div
                key={cardIndex}
                className='ChatbotPage-inspiration-cards-card'
                onClick={handleInspirationCardClick}
              >
                <h5>{inspirationCard.title}</h5>
                <p>{inspirationCard.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className='ChatbotPage-inspiration-mobile'>
          <h1 className='ChatbotPage-inspiration-title'>
            <FontAwesomeIcon
              className='ChatbotPage-inspiration-title-icon'
              icon={faLightbulb}
            />
            Some inspiration for questions
          </h1>
          <div className='ChatbotPage-inspiration-mobile-cards'>
            <Swiper
              style={{ width: "100%" }}
              pagination={{ type: "bullets" }}
              index={swiperIndex}
              onSlideChange={(swiper) => {
                handleChangeSwiperIndex(swiper.activeIndex);
              }}
              slidesPerView={1}
              autoplay={{ delay: 10000 }}
            >
              {inspirationCards.map((inspirationCard, cardIndex) => (
                <SwiperSlide key={cardIndex} className='swiper-slide'>
                  <div
                    key={cardIndex}
                    className='ChatbotPage-inspiration-mobile-cards-card'
                    onClick={handleInspirationCardClick}
                  >
                    <h5>{inspirationCard.title}</h5>
                    <p>{inspirationCard.text}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatbotPage;

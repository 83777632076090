import React, { useState, useEffect, useRef } from "react";
import { auth, db, storage } from "../firebase/config";
import PropTypes from "prop-types";
import "react-quill/dist/quill.snow.css"; // Import styles

//import bootstrap components
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// import styles
import "bootstrap/dist/css/bootstrap.min.css";

// import fontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faFilter,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";

// import image assets
import placeholderImageNeeds from "../assets/placeholderNeeds.png";

export default function BlackboxCISkuSharingModal({
  skuSharingModalShow,
  setSkuSharingModalShow,
  customTheme,
}) {
  const [newSku, setNewSku] = useState({
    brand: "",
    country: "",
    countryOther: "",
    manufacturer: "",
    categoryMekkoLegend: "",
    categoryMekkoLegendOther: "",
    baseIngredient: "",
    baseIngredientOther: "",
    flavour: "",
    bakingProcess: "",
    bakingProcessOther: "",
    shape: "",
    shapeOther: "",
  });
  const [packFormatIsOtherSelected, setPackFormatIsOtherSelected] =
    useState(false);
  const [baseIngredientIsOtherSelected, setBaseIngredientIsOtherSelected] =
    useState(false);
  const [categoryIsOtherSelected, setCategoryIsOtherSelected] = useState(false);
  const [bakingProcessIsOtherSelected, setBakingProcessIsOtherSelected] =
    useState(false);
  const [countryIsOtherSelected, setCountryIsOtherSelected] = useState(false);
  const [addedSkuData, setAddedSkuData] = useState([]); // This will be an array of objects.

  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const textAreaRef = useRef(null);

  // prop type validations

  BlackboxCISkuSharingModal.propTypes = {
    ciSkusData: PropTypes.array.isRequired,
    // ... other props validation
  };

  const handleSkuInputChange = (event) => {
    setNewSku({ ...newSku, [event.target.name]: event.target.value });
  };

  const handlePackFormatChange = (event) => {
    const value = event.target.value;
    setNewSku({ ...newSku, packFormat: value });

    // Check if 'Other' is selected.
    if (value === "Other") {
      setPackFormatIsOtherSelected(true);
    } else {
      setPackFormatIsOtherSelected(false);
    }
  };

  const handleBaseIngredientChange = (event) => {
    const value = event.target.value;
    setNewSku({ ...newSku, baseIngredient: value });

    // Check if 'Other' is selected.
    if (value === "Other") {
      setBaseIngredientIsOtherSelected(true);
    } else {
      setBaseIngredientIsOtherSelected(false);
    }
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setNewSku({ ...newSku, categoryMekkoLegend: value });

    // Check if 'Other' is selected.
    if (value === "Other") {
      setCategoryIsOtherSelected(true);
    } else {
      setCategoryIsOtherSelected(false);
    }
  };

  const handleBakingProcessChange = (event) => {
    const value = event.target.value;
    setNewSku({ ...newSku, bakingProcess: value });

    // Check if 'Other' is selected.
    if (value === "Other") {
      setBakingProcessIsOtherSelected(true);
    } else {
      setBakingProcessIsOtherSelected(false);
    }
  };

  const handleCountryChange = (event) => {
    const value = event.target.value;
    setNewSku({ ...newSku, country: value });

    // Check if 'Other' is selected.
    if (value === "Other") {
      setCountryIsOtherSelected(true);
    } else {
      setCountryIsOtherSelected(false);
    }
  };

  const handleCiSkuSubmit = async () => {
    let imageFileURL = placeholderImageNeeds; // Default to the current image value.

    // First, check if there's a selected file and upload it to Firebase Storage.
    if (selectedFile) {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`ciCompetitorSkus/${selectedFile.name}`);
      setUploading(true);

      try {
        await fileRef.put(selectedFile);
        imageFileURL = await fileRef.getDownloadURL(); // Directly assign the file URL.
        setUploading(false);
      } catch (error) {
        setUploading(false);
        return; // Exit if there's an error to prevent saving to Firestore.
      }
    }

    const userId = auth.currentUser.uid;
    let skuData = {};

    // use image file url driectly

    skuData = {
      ...newSku,
      imageUrl: imageFileURL, // Set the image URL here.
      userId,
      argumentsVisible: false,
      creationDate: new Date(),
    };

    // Push to Firebase
    let newDocRef = null;
    try {
      newDocRef = await db.collection("ciCompetitorSkus").add(skuData);
    } catch (e) {
      console.log("error message when trying to store sku", e.message);
    }

    if (newDocRef) {
      // Get the ID of the newly created sku
      const ciSkuId = newDocRef.id;

      // Add the skuId to the skuData object
      const completeSkuData = {
        ...skuData,
        ciSkuId: ciSkuId,
      };

      // Update local state with the complete data
      if (Array.isArray(addedSkuData)) {
        setAddedSkuData([...addedSkuData, completeSkuData]);
      } else {
        console.error("addedSkuData is not an array:", addedSkuData);
        setAddedSkuData([completeSkuData]); // Default to just the new sku data if the existing data is not iterable.
      }
    }

    setSelectedFile(null);
    setSkuSharingModalShow(false);
  };

  return (
    <Modal
      show={skuSharingModalShow}
      onHide={() => setSkuSharingModalShow(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='BlackboxCISolutions-shareSolutionsModal'
    >
      <Modal.Header
        closeButton
        className={
          customTheme
            ? "BlackboxCISolutions-shareSolutionsModal-header customTheme"
            : "BlackboxCISolutions-shareSolutionsModal-header"
        }
      >
        <Modal.Title id='contained-modal-title-vcenter'>
          Share Your SKU
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='BlackboxCISolutions-shareSolutionsModal-body'>
        <Form>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Brand
            </Form.Label>
            <Form.Control
              ref={textAreaRef}
              as='textarea'
              placeholder='Provide the brand name of the SKU'
              name='brand'
              onChange={handleSkuInputChange}
              value={newSku.brand}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              rows={1}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Manufacturer
            </Form.Label>
            <Form.Control
              ref={textAreaRef}
              as='textarea'
              placeholder='Provide the name of the SKU manufacturer'
              name='manufacturer'
              onChange={handleSkuInputChange}
              value={newSku.manufacturer}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              rows={1}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Flavour
            </Form.Label>
            <Form.Control
              ref={textAreaRef}
              as='textarea'
              placeholder='Provide the flavour of the SKU'
              name='flavour'
              onChange={handleSkuInputChange}
              value={newSku.flavour}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              rows={1}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Category
            </Form.Label>
            <Form.Control
              as='select'
              name='categoryMekkoLegend'
              onChange={handleCategoryChange} // We'll define this function next.
              value={newSku.categoryMekkoLegend}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
            >
              <option value=''>Select the Category</option>
              <option value='Sliced Chips'>Chips</option>
              <option value='Reconstituted'>Chips</option>
              <option value='Extruded'>Extruded</option>
              <option value='Tortilla'>Tortilla</option>
              <option value='Popcorn'>Popcorn</option>
              <option value='Nuts'>Nuts</option>
              <option value='Pretzels'>Pretzels</option>
              <option value='Crackers'>Crackers</option>
              <option value='Other'>Other</option>
            </Form.Control>
          </Form.Group>
          {categoryIsOtherSelected && (
            <Form.Group>
              <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
                Specify Other Category
              </Form.Label>
              <Form.Control
                as='textarea'
                placeholder='Specify the pack format'
                name='categoryMekkoLegendOther'
                onChange={handleSkuInputChange}
                value={newSku.packFormatOther}
                className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Pack Format
            </Form.Label>
            <Form.Control
              as='select'
              name='packFormat'
              onChange={handlePackFormatChange} // We'll define this function next.
              value={newSku.packFormat}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
            >
              <option value=''>Select the Pack Format</option>
              <option value='Large Sharing'>Large Sharing</option>
              <option value='Singles'>Singles</option>
              <option value='Multipack'>Multipack</option>
              <option value='Other'>Other</option>
            </Form.Control>
          </Form.Group>
          {packFormatIsOtherSelected && (
            <Form.Group>
              <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
                Specify Other Pack Format
              </Form.Label>
              <Form.Control
                as='textarea'
                placeholder='Specify the pack format'
                name='packFormatOther'
                onChange={handleSkuInputChange}
                value={newSku.packFormatOther}
                className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Base Ingredient
            </Form.Label>
            <Form.Control
              as='select'
              name='baseIngredient'
              onChange={handleBaseIngredientChange} // We'll define this function next.
              value={newSku.baseIngredient}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
            >
              <option value=''>Select the Base Ingredient</option>
              <option value='Potato'>Potato</option>
              <option value='Potato starch / flakes'>
                Potato starch / flakes
              </option>
              <option value='Wheat'>Wheat</option>
              <option value='Corn'>Corn</option>
              <option value='Lentil / Chickpea'>Lentil / Chickpea</option>
              <option value='Rice'>Rice</option>
              <option value='Other'>Other</option>
            </Form.Control>
          </Form.Group>
          {baseIngredientIsOtherSelected && (
            <Form.Group>
              <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
                Specify Other Base Ingredient
              </Form.Label>
              <Form.Control
                as='textarea'
                placeholder='Specify Other Base Ingredient'
                name='baseIngredientOther'
                onChange={handleSkuInputChange}
                value={newSku.baseIngredientOther}
                className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Baking Process
            </Form.Label>
            <Form.Control
              as='select'
              name='bakingProcess'
              onChange={handleBakingProcessChange} // We'll define this function next.
              value={newSku.bakingProcess}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
            >
              <option value=''>Select the Baking Process</option>
              <option value='Fried'>Fried</option>
              <option value='Oven-Baked'>Oven-Baked</option>
              <option value='Hand-Cooked'>Hand-Cooked</option>
              <option value='Popped'>Popped</option>
              <option value='Other'>Other</option>
            </Form.Control>
          </Form.Group>
          {bakingProcessIsOtherSelected && (
            <Form.Group>
              <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
                Specify Other Baking Process
              </Form.Label>
              <Form.Control
                as='textarea'
                placeholder='Specify the other baking process'
                name='bakingProcessOther'
                onChange={handleSkuInputChange}
                value={newSku.bakingProcessOther}
                className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Country
            </Form.Label>
            <Form.Control
              as='select'
              name='country'
              onChange={handleCountryChange} // We'll define this function next.
              value={newSku.country}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
            >
              <option value=''>Select the country</option>
              <option value='U.S.'>U.S.</option>
              <option value='U.K.'>U.K.</option>
              <option value='Germany'>Germany</option>
              <option value='France'>France</option>
              <option value='Italy'>Italy</option>
              <option value='Spain'>Spain</option>
              <option value='Poland'>Poland</option>
              <option value='Other'>Other</option>
            </Form.Control>
          </Form.Group>
          {countryIsOtherSelected && (
            <Form.Group>
              <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
                Specify Other Country
              </Form.Label>
              <Form.Control
                as='textarea'
                placeholder='Specify the other baking process'
                name='countryOther'
                onChange={handleSkuInputChange}
                value={newSku.countryOther}
                className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Image
            </Form.Label>
            <Form.Control
              type='file'
              onChange={(e) => setSelectedFile(e.target.files[0])}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              accept='.jpg, .jpeg, .png, .webp, .jfif' // Only accept .jpg, .jpeg,.webp, .jfif and .png files
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer
        className='BlackboxCISolutions-shareSolutionsModal-footer'
        style={{ background: "rgb(34,34,42)" }}
      >
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleCiSkuSubmit(e);
          }}
          className='BlackboxCISolutions-shareSolutionsModal-footer-submitButton'
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

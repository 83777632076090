// BlackboxRankingPage.js
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BlackboxRankingPage.css";

import { db } from "../firebase/config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { use } from "i18next";

const BlackboxRankingPage = ({
  currentUserID,
  playersData,
  ciSolutionsData,
  ciSolutionsVotesData,
  argumentsData,
  argumentsLikesData,
}) => {
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("xp");
  const [sortedPlayersData, setSortedPlayersData] = useState([]);
  const [loginEvents, setLoginEvents] = useState([]);

  // The useEffectFunction to calculate XP and to sorting the players

  const getUserAgreeAndDisAgreeVotesForPlayerX = (userId) => {
    // Find all ideas created by the user
    const userIdeas = ciSolutionsData.filter((idea) => idea.userId === userId);

    // Extract idea IDs of those ideas
    const userIdeaIds = userIdeas.map((idea) => idea.ideaId);

    // Find all agree votes for those idea IDs
    const agreeVotes = ciSolutionsVotesData.filter(
      (vote) => vote.vote === "agree" && userIdeaIds.includes(vote.ideaId)
    );
    const disAgreeVotes = ciSolutionsVotesData.filter(
      (vote) => vote.vote === "disagree" && userIdeaIds.includes(vote.ideaId)
    );

    return { agreeVotes, disAgreeVotes };
  };

  useEffect(() => {
    fetchLoginEvents();
  }, []);

  const fetchLoginEvents = async () => {
    try {
      const loginEventsCollection = db.collection("loginEvents");
      const snapshot = await loginEventsCollection.get();
      // Include the doc.id in the mapped data
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        loginEventId: doc.id,
      }));
      setLoginEvents(data);
    } catch (error) {
      console.error("Error fetching login events: ", error);
    }
  };

  useEffect(() => {
    const calculateMaxStreak = (player) => {
      const currentPlayerLoginEvents = loginEvents.filter(
        (event) => event.userId === player
      );

      if (currentPlayerLoginEvents.length === 0) return 0;

      // Convert each timestamp to "YYYY-MM-DD" format and then get unique dates
      const uniqueLoginDates = [
        ...new Set(
          currentPlayerLoginEvents.map(
            (event) => event.timestamp.toDate().toISOString().split("T")[0]
          )
        ),
      ];

      // Sort the unique dates
      const sortedUniqueDates = uniqueLoginDates.sort();

      let longestStreak = 1;
      let currentStreak = 1;

      for (let i = 1; i < sortedUniqueDates.length; i++) {
        const prevDate = new Date(sortedUniqueDates[i - 1]);
        const currentDate = new Date(sortedUniqueDates[i]);

        const diffTime = Math.abs(currentDate - prevDate);
        const diffDays = diffTime / (1000 * 60 * 60 * 24);

        if (diffDays === 1) {
          currentStreak++;
          longestStreak = Math.max(longestStreak, currentStreak);
        } else if (diffDays > 1) {
          currentStreak = 1;
        }
      }
      return longestStreak;
    };
    const calculateStreakXP = (streak) => {
      let xp = 0;
      if (streak >= 14) xp += 40;
      if (streak >= 7) xp += 20;
      if (streak >= 3) xp += 10;
      return xp;
    };

    const calculateXP = (player) => {
      const streakXP = calculateStreakXP(calculateMaxStreak(player.userUid));

      return (
        ciSolutionsData.filter((solution) => solution.userId === player.userUid)
          .length *
          10 +
        ciSolutionsVotesData.filter((vote) => vote.userId === player.userUid)
          .length *
          2 +
        getUserAgreeAndDisAgreeVotesForPlayerX(player.userUid).agreeVotes
          .length *
          2 +
        getUserAgreeAndDisAgreeVotesForPlayerX(player.userUid).disAgreeVotes
          .length *
          -1 +
        argumentsData.filter((argument) => argument.userId === player.userUid)
          .length *
          5 +
        streakXP
      );
    };

    const sortedPlayers = [...playersData]
      .map((player) => ({
        ...player,
        xp: calculateXP(player),
        ideas: ciSolutionsData.filter(
          (solution) => solution.userId === player.userUid
        ).length,
        votes: ciSolutionsVotesData.filter(
          (vote) => vote.userId === player.userUid
        ).length,
        arguments: argumentsData.filter(
          (argument) => argument.userId === player.userUid
        ).length,
        streak: calculateMaxStreak(player.userUid),
      }))
      .sort((a, b) => {
        switch (sortBy) {
          case "ideas":
            return sortOrder === "asc" ? a.ideas - b.ideas : b.ideas - a.ideas;
          case "votes":
            return sortOrder === "asc" ? a.votes - b.votes : b.votes - a.votes;
          case "arguments":
            return sortOrder === "asc"
              ? a.arguments - b.arguments
              : b.arguments - a.arguments;
          case "streak":
            return sortOrder === "asc"
              ? a.streak - b.streak
              : b.streak - a.streak;
          case "xp":
          default:
            return sortOrder === "asc" ? a.xp - b.xp : b.xp - a.xp;
        }
      });

    setSortedPlayersData(sortedPlayers);
  }, [
    playersData,
    sortBy,
    sortOrder,
    ciSolutionsData,
    ciSolutionsVotesData,
    argumentsData,
    loginEvents,
  ]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("desc");
    }
  };

  const getSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === "asc" ? (
        <FontAwesomeIcon icon={faCaretUp} color='red' />
      ) : (
        <FontAwesomeIcon icon={faCaretDown} color='green' />
      );
    } else {
      return <FontAwesomeIcon icon={faCaretRight} />;
    }
  };

  return (
    <div className='BlackboxRankingPage'>
      <div className='table-responsive'>
        <table className='table table-dark'>
          <thead>
            <tr>
              <th className='col-auto' scope='col'>
                #
              </th>
              <th className='col-auto' scope='col'>
                Avatar
              </th>
              <th className='col' scope='col'>
                Name
              </th>
              <th
                className='col-auto sortable'
                scope='col'
                onClick={() => handleSort("ideas")}
              >
                Ideas {getSortIcon("ideas")}
              </th>
              <th
                className='col-auto sortable'
                scope='col'
                onClick={() => handleSort("votes")}
              >
                Votes {getSortIcon("votes")}
              </th>
              <th
                className='col-auto sortable'
                scope='col'
                onClick={() => handleSort("arguments")}
              >
                Arguments {getSortIcon("arguments")}
              </th>
              <th
                className='col-auto sortable'
                scope='col'
                onClick={() => handleSort("streak")}
              >
                Longest Streak {getSortIcon("streak")}
              </th>
              <th
                className='col-auto sortable'
                scope='col'
                onClick={() => handleSort("xp")}
              >
                XP Points {getSortIcon("xp")}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedPlayersData.map((player, index) => {
              const playerRank =
                sortedPlayersData.findIndex(
                  (xpSortedPlayer) => xpSortedPlayer.id === player.id
                ) + 1;

              return (
                <tr
                  key={player.id}
                  className={player.you ? "highlight" : ""}
                  style={
                    player.you
                      ? { background: "rgba(0,0,0,0.8)" }
                      : { background: "rgba(0,0,0,0)" }
                  }
                >
                  <th className='col-auto' scope='row'>
                    {index + 1}
                  </th>
                  <td className='col-auto'>
                    <img
                      src={player.playerImageURL}
                      alt={player.playerName}
                      className='profile-pic'
                    />
                  </td>
                  <td className='col'>{player.playerName}</td>
                  <td className='col-auto'>{player.ideas}</td>
                  <td className='col-auto'>{player.votes}</td> {/*  */}
                  <td className='col-auto'>{player.arguments}</td>{" "}
                  <td className='col-auto'>{player.streak}</td>{" "}
                  {/* argumentsData & argumentsLikesData  */}
                  <td className='col-auto'>{player.xp + player.streak * 5}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BlackboxRankingPage;

// LandingPage.js
import React, {useEffect, useState} from 'react';
import './OurCapabilities.css';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faLightbulb, faRobot, faFlaskVial, faCircleNodes } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, Pagination]);


const capabilities = [
  {icon: faLightbulb, title: 'Design Thinking', text: 'Within everything we do, we employ design-thinking techniques, to identify key customer needs, document best-practices, generate promising alternatives, and design cost-effective experiments to quickly test promising concepts.'},
  {icon: faPaperPlane, title: 'Rapid Prototyping', text: 'During condense design sprints, our skilled team of developers can help you quickly create mockups, prototypes, and MVPs of physical and digital products, allowing for early idea validation in a cost-effective manner.'},
  {icon: faFlaskVial, title: 'Disciplined Business Experiments', text: 'Our in-house team of analysts and data scientists can aid you in designing and executing disciplined business experiments. This can yield statistically significant insights, enabling better, data-driven innovation decisions.'},
  {icon: faCircleNodes, title: 'Crowdsourcing', text: 'By using our proprietary Blackbox tool, we can quickly leverage the collective intelligence of your entire workforce to accelerate the generation of fresh ideas around customer-centric challenges.'},
  {icon: faRobot, title: 'Generative AI', text: 'By using custom-trained generative AI models, we further accelerate the innovation process, as it provides inspiration, encourages creativity, and can help to continuously engage all employees in the innovation process.'},
];



const DesktopTileComponent = ({ icon, title, text }) => {
    return (
      <div className="OurCapabilitiesPage-desktopSection-tile">
        <div className="OurCapabilitiesPage-desktopSection-tile-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className="OurCapabilitiesPage-desktopSection-tile-title">
          {title}
        </div>
        <div className="OurCapabilitiesPage-desktopSection-tile-description">
          {text}
        </div>
      </div>
    );
  }

  const MobileTileComponent = ({ icon, title, text }) => {
    return (
      <div className="OurCapabilitiesPage-mobileSection-tile">
        <div className="OurCapabilitiesPage-mobileSection-tile-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className="OurCapabilitiesPage-mobileSection-tile-title">
          {title}
        </div>
        <div className="OurCapabilitiesPage-mobileSection-tile-description">
          {text}
        </div>
      </div>
    );
  }

const OurCapabilities = () => {
  const location = useLocation();
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  useEffect(() => {
      if (location.hash) {
        const anchor = document.querySelector(location.hash);
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  return (
    <div className='OurCapabilitiesPage'>
        <section className='OurCapabilitiesPage-desktopSection'>
            <h2 className='OurCapabilitiesPage-desktopSection-mainTitle'>Our Key Capabilities<br/>To Accelerate Innovation</h2>
            {capabilities.map((capability, index) => (
            <DesktopTileComponent key={index} icon={capability.icon} title={capability.title} text={capability.text} />
            ))}
        </section>
        <section className='OurCapabilitiesPage-mobileSection'>
            <h2 className='OurCapabilitiesPage-mobileSection-mainTitle'>Our Unique Blend<br/>of Capabilities</h2>
            <Swiper
            style={{width: '100%'}}
            pagination={{ type: 'bullets' }}
            index={index}
            onSlideChange={(swiper) => {
              handleChangeIndex(swiper.activeIndex);
            }}
            autoplay={{ delay: 10000 }}
            >
            {capabilities.map((capability, i) => (
              <SwiperSlide key={i} >
                <MobileTileComponent key={i} icon={capability.icon} title={capability.title} text={capability.text} />
              </SwiperSlide>))}
            </Swiper>
        </section>
    </div>
  );
};

export default OurCapabilities;
import React, { useState, useEffect } from "react";
import "./HowWeHelp.css";

function HowWeHelp() {
  const [cases, setCases] = useState([
    {
      number: "01",
      title: "Assess Market Need",
      text: "Initial customer research is hardly enough to make large investment decisions. Test ideas with Mockups, Prototypes and MVPs and get valuable feedback - that’s how you derisk your investments.",
      id: "desirability",
    },
    {
      number: "02",
      title: "Check Feasibility",
      text: "There is no sense to make a fully working product until you’re sure that all the features are technically possible. Test scalability, 3rd party integrations and algorithm realization first.",
      id: "feasibility",
    },
    {
      number: "03",
      title: "Raise Funds",
      text: "With interest rates on the rise, external and internal investors are no longer giving investments or budget for just a vague idea or vision. You have to let them experience your vision and show you have made your first product steps.",
      id: "invest",
    },
  ]);
  const [screenWidth, setScreenWidth] = useState(1000);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <div className='HowWeHelp' id='HowWeHelp'>
      <div className='HowWeHelp-title'>
        <h1>
          Design Sprints
          <br />& Rapid Prototyping
        </h1>
      </div>
      <div className='HowWeHelp-cases'>
        <h2>
          <span>Three usecases</span> <br />
          where it helps -{" "}
        </h2>
        {cases.map((item, index) => (
          <div className='HowWeHelp-cases-card' id={item.id} key={index}>
            <div className='HowWeHelp-cases-card-number'>{item.number}</div>
            <div className='HowWeHelp-cases-card-title'>{item.title}</div>
            <div className='HowWeHelp-cases-card-text'>{item.text}</div>
          </div>
        ))}
        <a className='unstyledLink' href='mailto:gilles@howtofly.be'>
          <button className='howWeHelp-black-callToAction'>contact us</button>
        </a>
      </div>
    </div>
  );
}

export default HowWeHelp;

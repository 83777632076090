import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  Title,
  LinearScale,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";

// initiate the chart.js plugin
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Title
);

export default function BlackboxCIKnowledgeBaseBarChartCard({
  chosenKnowledgeBaseItem,
  barChartData,
  prepareDataForBarChart,
  cardTitle,
  cardDescriptionText,
  chosenType,
}) {
  return (
    <div className='BlackboxCIKnowledgeBaseItem-barChartCard'>
      <div className='BlackboxCIKnowledgeBaseItem-barChartCard-title'>
        {cardTitle}
      </div>
      <div className='BlackboxCIKnowledgeBaseItem-barChartCard-insightDescription'>
        <ul>
          {cardDescriptionText.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
      <div className='BlackboxCIKnowledgeBaseItem-barChartCard-chart'>
        <Bar
          data={prepareDataForBarChart(chosenType)}
          height='200px'
          options={{
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  color: "white", // e.g., "red" or "#ff0000"
                },
              },
              tooltip: {},
              afterDraw: (chart) => {
                var ctx = chart.ctx;

                chart.data.datasets.forEach((dataset, datasetIndex) => {
                  var datasetMeta = chart.getDatasetMeta(datasetIndex);
                  datasetMeta.data.forEach((bar, index) => {
                    var label = dataset.data[index];
                    var x = bar.x;
                    var y = bar.y - 10; // Offset above the bar
                    ctx.fillText(label, x, y);
                    ctx.fillStyle = "white"; // set this to the color you want
                  });
                });
              },
            },
            scales: {
              x: {
                beginAtZero: true,
                grid: {
                  borderColor: "white", // e.g., "#cccccc"
                  drawBorder: true,
                },
                ticks: {
                  color: "white", // e.g., "blue" or "#0000ff"
                },
              },
              y: {
                beginAtZero: true,
                grid: {
                  borderColor: "white", // e.g., "#cccccc"
                  drawBorder: true,
                },
                ticks: {
                  color: "white", // e.g., "green" or "#00ff00"
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import MasonryLayout from "./MasonryLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BlackboxCISolutions.css";
import { db } from "../firebase/config";
import BlackboxCISolutionSharingModal from "./BlackboxCISolutionSharingModal";
import BlackboxCISolutionTile from "./BlackboxCISolutionTile";

const BlackboxCISolutions = (props) => {
  const {
    ciSolutionsData,
    setCiSolutionsData,
    handleCiSolutionVote,
    ciSolutionsVotesData,
    playersData,
    currentUserId,
    handleAddToBuildDeck,
    handleShareIdeaClickFromBuildDeck,
    customTheme,
  } = props;

  const [ideasRead, setIdeasRead] = useState([]);
  const [sortedIdeasOnNewestFirst, setSortedIdeasOnNewestFirst] =
    useState(ciSolutionsData);

  useEffect(() => {
    fetchIdeasReadData();
    sortIdeasOnNewestFirst(ciSolutionsData);
  }, [currentUserId, ciSolutionsData]);

  function sortIdeasOnNewestFirst(arr) {
    setSortedIdeasOnNewestFirst(
      arr.sort((a, b) => b.creationDate - a.creationDate)
    );
  }

  const [filter, setFilter] = useState({
    topic: null,
    userCreated: false,
    userVoted: false,
    userUnvoted: false,
    unreadIdeas: false,
  });

  const [solutionModalData, setSolutionModalData] = useState({
    visible: false,
    item: null,
  });

  const fetchIdeasReadData = async () => {
    try {
      const ideasReadCollection = db.collection("ideasRead");

      // Filter by the current user's ID
      const query = ideasReadCollection.where("userId", "==", currentUserId);

      const snapshot = await query.get();

      // Include the doc.id in the mapped data
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        ideaReadId: doc.id,
      }));

      const onlyReadIdeas = data.filter((idea) => idea.readStatus === true);

      setIdeasRead(onlyReadIdeas);
    } catch (error) {
      console.error("Error fetching arguments data: ", error);
    }
  };

  const handleFilterChange = (event) => {
    fetchIdeasReadData();
    const { value } = event.target;

    const defaultFilter = {
      topic: null,
      userCreated: false,
      userVoted: false,
      userUnvoted: false,
    };

    switch (value) {
      case "userCreated":
        setFilter({ ...defaultFilter, userCreated: true });
        break;
      case "userVoted":
        setFilter({ ...defaultFilter, userVoted: true });
        break;
      case "userUnvoted":
        setFilter({ ...defaultFilter, userUnvoted: true });
        break;
      case "unreadIdeas":
        setFilter({ ...defaultFilter, unreadIdeas: true });
        break;
      default:
        setFilter({ ...defaultFilter, topic: value || null });
    }
  };

  const userHasVotedForSolution = (item) => {
    return ciSolutionsVotesData.some(
      (vote) =>
        vote.ciSolutionId === item.ciSolutionId && vote.userId === currentUserId
    );
  };

  const userHasReadIdea = (ciSolutionId) => {
    return ideasRead.some((idea) => idea.ciSolutionId === ciSolutionId);
  };

  const filteredCiSolutionsData = sortedIdeasOnNewestFirst.filter((item) => {
    if (filter.topic && !item.topic[filter.topic]) return false;
    if (filter.userCreated && item.userId !== currentUserId) return false;
    if (filter.userVoted && !userHasVotedForSolution(item)) return false;
    if (filter.userUnvoted && userHasVotedForSolution(item)) return false;

    // Here's the modification for unreadIdeas. It checks if the idea has been read by the user.
    if (filter.unreadIdeas && userHasReadIdea(item.ciSolutionId)) return false;

    return true;
  });

  return (
    <div
      className={
        customTheme ? "BlackboxCISolutions customTheme" : "BlackboxCISolutions"
      }
    >
      <div className='BlackboxCISolutions-titleRow'>
        <h1 className='BlackboxCISolutions-titleRow-title'>
          Customer Solutions
        </h1>
        <div className='BlackboxCISolutions-titleRow-buttons'>
          <select
            className='BlackboxCISolutions-titleRow-filterButton'
            onChange={handleFilterChange}
          >
            <option value=''>Filter</option>
            <option value=''>All Ideas</option>
            <option value='userCreated'>Your Ideas</option>
            <option value='unreadIdeas'>Unread Ideas</option>
            <option value='userVoted'>Voted Ideas</option>
            <option value='userUnvoted'>Unvoted Ideas</option>
            <option value='marketing'>Marketing Ideas</option>
            <option value='product'>Product Ideas</option>
            <option value='packaging'>Packaging Ideas</option>
            <option value='flavourVariantsAndInnovations'>
              Flavor Variants
            </option>
            <option value='limitedEditions'>Limited Editions</option>
            <option value='combosAndPairing'>Combos & Pairing</option>
            <option value='textureShapeAndForm'>
              Texture, Shape, and Form
            </option>
            <option value='newCategory'>New Category</option>
            <option value='dIYSnacking'>DIY Snacking</option>
            <option value='commercialsAndEvents'>Commercials & Events</option>
            <option value='brandPartnershipsAndCrossPromotions'>
              Brand Partnerships & Cross-Promotions
            </option>
            <option value='influencersCelebritiesAndLicensing'>
              Influencers & Licencing
            </option>
            <option value='consumerEngagementAndExperience'>
              Consumer Engagement & Experience
            </option>
          </select>
          {true && (
            <button
              className='BlackboxCISolutions-titleRow-shareButton'
              onClick={() => handleShareIdeaClickFromBuildDeck(false)}
            >
              + Share Idea
            </button>
          )}
        </div>
      </div>

      {/* Blackbox grid of solutions ------------------------------------------------------------- */}
      <MasonryLayout className='BlackboxCISolutions-grid'>
        {filteredCiSolutionsData.map((item, index) => (
          <BlackboxCISolutionTile
            key={item.ciSolutionId || index} // added a key here, assuming items have a unique "solutionId". If not, it falls back to the index, which isn't ideal but will work.
            item={item}
            index={index}
            ciSolutionsData={ciSolutionsData}
            setCiSolutionsData={setCiSolutionsData}
            handleCiSolutionVote={handleCiSolutionVote}
            solutionModalData={solutionModalData}
            setCiSolutionModalData={setSolutionModalData}
            ciSolutionsVotesData={ciSolutionsVotesData} // Used in the BlackboxSolutionTile component
            handleAddToBuildDeck={handleAddToBuildDeck}
          />
        ))}
      </MasonryLayout>
    </div>
  );
};

export default BlackboxCISolutions;

import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase/config";

// import KnowledgeBase data (JSON files)
import {
  competitorSKUs,
  competitorSKUsIntro,
} from "./knowledgeBaseData/competitorSKUs.js";

// import custom components
import MasonryLayout from "./MasonryLayout.js";
import BlackboxMekkoGraph from "./BlackboxMekkoGraph.js";

// import bootstrap components
import { Button, DropdownButton, Dropdown } from "react-bootstrap";

// import font-awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";

// import css stylesheets
import "./BlackboxCompetitorCollection.css";
import { set } from "lodash";

// Main Component ////////////////////////////////////////////////////////////////////////

export default function BlackboxCompetitorCollection({
  visibleContent,
  handleAddToBuildDeck,
  setSkuSharingModalShow,
}) {
  const [filters, setFilters] = useState({});
  const [sortedBySales, setSortedBySales] = useState(false);
  const [filteredSKUs, setFilteredSKUs] = useState([]);
  const [allCompetitorSKUs, setAllCompetitorSKUs] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");
  const [readMoreActiveIndex, setReadMoreActiveIndex] = useState(null);
  const [competitorSkusFromFirebase, setCompetitorSkusFromFirebase] = useState(
    []
  );

  const combinedSKUs = [...competitorSKUs, ...competitorSkusFromFirebase];

  // the useEffect Function

  useEffect(() => {
    fetchCompetitorSkusFromFirebase();
    let result = [...competitorSkusFromFirebase, ...competitorSKUs];

    // Filter
    for (const key in filters) {
      if (filters[key]) {
        result = result.filter((sku) => sku[key] === filters[key]);
      }
    }

    // Sort
    if (sortedBySales) {
      result.sort((a, b) => b.sales - a.sales);
    }

    setFilteredSKUs(result);
  }, [filters, sortedBySales, competitorSkusFromFirebase]);

  // function to handle the fetching of SKU data from firebase

  const fetchCompetitorSkusFromFirebase = async () => {
    try {
      const competitorsSkusFromFirebase = db.collection("ciCompetitorSkus");
      const snapshot = await competitorsSkusFromFirebase.get();
      // Include the doc.id in the mapped data
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        ciCompetitorSkuId: doc.id,
      }));
      setCompetitorSkusFromFirebase(data);
    } catch (error) {
      console.error("Error fetching solutions data: ", error);
    }
  };

  const handleFilter = (key, value) => {
    setFilters({ [key]: value === filters[key] ? "" : value });
    setActiveFilter(key);
  };

  const key = "someKey";

  const buttonTitle =
    filters[key] ||
    (key === "manufacturerMekkoLegend" && "Manufacturer") ||
    (key === "flavourMekkoLegend" && "Flavour") ||
    (key === "shapeMekkoLegend" && "Shape") ||
    (key === "mainUSPMekkoLegend" && "Main USP") ||
    (key === "baseIngredientMekkoLegend" && "Base Ingredient") ||
    (key === "packFormat" && "Pack Format") ||
    (key === "country" && "Country") ||
    (key === "mainTargetAudienceMekkoLegend" && "Main Target Audience");

  // add or remove filters here

  const uniqueKeys = Object.keys(combinedSKUs[0]).filter(
    (key) =>
      (key === "manufacturerMekkoLegend" ||
        key === "flavourMekkoLegend" ||
        key === "baseIngredientMekkoLegend" ||
        key === "country" ||
        key === "packFormat") &&
      typeof combinedSKUs[0][key] === "string"
  );

  return (
    <div className='BlackboxCompetitorCollection'>
      <div className='BlackboxCompetitorCollection-titleRow'>
        <h1 className='BlackboxCompetitorCollection-titleRow-title'>
          Competitor SKUs in the cheese-flavoured salty-snack market
        </h1>
        <p>
          To get a better understanding which value we can bring to the
          cheese-flavoured salty snack category, we have to get a good view on
          our competition. <br />
          Check out all SKUs sold on the U.K., German, and French market below.{" "}
        </p>
        <p>
          Did you see some interesting SKUs in your market which are currently
          not listed? Click on the red "+ share SKU" button to add them to the
          list.
        </p>
      </div>
      <div className='BlackboxCompetitorCollection-rowForFilterAndSortButtons'>
        <div className='BlackboxCompetitorCollection-rowForFilterAndSortButtons-filterButtons'>
          {uniqueKeys.map((key) => {
            const buttonTitle =
              filters[key] ||
              (key === "manufacturerMekkoLegend" && "Manufacturer") ||
              (key === "flavourMekkoLegend" && "Flavour") ||
              (key === "shapeMekkoLegend" && "Shape") ||
              (key === "mainUSPMekkoLegend" && "Main USP") ||
              (key === "baseIngredientMekkoLegend" && "Base Ingredient") ||
              (key === "country" && "Country") ||
              (key === "packFormat" && "Pack Format") ||
              (key === "mainTargetAudienceMekkoLegend" &&
                "Main Target Audience");
            return (
              <DropdownButton
                title={buttonTitle}
                key={key}
                className={
                  activeFilter === key
                    ? "dropdownFilters btn-block active-filter"
                    : "dropdownFilters btn-block"
                }
              >
                {[...new Set(combinedSKUs.map((sku) => sku[key]))].map(
                  (value) => (
                    <Dropdown.Item
                      onClick={() => handleFilter(key, value)}
                      key={value}
                      active={filters[key] === value}
                      className='dropdown-item'
                      data-width='100%'
                    >
                      {value}
                    </Dropdown.Item>
                  )
                )}
              </DropdownButton>
            );
          })}
        </div>
        <div className='BlackboxCompetitorCollection-rowForFilterAndSortButtons-sortButtons'>
          <Button
            className='shareSKUButton'
            onClick={() => setSkuSharingModalShow(true)}
          >
            + Share SKU
          </Button>
          <Button
            variant={sortedBySales ? "primary" : "outline-primary"}
            onClick={() => setSortedBySales((prev) => !prev)}
            className={
              sortedBySales
                ? "sortBySalesButton sortActive"
                : "sortBySalesButton"
            }
          >
            <div>Sort by Sales</div>
            {sortedBySales && <FontAwesomeIcon icon={faCaretDown} />}
            {!sortedBySales && <FontAwesomeIcon icon={faCaretRight} />}
          </Button>
        </div>
      </div>
      <MasonryLayout className='BlackboxCompetitorCollection-rowForCollectionOfCards'>
        {filteredSKUs.map((sku, skuIndex) => (
          <div
            key={skuIndex}
            className='BlackboxCompetitorCollection-rowForCollectionOfCards-card'
          >
            <div className='BlackboxCompetitorCollection-rowForCollectionOfCards-card-imageContainer'>
              <img src={sku.imageUrl} />
            </div>
            <div className='BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer'>
              <div className='BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer-titleContainer'>
                <h5>
                  <strong>
                    {sku.brand} (
                    {sku.countryOther ? sku.countryOther : sku.country})
                  </strong>
                  <br />
                  {sku.packFormatOther ? sku.packFormatOther : sku.packFormat}
                </h5>
              </div>
              <div className='BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer'>
                <Button
                  onClick={() =>
                    setReadMoreActiveIndex(
                      readMoreActiveIndex === skuIndex ? null : skuIndex
                    )
                  }
                  className='BlackboxCompetitorCollection-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button'
                >
                  {readMoreActiveIndex === skuIndex ? "Read Less" : "Read More"}
                </Button>
                <Button
                  onClick={() => handleAddToBuildDeck(sku)}
                  className='BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button addToDeckButton addSolutionToBuildDeckButton'
                >
                  +
                </Button>
              </div>
            </div>
            {readMoreActiveIndex === skuIndex && (
              <div className='BlackboxCompetitorCollection-rowForCollectionOfCards-card-readMoreContentContainer'>
                <div>
                  <strong style={{ marginRight: "5px" }}>Manufacturer: </strong>{" "}
                  {sku.manufacturer}
                </div>
                <div>
                  <strong style={{ marginRight: "5px" }}>Category: </strong>{" "}
                  {sku.categoryMekkoLegendOther
                    ? sku.categoryMekkoLegendOther
                    : sku.categoryMekkoLegend}
                </div>
                <div>
                  <strong style={{ marginRight: "5px" }}>
                    Base Ingredient:{" "}
                  </strong>{" "}
                  {sku.baseIngredientOther
                    ? sku.baseIngredientOther
                    : sku.baseIngredient}
                </div>
                <div>
                  <strong style={{ marginRight: "5px" }}>Flavour: </strong>{" "}
                  {sku.flavour}
                </div>
                <div>
                  <strong style={{ marginRight: "5px" }}>
                    Baking Process:{" "}
                  </strong>{" "}
                  {sku.bakingProcessOther
                    ? sku.bakingProcessOther
                    : sku.bakingProcess}
                </div>
                <div>
                  <strong style={{ marginRight: "5px" }}>Sales: </strong> €
                  {new Intl.NumberFormat().format(sku.sales)}
                </div>
              </div>
            )}
          </div>
        ))}
      </MasonryLayout>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "./LogoCarousel.css";

// import logos
import Captic from "../assets/ClientLogos/Captic.png";
import CheezIt from "../assets/ClientLogos/CheezIt.png";
import Eneco from "../assets/ClientLogos/Eneco.png";
import Kellanova from "../assets/ClientLogos/Kellanova.png";
import Kellogg from "../assets/ClientLogos/Kellogg.png";
import Pringles from "../assets/ClientLogos/Pringles.png";
import Rae from "../assets/ClientLogos/Rae.png";

const companyLogos = [
  Captic,
  CheezIt,
  Eneco,
  Kellanova,
  Rae,
  Kellogg,
  Pringles,
];

const LogoCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % companyLogos.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const getVisibleLogos = () => {
    let logos = [];
    for (let i = 0; i < 3; i++) {
      logos.push(companyLogos[(currentIndex + i) % companyLogos.length]);
    }
    return logos;
  };

  return (
    <div className='LogoCarousel-carousel'>
      <div className='LogoCarousel-carousel-inner'>
        {getVisibleLogos().map((logo, index) => (
          <div key={index} className='LogoCarousel-carousel-item'>
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoCarousel;

// import assets
import UKFlavourMap from "../../assets/UKFlavourMap.png";
import flag1 from "../../assets/webpKB/flag1.webp";
import flag2 from "../../assets/webpKB/flag2.webp";
import flag3 from "../../assets/webpKB/flag3.webp";
import flag4 from "../../assets/webpKB/flag4.webp";
import flag5 from "../../assets/webpKB/flag5.webp";
import flag6 from "../../assets/webpKB/flag6.webp";

const countryProfilesIntro = {
  title: "Country Profiles",
  description: [
    "Cheez-It Snap’d will primarily be launched in U.K., Ireland, and Germany mid 24. Early ‘25 France and Spain Will Follow. After the necessary investments in a second line, other European countries will follow such as another established snack market such as Italy, or a high growth market such as Poland. To adapt to local preferences, it’s important to understand some subtleties of the different markets. Here’s a quick overview of some crucial markets in Europe.",
  ],
};

const countryProfiles = [
  {
    cardTitle: "United Kingdom (UK)",
    cardType: "Country Profile",
    cardSubTitle: "Cheese-flavored salty snacks: €909M",
    country: "United Kingdom",
    countryAbbreviation: "UK",
    imageUrl: flag1,
    allSaltySnackSalesBillion: 4.7,
    allCheeseFlavouredSaltySnackSalesBillion: 0.909,
    groceryChannelMarketShareInsightDescription: [
      "Over the upcoming 5 years, the grocery market in the U.K. is expected to grow 2.2% year-on-year.",
      "With an expected anual growth of 4.4%, the discount channel is expected be the fastest growing grocery channel in the U.K.",
      "But with 13.7% market share (and 26.6% specifically in the salty snack category), discount is currently still relatively small compared to the channel size in mainland Europe.",
    ],
    groceryChannelMarketShareSaltySnacks2022: {
      superAndHypers: 44.2,
      convenience: 13.6,
      discount: 26.6,
      onlineGroceryRetail: 8.5,
      traditionalAndOtherModern: 5.8,
    },
    groceryChannelMarketShareAllGroceries2022: {
      superAndHypers: 49.7,
      convenience: 20.6,
      discount: 13.7,
      onlineGroceryRetail: 10.1,
      traditionalAndOtherModern: 5.7,
    },
    groceryChannelMarketShareAllGroceries2027: {
      superAndHypers: 47.4,
      convenience: 21.1,
      discount: 15.2,
      onlineGroceryRetail: 11.2,
      traditionalAndOtherModern: 5.2,
    },
    top10PopularGroceryRetailersInsight: [
      "Tesco is the largest grocery retailer in the U.K. with more than 25% market share.",
      "The top 10 grocery retailers in the U.K. make up more than 80% of the market.",
    ],
    top10PopularGroceryRetailers: [
      { name: "Tesco", allGrocerySales2022Billion: 58.1 },
      { name: "Sainsbury’s", allGrocerySales2022Billion: 33.8 },
      { name: "Asda", allGrocerySales2022Billion: 23.7 },
      { name: "Morissons", allGrocerySales2022Billion: 19.0 },
      { name: "Aldi", allGrocerySales2022Billion: 13.9 },
      { name: "Lidl", allGrocerySales2022Billion: 11.0 },
      { name: "Co-op", allGrocerySales2022Billion: 10.1 },
      { name: "Waitrose", allGrocerySales2022Billion: 7.8 },
      { name: "Iceland", allGrocerySales2022Billion: 3.2 },
      { name: "M&S Food", allGrocerySales2022Billion: 2.7 },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackManufacturersInsight: [
      "The €909M cheese-flavoured salty snack market is dominated by PepsiCo with more that 62% market share. This is driven by popular brands such as Cheetos, walkers Wotsits.",
      "A long tail of more than 50 manufacturers make-up less than 5% of the market.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackManufacturers: [
      { type: "Manufacturer", name: "PepsiCo", marketShare: 0.62 },
      { type: "Manufacturer", name: "Pladis", marketShare: 0.114 },
      { type: "Manufacturer", name: "Private Label", marketShare: 0.106 },
      { type: "Manufacturer", name: "KP Snacks", marketShare: 0.101 },
      { type: "Manufacturer", name: "Kellogg", marketShare: 0.015 },
      { type: "Manufacturer", name: "Valeo Foods", marketShare: 0.012 },
      { type: "Manufacturer", name: "Tayto Group", marketShare: 0.011 },
      {
        type: "Manufacturer",
        name: "Seabrook Potato Crisps",
        marketShare: 0.009,
      },
      { type: "Manufacturer", name: "Piper Crisps", marketShare: 0.003 },
      {
        type: "Manufacturer",
        name: "Mackies Of Schotland",
        marketShare: 0.002,
      },
      {
        type: "Manufacturer",
        name: "Other (47 manufacturers)",
        marketShare: 0.007,
      },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBrandsInsights: [
      "With 4 brands in the top 5, and six brands in the top 10, PepsiCo dominates the top 10 of cheese flavoured salty snacks with their Walkers Core, Walkers Wotsits, Walkers Quavers, Walkers Oven Baked, Walkers Great Taste, and Doritos Core brands.",
      "A long tail of more than 190 brands make op a little bit more than 25% of €909M cheese-flavoured salty snack market in the U.K.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBrands: [
      { type: "Brands", name: "Walkers Core", marketShare: 0.167 },
      { type: "Brands", name: "Walkers Wotsits", marketShare: 0.134 },
      { type: "Brands", name: "Walkers Quavers", marketShare: 0.12 },
      { type: "Brands", name: "Doritos Core", marketShare: 0.101 },
      { type: "Brands", name: "Jacobs Mini Cheddars", marketShare: 0.094 },
      { type: "Brands", name: "Hula Hoops Core", marketShare: 0.042 },
      { type: "Brands", name: "Walkers Oven Baked", marketShare: 0.041 },
      {
        type: "Brands",
        name: "Mccoys Ridge Cut",
        marketShare: 0.009,
      },
      { type: "Brands", name: "Walkers Great Taste", marketShare: 0.003 },
      {
        type: "Brands",
        name: "Tyrells Core",
        marketShare: 0.002,
      },
      {
        type: "Brands",
        name: "Other (190 brands)",
        marketShare: 0.257,
      },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBaseIngredientsInsights: [
      "With almost 40%, potato is the most popular base ingredient in the U.K. cheese-flavoured salty snack market.",
      "Corn and potato starch are also very popular with respectively 28% and 20% market share.",
      "Multigrain, peas/chickpea/lentil, cheese, and nuts are relatively small with less than 1% market share.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBaseIngredients: [
      { type: "Base Ingredients", name: "Potato", marketShare: 0.382 },
      { type: "Base Ingredients", name: "Corn", marketShare: 0.279 },
      { type: "Base Ingredients", name: "Potato Starch", marketShare: 0.203 },
      { type: "Base Ingredients", name: "Wheat", marketShare: 0.11 },
      { type: "Base Ingredients", name: "Multigrain", marketShare: 0.001 },
      {
        type: "Base Ingredients",
        name: "Peas / ChickPea / Lentil",
        marketShare: 0.001,
      },
      { type: "Base Ingredients", name: "Cheese", marketShare: 0.001 },
      { type: "Base Ingredients", name: "Nuts", marketShare: 0 },
      { type: "Base Ingredients", name: "Other", marketShare: 0.023 },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackPackFormatInsights: [
      "With almost 50%, multipack is a very popular purchase format in the U.K.",
      "With respectively 25.8% and 23.8% large sharing and single bags also take their lion-share of the market.",
      "Resealable pouch bags and tubes are relatively small compared to the traditional bags.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackPackFormat: [
      { type: "Pack Format", name: "Multipack", marketShare: 0.488 },
      { type: "Pack Format", name: "Large Sharing Bag", marketShare: 0.258 },
      { type: "Pack Format", name: "Singles", marketShare: 0.238 },
      { type: "Pack Format", name: "Large Sharing Tube", marketShare: 0.016 },
      { type: "Pack Format", name: "Singles Pouch", marketShare: 0.001 },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackCategoryInsights: [
      "Classic sliced potato chips and Extrude chips dominate the market with respectively 39.0% and 32.8%.",
      "Although trending, cheese-flavoured vegetable crisps are still extremely small in the U.K. with only 0.01% market share in the cheese-flavoured salty snack segment.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackCategory: [
      {
        type: "Snack Category",
        name: "Sliced Potato Chips",
        marketShare: 0.39,
      },
      { type: "Snack Category", name: "Extruded", marketShare: 0.318 },
      { type: "Snack Category", name: "Tortilla", marketShare: 0.109 },
      { type: "Snack Category", name: "Crackers", marketShare: 0.104 },
      {
        type: "Snack Category",
        name: "Reconstituted Potato",
        marketShare: 0.055,
      },
      {
        type: "Snack Category",
        name: "Vegetable (Lentil / Humus / Chickpea/…)",
        marketShare: 0,
      },
      { type: "Snack Category", name: "Other", marketShare: 0.023 },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBakingProcessInsights: [
      "With 67.9% Frying remain the most popular baking process in the U.K.",
      "Baking and Hand-Coocking complete the top 3 with respectively 20.7% and 4.6%.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBakingProcess: [
      { type: "Baking Process", name: "Fried", marketShare: 0.679 },
      { type: "Baking Process", name: "Baked", marketShare: 0.207 },
      { type: "Baking Process", name: "Hand-Cooked", marketShare: 0.046 },
      { type: "Baking Process", name: "Other", marketShare: 0.068 },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackSubFlavoursInsights: [
      "Cheese & Onion is a very popular flavour combination in the U.K. with more than 40% market share in the cheese-flavoured salty snack market.",
      "Although on-trend, Spicy Variations are still relatively small with only 1.8% market share.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackSubFlavours: [
      { type: "Flavour Types", name: "Cheese & Onion", marketShare: 0.41 },
      { type: "Flavour Types", name: "Basic Cheese", marketShare: 0.285 },
      {
        type: "Flavour Types",
        name: "Speciality, Gourmet & Composite Flavors",
        marketShare: 0.264,
      },
      { type: "Flavour Types", name: "Spicy Variations", marketShare: 0.018 },
      { type: "Flavour Types", name: "Cheese & Herb", marketShare: 0.017 },
      {
        type: "Flavour Types",
        name: "Cheese & Specialty Ingredients",
        marketShare: 0.005,
      },
      { type: "Flavour Types", name: "Other", marketShare: 0.001 },
    ],
    CountryFlavourMap: UKFlavourMap,
    countryOccasionsTotalFoodInsights: [
      "Lunch is the largest salty snack occasion in the U.K.",
      "However, with only 7% percent, lunch is the occasion where Pringles has the highest under-index.",
      "Due to pack format and brand-equity placing, Pringles heartland is in evening sharing occasions.",
      "Clear Opportunity for Cheez-It to be relevant across a greater number of occasions throughout the day through a variety of pack formats.",
      "Evening ME and Bedtime / Late Night Snacking often occur alone with fewer items consumed, increasing the risk to find the right snack that will deliver.",
    ],
    countryOccasionsTotalFood: {
      AMSnack: 6,
      Lunch: 14,
      LunchAlt: 7,
      PMSnack: 7,
      AfterWork: 4,
      EveningMe: 3,
      EveningWe: 5,
      BedtimeLateNightSnack: 6,
    },
    countryOccasionsSaltySnacks: {
      AMSnack: 6,
      Lunch: 14,
      LunchAlt: 13,
      PMSnack: 9,
      AfterWork: 8,
      EveningMe: 5,
      EveningWe: 7,
      BedtimeLateNightSnack: 8,
    },
    countryOccasionsPringles: {
      AMSnack: 6,
      Lunch: 7,
      LunchAlt: 12,
      PMSnack: 10,
      AfterWork: 9,
      EveningMe: 7,
      EveningWe: 15,
      BedtimeLateNightSnack: 13,
    },
    sources: [
      {
        link: "Competitor Cheese SKUs by market - Masterfile July 2023.xslx",
        url: "https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/Competitor%20Cheese%20SKUs%20by%20market%20-%20Jun%20'23%20Masterfile.xlsx?alt=media&token=d6a14e90-6daa-4bce-bdb6-387b1a56dc86",
      },
      { link: "Masterfile Cheez-It growth Hack (Slide 88)", url: "" },
      {
        link: "IGD 2022 - Europe’s channel forecasts 2022 - 2027 (Slide 47)",
        url: "https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/Europe%E2%80%99s%20channel%20forecasts%202022%20-%202027%20%20(2).pdf?alt=media&token=ab9b5f5f-8469-406c-801b-13b678593fcc",
      },
      {
        link: ` Statista, "Grocery market share in Great Britain 2017-2023"`,
        url: "https://www.statista.com/statistics/280208/grocery-market-share-in-the-united-kingdom-uk/",
      },
      {
        link: ` Retail Economics, "Top 10 UK Food, Grocery & Supermarket Retailers"`,
        url: "https://www.retaileconomics.co.uk/top-10-retailers-food-and-grocery",
      },
      {
        link: ` ESM Magazine, "Top 10 Supermarket Retail Chains In The UK"`,
        url: "https://www.esmmagazine.com/retail/top-10-supermarket-chains-in-the-uk-231802",
      },
      {
        link: ` Wikipedia, "List of supermarket chains in the United Kingdom"`,
        url: "https://en.wikipedia.org/wiki/List_of_supermarket_chains_in_the_United_Kingdom",
      },
      {
        link: `LinkedIn, "What Does 'USP' Mean? Does Every Business Need One?"`,
        url: "https://www.linkedin.com/pulse/what-does-usp-mean-every-business-need-one-patrick-mutabazi/",
      },
      {
        link: `Shopify, "Win Sales With a Unique Selling Proposition +10 Examples"`,
        url: "https://www.shopify.com/blog/unique-selling-proposition",
      },
      {
        link: `Constant Contact, "What is a Unique Selling Proposition?"`,
        url: "https://www.constantcontact.com/blog/unique-selling-proposition/",
      },
      {
        link: `New Breed Marketing, "How to Define Your Unique Selling Proposition (USP)"`,
        url: "https://www.newbreedrevenue.com/blog/how-to-define-your-unique-selling-proposition-usp",
      },
      {
        link: `MarketSplash, "Unique Selling Proposition: The What, The Why & The How"`,
        url: "https://marketsplash.com/unique-selling-proposition/",
      },
    ],
  },
  {
    cardTitle: "Germany (DE)",
    cardType: "Country Profile",
    cardSubTitle: "Cheese-flavored salty snacks: €113M",
    country: "Germany",
    countryAbbreviation: "DE",
    imageUrl: flag2,
    allSaltySnackSalesBillion: 3.3,
    allCheeseFlavouredSaltySnackSalesBillion: 0.113,
    groceryChannelMarketShareInsightDescription: [
      "Online will see the fastest growth, with a CAGR of 16.0%. Growth will mainly come from online pure players; Picnic (in conjunction with Edeka) and Rohlik",
      "Discount will remain the market’s leading channel, achieving a CAGR of 3.4%. Aldi and Lidl will continue to dominate",
      "Supermarkets will grow just above the market, with a CAGR of 3.3%. The channel will offer the second largest sales opportunity between 2022 and 2027",
      "Convenience will grow at the market rate, with Rewe achieving the only channel share gains to 2027",
      "Hypermarkets will see the largest loss of market share, with a CAGR of 1.5%. The loss in shopper appeal of the format will see retailers focus on smaller formats",
      "Traditional will remain as the country’s third largest channel, despite losing market share between 2022 and 2027",
    ],
    groceryChannelMarketShareSaltySnacks2022: {
      superAndHypers: 43.2,
      convenience: 3.2,
      discount: 30.8,
      onlineGroceryRetail: 1.4,
      traditionalAndOtherModern: 21.3,
    },
    groceryChannelMarketShareAllGroceries2022: {
      superAndHypers: 42.2,
      convenience: 3.2,
      discount: 31.3,
      onlineGroceryRetail: 2.5,
      traditionalAndOtherModern: 20.7,
    },
    groceryChannelMarketShareAllGroceries2027: {
      superAndHypers: 49.6,
      convenience: 8.0,
      discount: 42.4,
      onlineGroceryRetail: undefined,
      traditionalAndOtherModern: undefined,
    },
    top10PopularGroceryRetailersInsight: [
      "Edeka is the largest grocery retailer in Germany with sales reaching €60.4B in 2022.",
      "The grocery retail market is dominated mostly by 4 major players: Edeka, Rewe, Aldi, and Schwarz Group (Lidl, Kaufland).",
    ],
    top10PopularGroceryRetailers: [
      { name: "Edeka", allGrocerySales2022Billion: 62.7 },
      {
        name: "Schwarz Group (Lidl, Kaufland)",
        allGrocerySales2022Billion: 48.4,
      },
      { name: "Rewe Group", allGrocerySales2022Billion: 44.4 },
      {
        name: "Aldi Group (Aldi Nord, Aldi Süd)",
        allGrocerySales2022Billion: 38.0,
      },
      { name: "Metro Group", allGrocerySales2022Billion: 27.1 },
      { name: "Tengelmann Group", allGrocerySales2022Billion: 11.0 },
      { name: "Globus Holding", allGrocerySales2022Billion: 7.7 },
      { name: "Norma Group", allGrocerySales2022Billion: 4.0 },
      { name: "Lekkerland", allGrocerySales2022Billion: 3.9 },
      { name: "Bela", allGrocerySales2022Billion: 3.5 },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackManufacturersInsight: [
      "The €113M cheese-flavoured salty snack market is dominated by Intersnack, Private Label, PepsiCo, Lorenz, and Kellogg with respectively 29.9%, 24.2%, 15.6%, 13.9%, and 7.4% market share.",
      "A long tail of more than 30 manufacturers make-up less than 3% of the market.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackManufacturers: [
      { type: "Manufacturer", name: "Intersnack", marketShare: 0.299 },
      { type: "Manufacturer", name: "Private Label", marketShare: 0.242 },
      { type: "Manufacturer", name: "PepsiCo", marketShare: 0.156 },
      { type: "Manufacturer", name: "Lorenz", marketShare: 0.139 },
      { type: "Manufacturer", name: "Kellogg", marketShare: 0.074 },
      { type: "Manufacturer", name: "XOX Group", marketShare: 0.018 },
      { type: "Manufacturer", name: "Fonzies", marketShare: 0.015 },
      {
        type: "Manufacturer",
        name: "PHOENIXY",
        marketShare: 0.013,
      },
      { type: "Manufacturer", name: "Gunz", marketShare: 0.008 },
      {
        type: "Manufacturer",
        name: "MBG FOODSERVICE",
        marketShare: 0.006,
      },
      {
        type: "Manufacturer",
        name: "Other (32 manufacturers)",
        marketShare: 0.027,
      },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBrandsInsights: [
      "With 4 brands in the top 5, and six brands in the top 10, PepsiCo dominates the top 10 of cheese flavoured salty snacks with their Walkers Core, Walkers Wotsits, Walkers Quavers, Walkers Oven Baked, Walkers Great Taste, and Doritos Core brands.",
      "A long tail of more than 190 brands make op a little bit more than 25% of €909M cheese-flavoured salty snack market in the U.K.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBrands: [
      { type: "Brand", name: "CHIO TORTILLA CHIPS", marketShare: 0.239 },
      { type: "Brand", name: "PRIVATE LABEL", marketShare: 0.214 },
      { type: "Brand", name: "LORENZ CRUNCHIPS", marketShare: 0.096 },
      { type: "Brand", name: "DORITOS BASE", marketShare: 0.086 },
      { type: "Brand", name: "LAYS BUGLES", marketShare: 0.07 },
      { type: "Brand", name: "PRINGLES BASE", marketShare: 0.041 },
      { type: "Brand", name: "PRINGLES SIZZL'N", marketShare: 0.033 },
      { type: "Brand", name: "FUNNY FRISCH CORNADOS", marketShare: 0.031 },
      { type: "Brand", name: "LORENZ CRUNCHIPS WOW", marketShare: 0.028 },
      {
        type: "Brands",
        name: "Other (58 brands)",
        marketShare: 0.161,
      },
    ],
    countryOccasionsTotalFoodInsights: [
      "The vast majority of salty snacks occasions occur across the evening",
      "These are also the largest occasions for Pringles and where it has it’s greatest over index thanks to it’s larger pack formats (200g/185g)",
      "However, Pringles is still falling significantly behind the category",
      "Cheez-It has the potential to win in key occasions from PM snack through to evening occasions thanks to pack format. (60% value sales in pack formats below 150g)",
      "As in the UK these occasions often occur alone or alongside others, with fewer items consumed – increasing the risk and the need to find the right snack that will deliver!",
    ],
    countryOccasionsTotalFood: {
      AMSnack: 5,
      Lunch: 18,
      LunchAlt: 6,
      PMSnack: 9,
      AfterWork: 4,
      EveningMe: 3,
      EveningWe: 5,
      BedtimeLateNightSnack: 4,
    },
    countryOccasionsSaltySnacks: {
      AMSnack: 6,
      Lunch: 2,
      LunchAlt: 8,
      PMSnack: 7,
      AfterWork: 8,
      EveningMe: 10,
      EveningWe: 19,
      BedtimeLateNightSnack: 10,
    },
    countryOccasionsPringles: {
      AMSnack: 4,
      Lunch: 1,
      LunchAlt: 7,
      PMSnack: 7,
      AfterWork: 4,
      EveningMe: 12,
      EveningWe: 26,
      BedtimeLateNightSnack: 13,
    },
    sources: [
      {
        link: "Competitor Cheese SKUs by market - Masterfile July 2023",
        url: "https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/Competitor%20Cheese%20SKUs%20by%20market%20-%20Jun%20'23%20Masterfile.xlsx?alt=media&token=d6a14e90-6daa-4bce-bdb6-387b1a56dc86",
      },
      { link: "Masterfile Cheez-It growth Hack (Slide 89)", url: "" },
      {
        link: "IGD 2022 - Europe’s channel forecasts 2022 - 2027 (Slide 19)",
        url: "https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/Europe%E2%80%99s%20channel%20forecasts%202022%20-%202027%20%20(2).pdf?alt=media&token=ab9b5f5f-8469-406c-801b-13b678593fcc",
      },
      {
        link: "Expatrio.com - German Supermarkets",
        url: "https://www.expatrio.com/living-germany/costs-living-germany/german-supermarkets",
      },
      {
        link: "Statista - leading-companies-in-food-retail-germany",
        url: "https://www.statista.com/statistics/505129/leading-companies-in-food-retail-germany/",
      },
      {
        link: "Retail-Index - toprankingretailersingermany",
        url: "https://www.retail-index.com/countries/toprankingretailersingermany.aspx",
      },
      {
        link: "Esmmagazine.com - Top 10 supermarket retail chains in Germany",
        url: "https://www.esmmagazine.com/retail/top-10-supermarket-retail-chains-in-germany-236817",
      },
    ],
  },
  {
    cardTitle: "France (FR)",
    cardType: "Country Profile",
    cardSubTitle: "Cheese-flavored salty snacks: €74.2M",
    country: "France",
    countryAbbreviation: "FR",
    imageUrl: flag3,
    allSaltySnackSalesBillion: 2.6,
    allCheeseFlavouredSaltySnackSalesBillion: 0.0742,
    groceryChannelMarketShareInsightDescription: [
      "In the upcoming years, discount and online will gain share from all other channels",
      "Online growth will be driven by all major retailers, especially Leclerc, Carrefour and Intermarché. While click and collect will continue to account for most sales, home delivery will grow in France’s largest cities",
      "The expansion of the discount channel will be mainly due to the rapid growth of Lidl. Lidl will also benefit from the country’s current economic environment and strong new store opening plans",
      "Supermarkets will continue to account for most grocery sales, but we expect to see some sales switching to online",
      "The higher food prices are creating momentum for hypermarkets, but their large footprint and complex assortment will see shoppers continue to visit them less",
      "Convenience will remain stable with Casino and Carrefour expected to continue to perform well in city centres",
      "With more shoppers looking for local products, traditional retail is set to regain some traction in France. It should not lose as much market share as it is forecast to do in other Western European countries",
    ],
    groceryChannelMarketShareSaltySnacks2022: {
      superAndHypers: 53.6,
      convenience: 7.3,
      discount: 11.0,
      onlineGroceryRetail: 5.2,
      traditionalAndOtherModern: 22.9,
    },
    groceryChannelMarketShareAllGroceries2022: {
      superAndHypers: 50.5,
      convenience: 7.3,
      discount: 13.2,
      onlineGroceryRetail: 6.5,
      traditionalAndOtherModern: 22.4,
    },
    groceryChannelMarketShareAllGroceries2027: {
      superAndHypers: 57.7,
      convenience: 12.5,
      discount: 24.1,
      onlineGroceryRetail: 5.6,
      traditionalAndOtherModern: undefined,
    },
    top10PopularGroceryRetailersInsight: [
      "Leclerc is the largest grocery retailer in France with sales reaching more then €50B in 2022.",
      "Carrefour and Les Mousquetaires (the parent company of Intermarché, Bricomarché, Restaumarché, Ecomarché, and Netto) follow on a safe distance with respectively €35.3B and €36.1B in revenue.",
    ],
    top10PopularGroceryRetailers: [
      { name: "Leclerc", allGrocerySales2022Billion: 51.0 },
      {
        name: "Carrefour",
        allGrocerySales2022Billion: 35.3,
      },
      { name: "Les Mousquetaires", allGrocerySales2022Billion: 36.1 },
      {
        name: "Système U",
        allGrocerySales2022Billion: 23.0,
      },
      { name: "Auchan", allGrocerySales2022Billion: 16.2 },
      { name: "Casino", allGrocerySales2022Billion: 14.0 },
      { name: "Lidl", allGrocerySales2022Billion: 14.0 },
      { name: "Cora", allGrocerySales2022Billion: 5.1 },
      { name: "Metro", allGrocerySales2022Billion: 3.8 },
      { name: "Aldi", allGrocerySales2022Billion: 3.3 },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackManufacturersInsight: [
      "The €74M cheese-flavoured salty snack market is dominated by Pepsico with more then 50% market share",
      "Mondelez, Private Label, and Kellogg follow on a safe distance with respectively 23.7%, 16.6%, and 7.0% market share.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackManufacturers: [
      { type: "Manufacturer", name: "PEPSICO", marketShare: 0.504 },
      { type: "Manufacturer", name: "MONDELEZ", marketShare: 0.237 },
      { type: "Manufacturer", name: "PRIVATE LABEL", marketShare: 0.166 },
      { type: "Manufacturer", name: "KELLOGGS", marketShare: 0.07 },
      { type: "Manufacturer", name: "INTERSNACK", marketShare: 0.006 },
      { type: "Manufacturer", name: "MENGUY'S", marketShare: 0.003 },
      { type: "Manufacturer", name: "OTHER MANUFACTURERS", marketShare: 0.015 },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBrandsInsights: [
      "Doritos, Beline Croustilles, and Lay's (Bugles) dominate the cheese-flavoured salty snack market with respectively €23.3M, €17.6M, and €11.9M in cheese-flavoured snack sales.",
      "Also Private label takes a small bite of the market with €15.8M in cheese-flavoured salty snack sales.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBrands: [
      { type: "Brand", name: "DORITOS", marketShare: 0.273 },
      { type: "Brand", name: "BELIN CROUSTILLES", marketShare: 0.237 },
      { type: "Brand", name: "LAY'S (BUGLES)", marketShare: 0.16 },
      { type: "Brand", name: "PRIVATE LABEL", marketShare: 0.166 },
      { type: "Brand", name: "CHEETOS", marketShare: 0.071 },
      { type: "Brand", name: "PRINGLES", marketShare: 0.07 },
      { type: "Brand", name: "NOBRAND", marketShare: 0.004 },
      { type: "Brand", name: "MENGUY'S", marketShare: 0.003 },
      { type: "Brand", name: "VICO DIXI", marketShare: 0.001 },
      { type: "Brand", name: "OTHER BRANDS", marketShare: 0.015 },
    ],
    countryOccasionsTotalFoodInsights: [
      "After Work (Aperitif) occasion is the largest salty snack occasion in France and despite Pringles strength in this occasion, our index is still significantly below the category",
      "Pringles plays a stronger role in Family/Celebration Aperitif moments with opportunity for Cheez-It to have a greater relevance in largest Aperitif occasion (Post-Work Chilling)",
      "Like in UK & DE these Apero occasions often occur alone or with one other, with fewer items consumed – increasing the risk and the need to find the right snack that will deliver!",
    ],
    countryOccasionsTotalFood: {
      AMSnack: 4,
      Lunch: 18,
      LunchAlt: 6,
      PMSnack: 9,
      AfterWork: 8,
      EveningMe: 2,
      EveningWe: 4,
      BedtimeLateNightSnack: 3,
    },
    countryOccasionsSaltySnacks: {
      AMSnack: 7,
      Lunch: 7,
      LunchAlt: 9,
      PMSnack: 9,
      AfterWork: 19,
      EveningMe: 2,
      EveningWe: 5,
      BedtimeLateNightSnack: 4,
    },
    countryOccasionsPringles: {
      AMSnack: 5,
      Lunch: 5,
      LunchAlt: 10,
      PMSnack: 13,
      AfterWork: 25,
      EveningMe: 5,
      EveningWe: 6,
      BedtimeLateNightSnack: 5,
    },
    sources: [
      {
        link: "Competitor Cheese SKUs by market - Masterfile July 2023",
        url: "https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/Competitor%20Cheese%20SKUs%20by%20market%20-%20Jun%20'23%20Masterfile.xlsx?alt=media&token=d6a14e90-6daa-4bce-bdb6-387b1a56dc86",
      },
      { link: "Masterfile Cheez-It growth Hack (Slide 89)", url: "" },
      {
        link: "IGD 2022 - Europe’s channel forecasts 2022 - 2027 (Slide 19)",
        url: "https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/Europe%E2%80%99s%20channel%20forecasts%202022%20-%202027%20%20(2).pdf?alt=media&token=ab9b5f5f-8469-406c-801b-13b678593fcc",
      },
      {
        link: "Global Data Analysis - France-savory-snacks-market-analysis",
        url: "https://www.globaldata.com/store/report/france-savory-snacks-market-analysis/",
      },
      {
        link: "ESM Magazine - Top-10-supermarket-retail-chains-in-france",
        url: "https://www.esmmagazine.com/retail/top-10-supermarket-retail-chains-in-france-236416",
      },
    ],
  },
  {
    cardTitle: "Spain (SP)",
    cardType: "Country Profile",
    cardSubTitle: "Cheese-flavored salty snacks: €211M",
    country: "Spain",
    countryAbbreviation: "SP",
    imageUrl: flag4,
    allSaltySnackSalesBillion: 1.4,
    allCheeseFlavouredSaltySnackSalesBillion: 0.211,
    groceryChannelMarketShareInsightDescription: [
      "In the upcoming years, online, convenience and discount will lead the growth.",
      "The online grocery channel will be the fastest growing to 2027. Though small, grocery ecommerce has been given a large boost by the pandemic, although retailers will continue to invest in logistics to keep up with demand",
      "Convenience will see strong growth of 4.3%, gaining market share. The channel is expected to gain €2 bn to 2027, driven by Carrefour, Eroski and Auchan",
      "Discount will continue to be the largest channel in 2027, underpinned by the presence of market leader Mercadona. It is forecast to generate over €45 bn in sales in 2027, an increase of €7.3 bn from 2022",
      "Supermarkets are forecast to enjoy the second biggest change in value, at €4.5 bn, growing 3.4% annually to 2027, increasing its market share to 20.3% at the end of the timeframe",
      "Hypermarkets will see the second slowest growth to 2027. Its annual growth of 1.8% will see its market share fall by 0.6%",
      "The traditional channel will see the biggest decrease in market share, of 3.1%, with shoppers increasing their spend in the other retail channels",
    ],
    groceryChannelMarketShareSaltySnacks2022: {
      superAndHypers: 31.2,
      convenience: 7.0,
      discount: 31.1,
      onlineGroceryRetail: 1.8,
      traditionalAndOtherModern: 28.9,
    },
    groceryChannelMarketShareAllGroceries2022: {
      superAndHypers: 31.3,
      convenience: 7.6,
      discount: 32.3,
      onlineGroceryRetail: 3.1,
      traditionalAndOtherModern: 25.7,
    },
    groceryChannelMarketShareAllGroceries2027: {
      superAndHypers: 64.7,
      convenience: 21.8,
      discount: 12.5,
      onlineGroceryRetail: 1.0,
      traditionalAndOtherModern: undefined,
    },
    top10PopularGroceryRetailersInsight: [
      "By far, Mercadona is the largest grocery retailer in Spain with sales reaching more then €25B in 2022.",
      "El Corte Inglés and Carrefour follow on a safe distance with respectively €9.33B and €8.22B in revenue.",
    ],
    top10PopularGroceryRetailers: [
      { name: "Mercadona", allGrocerySales2022Billion: 25.3 },
      {
        name: "El Corte Inglés",
        allGrocerySales2022Billion: 9.33,
      },
      { name: "Carrefour", allGrocerySales2022Billion: 8.22 },
      {
        name: "Lidl",
        allGrocerySales2022Billion: 4.84,
      },
      { name: "Dia", allGrocerySales2022Billion: 2.13 },
      { name: "Eroski ", allGrocerySales2022Billion: 1.94 },
      { name: "Alcampo", allGrocerySales2022Billion: 1.87 },
      { name: "Auchan", allGrocerySales2022Billion: 1.47 },
      { name: "Consum", allGrocerySales2022Billion: 1.43 },
      { name: "Covirán", allGrocerySales2022Billion: 1.38 },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackManufacturersInsight: [
      "The €221M cheese-flavoured salty snack market is dominated by Pepsico with a cheese-flavoured salty snack sales revenue of more then €170M in 2022.",
      "The second biggest manufacturer, Risi follows at a very safe distance with €13.2M in cheese-flavoured salty snack sales.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackManufacturers: [
      { type: "Manufacturer", name: "PEPSICO", marketShare: 0.817 },
      { type: "Manufacturer", name: "RISI", marketShare: 0.063 },
      { type: "Manufacturer", name: "GREFUSA", marketShare: 0.039 },
      { type: "Manufacturer", name: "FRIT.RAVICH", marketShare: 0.026 },
      { type: "Manufacturer", name: "BIMBO", marketShare: 0.017 },
      { type: "Manufacturer", name: "RESTO FABRICANTES", marketShare: 0.016 },
      { type: "Manufacturer", name: "KELLOGGS", marketShare: 0.009 },
      {
        type: "Manufacturer",
        name: "APERITIVOS EXTRUSIONADOS",
        marketShare: 0.006,
      },
      { type: "Manufacturer", name: "TOSFRIT", marketShare: 0.003 },
      { type: "Manufacturer", name: "CUMBA", marketShare: 0.001 },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBrandsInsights: [
      "Pepsico and its Doritos, Cheetos, Lay's (Bugles), and Ruffles brands dominate the cheese-flavoured salty snack market",
      "The first non-PepsiCo brand is Risi Risketos with a market share of approximately 5%.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBrands: [
      { type: "Brand", name: "DORITOS", marketShare: 0.229 },
      { type: "Brand", name: "CHEETOS PELOTAZOS", marketShare: 0.148 },
      { type: "Brand", name: "CHEETOS PANDILLA", marketShare: 0.134 },
      { type: "Brand", name: "LAYS 3DS BUGLES", marketShare: 0.108 },
      { type: "Brand", name: "RUFFLES", marketShare: 0.059 },
      { type: "Brand", name: "RISI RISKETOS", marketShare: 0.049 },
      { type: "Brand", name: "CHEETOS GUSTOSINES", marketShare: 0.041 },
      { type: "Brand", name: "RESTO", marketShare: 0.037 },
      { type: "Brand", name: "SNATTS", marketShare: 0.032 },
      { type: "Brand", name: "CHEETOS RIZOS", marketShare: 0.022 },
      { type: "Brand", name: "OTHERS (65 BRANDS)", marketShare: 0.13 },
    ],
    sources: [
      {
        link: "Competitor Cheese SKUs by market - Masterfile July 2023",
        url: "https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/Competitor%20Cheese%20SKUs%20by%20market%20-%20Jun%20'23%20Masterfile.xlsx?alt=media&token=d6a14e90-6daa-4bce-bdb6-387b1a56dc86",
      },
      {
        link: "IGD 2022 - Europe’s channel forecasts 2022 - 2027 (Slide 40)",
        url: "https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/Europe%E2%80%99s%20channel%20forecasts%202022%20-%202027%20%20(2).pdf?alt=media&token=ab9b5f5f-8469-406c-801b-13b678593fcc",
      },
      {
        link: "ESM Magazine - Top-10-supermarket-retail-chains-in-spain",
        url: "https://www.esmmagazine.com/retail/top-10-supermarket-retail-chains-in-spain-229277",
      },
    ],
  },
  {
    cardTitle: "Italy (IT)",
    cardType: "Country Profile",
    cardSubTitle: "Cheese-flavored salty snacks: €110M",
    country: "Italy",
    countryAbbreviation: "IT",
    imageUrl: flag5,
    allSaltySnackSalesBillion: 4.9,
    allCheeseFlavouredSaltySnackSalesBillion: 0.11,
    groceryChannelMarketShareInsightDescription: [
      "In the upcoming years, online and discount will lead the growth.",
      "Online grocery will be the fastest growing channel to 2027, albeit from a small base. Retailers in Italy continue to expand their online services, including quick commerce and click and collect fulfilment options",
      "Discount will be the second largest modern channel in 2027. It has the biggest change in value at €7.7 bn, growing 5.9% annually to 2027",
      "Convenience is expected to gain €1.8 bn sales from 2022 2027, with a CAGR of 2.4%. We are seeing the opening of more innovative formats in the channel, such as the expansion of La Esse by Esselunga",
      "The traditional channel still plays a vital role in the country, growing 2.1% annually to 2027, and will remain its second largest channel",
      "The supermarket channel will lose the most market share to 2027. However, it is expected to add a further €7.4 bn in sales to 2027, presenting plenty of opportunities",
      "Hypermarkets will see the slowest sales growth to 2027. Its annual growth of 1.6% will see its market share decrease by 0.6%. More retailers will convert their largest stores to a compact hypermarket as they reduce selling space, especially for non grocery ranges",
    ],
    groceryChannelMarketShareSaltySnacks2022: {
      superAndHypers: 48.2,
      convenience: 7.3,
      discount: 12.0,
      onlineGroceryRetail: 1.0,
      traditionalAndOtherModern: 31.4,
    },
    groceryChannelMarketShareAllGroceries2022: {
      superAndHypers: 46.4,
      convenience: 7.2,
      discount: 14.0,
      onlineGroceryRetail: 1.7,
      traditionalAndOtherModern: 30.6,
    },
    groceryChannelMarketShareAllGroceries2027: {
      superAndHypers: undefined,
      convenience: undefined,
      discount: undefined,
      onlineGroceryRetail: undefined,
      traditionalAndOtherModern: undefined,
    },
    MarketShareTop10CheeseFlavouredSaltySnackManufacturersInsight: [
      "The €221M cheese-flavoured salty snack market in Italy is dominated by Mondelez with a cheese-flavoured salty snack sales revenue of more then €50M in 2022.",
      "The second biggest manufacturer, San Carlo at a very safe distance with €18.2M sales in cheese-flavoured salty snacks.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackManufacturers: [
      {
        type: "Manufacturer",
        name: "MONDELEZ INTERNATIONAL",
        marketShare: 0.511,
      },
      { type: "Manufacturer", name: "SAN CARLO", marketShare: 0.166 },
      { type: "Manufacturer", name: "PRIVATE LABEL", marketShare: 0.154 },
      { type: "Manufacturer", name: "PEPSICO", marketShare: 0.059 },
      { type: "Manufacturer", name: "KELLOGG", marketShare: 0.025 },
      { type: "Manufacturer", name: "AMICA CHIPS", marketShare: 0.025 },
      { type: "Manufacturer", name: "PREZIOSI FOOD", marketShare: 0.017 },
      { type: "Manufacturer", name: "CRIK CROK", marketShare: 0.011 },
      { type: "Manufacturer", name: "PATA", marketShare: 0.01 },
      { type: "Manufacturer", name: "GRANAROLO", marketShare: 0.009 },
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBrandsInsights: [
      "Mondelez and its Fonzies brand dominates the cheese-flavoured salty snack market with more then €50M in sales.",
      "With respectively €17M and €13M in sales, Private Label Brands and San Carlo Dixi complete the top 3.",
    ],
    MarketShareTop10CheeseFlavouredSaltySnackBrands: [
      { type: "Manufacturer", name: "FONZIES", marketShare: 0.462 },
      { type: "Manufacturer", name: "PRIVATE LABEL", marketShare: 0.154 },
      { type: "Manufacturer", name: "SAN CARLO DIXI", marketShare: 0.12 },
      { type: "Manufacturer", name: "DORITOS", marketShare: 0.054 },
      { type: "Manufacturer", name: "YONKERS", marketShare: 0.049 },
      { type: "Manufacturer", name: "PAI", marketShare: 0.04 },
      { type: "Manufacturer", name: "PRINGLES", marketShare: 0.025 },
      { type: "Manufacturer", name: "CRIK CROK PUFF", marketShare: 0.01 },
      {
        type: "Manufacturer",
        name: "AMICA CHIPS CORNETTO",
        marketShare: 0.009,
      },
      { type: "Manufacturer", name: "AMICA CHIPS BIP BIP", marketShare: 0.007 },
    ],
    sources: [
      {
        link: "Competitor Cheese SKUs by market - Masterfile July 2023",
        url: "https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/Competitor%20Cheese%20SKUs%20by%20market%20-%20Jun%20'23%20Masterfile.xlsx?alt=media&token=d6a14e90-6daa-4bce-bdb6-387b1a56dc86",
      },
      {
        link: "IGD 2022 - Europe’s channel forecasts 2022 - 2027 (Slide 25)",
        url: "https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/Europe%E2%80%99s%20channel%20forecasts%202022%20-%202027%20%20(2).pdf?alt=media&token=ab9b5f5f-8469-406c-801b-13b678593fcc",
      },
    ],
  },
  {
    cardTitle: "Poland (PL)",
    cardType: "Country Profile",
    cardSubTitle: "Cheese-flavored salty snacks",
    country: "Poland",
    countryAbbreviation: "PL",
    imageUrl: flag6,
    allSaltySnackSalesBillion: 0.829,
    allCheeseFlavouredSaltySnackSalesBillion: undefined,
    groceryChannelMarketShareInsightDescription: [
      "Discount will continue to dominate in Poland and gain further share by 2027",
      "With Aldi, Lidl and Biedronka opening new stores, and Tesco completing the conversion of stores to the Netto brand, the discount channel will see rapid growth",
      "Online will continue to grow strongly as more retailers invest in the channel following the change in shopping habits post pandemic. Look out for new channel entrants, such as Zabka in 2023, which will support further growth",
      "The convenience channel will experience strong growth, with a CAGR of 7.1% and a market share gain of 1.7%. This will mainly be driven by Zabka. The retailer continues to open new stores at pace and bring new concepts such as its autonomous format, Nano, and sustainable stores to the market, increasing its growth further",
      "Supermarket growth will be driven by local retailer Dino, with its strong value messaging resonating with shoppers in the challenging environment",
      "Hypermarkets will continue to lose share as shoppers seek out the value provided by the fast expanding discounters",
    ],
    groceryChannelMarketShareSaltySnacks2022: {
      superAndHypers: 25.3,
      convenience: 17.9,
      discount: 34.7,
      onlineGroceryRetail: 0.4,
      traditionalAndOtherModern: 21.6,
    },
    groceryChannelMarketShareAllGroceries2022: {
      superAndHypers: 26.1,
      convenience: 19.7,
      discount: 37.6,
      onlineGroceryRetail: 0.6,
      traditionalAndOtherModern: 16.1,
    },
    groceryChannelMarketShareAllGroceries2027: {
      superAndHypers: undefined,
      convenience: undefined,
      discount: undefined,
      onlineGroceryRetail: undefined,
      traditionalAndOtherModern: undefined,
    },
    top10PopularGroceryRetailersInsight: [
      "By far, Biedronka is the largest grocery retailer in Poland with more than double the revenue of the second largest grocery retailer, Lidl & Kaufland.",
    ],
    top10PopularGroceryRetailers: [
      { name: "Biedronka", allGrocerySales2022Billion: 14.9 },
      {
        name: "Lidl & Kaufland",
        allGrocerySales2022Billion: 7.05,
      },
      { name: "Eurocash", allGrocerySales2022Billion: 3.9 },
      {
        name: "Zabka",
        allGrocerySales2022Billion: 3.05,
      },
      { name: "Grupa Specjal", allGrocerySales2022Billion: 2.95 },
      { name: "Dino ", allGrocerySales2022Billion: 2.82 },
      { name: "Auchan", allGrocerySales2022Billion: 2.47 },
      { name: "Polska Grupa Supermarketów", allGrocerySales2022Billion: 1.87 },
      { name: "Carrefour", allGrocerySales2022Billion: 1.83 },
      { name: "Makro C&C (Metro)", allGrocerySales2022Billion: 1.19 },
    ],
    sources: [
      {
        link: "IGD 2022 - Europe’s channel forecasts 2022 - 2027 (Slide 31)",
        url: "https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/Europe%E2%80%99s%20channel%20forecasts%202022%20-%202027%20%20(2).pdf?alt=media&token=ab9b5f5f-8469-406c-801b-13b678593fcc",
      },
      {
        link: "ESM Magazine - Top-10-supermarket-retail-chains-in-poland",
        url: "https://www.esmmagazine.com/retail/top-10-supermarket-retail-chains-in-poland-237524",
      },
    ],
  },
];

export { countryProfilesIntro, countryProfiles };

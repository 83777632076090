import React from "react";
import "./BlackboxMekkoGraph.css";

import {
  competitorSKUs,
  competitorSKUsIntro,
} from "./knowledgeBaseData/competitorSKUs.js";

const SelectedMekkoAxises = {
  horizontalMekkoAxis: "mainTargetAudienceMekkoLegend",
  verticalMekkoAxis: "brand",
};

const MekkoData = aggregateSalesByAxes(competitorSKUs, SelectedMekkoAxises); // see function at the bottom of this page. This function puts the data into the right format for the Mekko Grap.

const MekkoHorizontalAxis = SelectedMekkoAxises.horizontalMekkoAxis;
const MekkoVerticalAxis = SelectedMekkoAxises.verticalMekkoAxis;

/* --------------------------------------------------------------------------------*/
/* --------------------------------------------------------------------------------*/
/* --------------------------------------------------------------------------------*/
/* --------------------------------------------------------------------------------*/

function MekkoGraph({ MekkoData }) {
  const horizontalMekkoCategories = [
    ...new Set(MekkoData.map((MekkoItem) => MekkoItem[MekkoHorizontalAxis])),
  ];
  const verticalMekkoCategories = [
    ...new Set(MekkoData.map((MekkoItem) => MekkoItem[MekkoVerticalAxis])),
  ];

  const totalSales = MekkoData.reduce((acc, item) => acc + item.totalSales, 0);

  const totalSalesByCategory1 = horizontalMekkoCategories.map((category) =>
    MekkoData.filter((item) => item[MekkoHorizontalAxis] === category).reduce(
      (acc, item) => acc + item.totalSales,
      0
    )
  );

  return (
    <div className='mekkoGraph'>
      {horizontalMekkoCategories.map((category, category1Index) => {
        const StyleColumn = {
          width: `${
            (totalSalesByCategory1[category1Index] / totalSales) * 100
          }%`,
        };
        return (
          <div className='mekkoGraph-categoryColumn' style={StyleColumn}>
            {MekkoData.filter(
              (item) => item[MekkoHorizontalAxis] === category
            ).map((item, itemIndex) => {
              const category1MarketShare =
                totalSalesByCategory1[category1Index] / totalSales;
              const itemMarketShareInCategory2Segment =
                item.totalSales / totalSalesByCategory1[category1Index];
              const style = {
                width: `100%`,
                height: `${itemMarketShareInCategory2Segment * 100}%`,
                backgroundColor: item.backgroundColor,
              };
              return (
                <div className='mekkoGraph-categoryColumn-item' style={style}>
                  <div
                    className={
                      5 < itemMarketShareInCategory2Segment * 100 ||
                      20 <
                        (totalSalesByCategory1[category1Index] / totalSales) *
                          100
                        ? "mekkoGraph-categoryColumn-item-label"
                        : "mekkoGraph-categoryColumn-item-label inactive"
                    }
                  >
                    {item[MekkoVerticalAxis]}
                  </div>
                </div>
              );
            })}
            <div className='mekkoGraph-categoryColumn-categoryLegend'>
              {category}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const BlackboxMekkoGraph = () => {
  return (
    <div>
      <MekkoGraph MekkoData={MekkoData} />
    </div>
  );
};
export default BlackboxMekkoGraph;

// This function puts the data into the right format for the Mekko Grap and sorts by totalSales.

function aggregateSalesByAxes(data, axises) {
  const aggData = {};

  data.forEach((item) => {
    const horizontalValue = item[axises.horizontalMekkoAxis];
    const verticalValue = item[axises.verticalMekkoAxis];
    const combinedKey = `${horizontalValue}-${verticalValue}`;

    if (!aggData[combinedKey]) {
      aggData[combinedKey] = {
        [axises.horizontalMekkoAxis]: horizontalValue,
        [axises.verticalMekkoAxis]: verticalValue,
        totalSales: 0,
        backgroundColor: getRandomColor(), // assigning random color
      };
    }
    aggData[combinedKey].totalSales += item.sales;
  });

  const sortedAggData = Object.values(aggData).sort(
    (a, b) => b.totalSales - a.totalSales
  );
  return sortedAggData;
}

// function to get random colors for the Mekko Graph items

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

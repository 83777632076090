import React, { useEffect } from "react";
import "./LandingPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import heroImageDesktop from "../assets/webp/heroImage_desktop_new.webp";
import heroImageMobile from "../assets/webp/heroImage_mobile.webp";
import bottleAnd3Boxes from "../assets/bottleAnd3Boxes.png";
import appAndAlgorithm from "../assets/appAndAlgorithm.png";
import bottleImage from "../assets/bottleImage.png";
import bottleAndBoxMobileHalf2 from "../assets/bottleAndBoxMobileHalf2.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const LandingPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const anchor = document.querySelector(location.hash);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div className='landingPage'>
      <div className='landingPage-left'>
        <div className='landingPage-left-text'>
          <div className='landingPage-left-text-titleMobile'>
            <div
              style={{
                fontSize: "1.8rem",
                fontWeight: "600",
                position: "relative",
                left: "-30px",
              }}
              className='howtoflyTitleMobile'
            >
              How To Fly
            </div>{" "}
            <div
              style={{
                fontSize: "1.8rem",
                fontWeight: "800",
                position: "relative",
                left: "30px",
              }}
              className='genAItitleMobile'
            >
              <div
                style={{
                  zIndex: "0",
                }}
                className='lineUnderMainTitleMobile'
              ></div>
              <div
                style={{
                  zIndex: "2",
                }}
                className='secondLineUnderMainTitleMobile'
              >
                with AI?
              </div>
            </div>
          </div>
          <p className='landingPage-left-text-textMobile'>
            Elevating innovation efforts by mixing Generative AI technology with
            the most valuable asset organizations have - their people, their
            collective intelligence.
          </p>
          <div className='landingPage-right-scrollText-mobile'>
            <p>
              start <span>experimenting</span>
              <br /> start <span>innovating</span>
              <br /> start <span>flying</span>
            </p>
            <Link
              className='roundScrollButton'
              to={{ pathname: "/", hash: "#howToAssistant" }}
              aria-label='Scroll to next section'
            >
              <FontAwesomeIcon icon={faArrowDown} />
            </Link>
          </div>
          <div style={{ position: "relative" }}>
            <div className='lineUnderMainTitle'></div>
            <h1 className='landingPage-left-text-titleDesktop'>
              How To Fly with AI?
            </h1>
          </div>
          <p className='landingPage-left-text-textDesktop'>
            Elevating innovation efforts by mixing Generative AI technology with
            the most valuable asset organizations have - their people, their
            collective intelligence.
          </p>
          <a className='howToFlyMailLink' href='mailto:gilles@howtofly.be'>
            contact us
          </a>
        </div>
      </div>
      <div className='landingPage-right'>
        <div className='landingPage-right-images'>
          <img
            className='landingPage-right-images-heroImageMobile'
            src={heroImageMobile}
            alt='HowToFly astronaut standing on piece of paper'
          />
          <img
            className='landingPage-right-images-heroImageDesktop'
            alt='HowToFly astronaut standing on piece of paper'
            src={heroImageDesktop}
          />
          <div className='landingPage-right-scrollText'>
            <p>
              start <span>experimenting</span>
              <br /> start <span>innovating</span>
              <br /> start <span>flying</span>
            </p>
            <Link
              className='roundScrollButton'
              to={{ pathname: "/", hash: "#howToAssistant" }}
              aria-label='Scroll to next section'
            >
              <FontAwesomeIcon icon={faArrowDown} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

// LandingPage.js
import React, { useEffect, useState } from "react";
import "./OurPromises.css";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faLightbulb,
  faShield,
  faRobot,
  faFlaskVial,
  faPeopleArrows,
  faCircleNodes,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, Pagination]);

const capabilities = [
  {
    icon: faPeopleArrows,
    title: "Inclusive Innovation",
    text: "Through storytelling and gamification techniques, we encourage every member of your team to contribute regardless of their technical background or expertise. This creates a lasting, fertile ground for groundbreaking end-to-end innovation.",
  },
  {
    icon: faLightbulb,
    title: "Supercharged Creativity",
    text: "By leveraging custom fine-tuned AI models and retrieval augmented generation (RAG), we supercharge the creativity of your entire workforce and aid them in generating creative ideas and novel approaches to make your organisation fly.",
  },
  {
    icon: faScrewdriverWrench,
    title: "Design Thinking Expertise",
    text: "Within everything we do, we employ design-thinking tools, to identify key customer needs, identify jobs-to-be-done, generate promising solutions, and design cost-effective experiments to quickly test promising concepts.",
  },
  {
    icon: faPaperPlane,
    title: "Rapid Prototyping",
    text: "Our skilled team of developers help you leverage cutting-edge AI technology to you quickly create mockups, prototypes, and MVPs of identified solutions, allowing for early idea validation in a cost-effective manner.",
  },
  {
    icon: faShield,
    title: "Secure Environment",
    text: "In line with your focus on data security, our platform ensures that all innovative processes are conducted in a secure, data-conscious environment.",
  },
];

const DesktopTileComponent = ({ icon, title, text }) => {
  return (
    <div className='OurPromisesPage-desktopSection-tile'>
      <div className='OurPromisesPage-desktopSection-tile-icon'>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className='OurPromisesPage-desktopSection-tile-title'>{title}</div>
      <div className='OurPromisesPage-desktopSection-tile-description'>
        {text}
      </div>
    </div>
  );
};

const MobileTileComponent = ({ icon, title, text }) => {
  return (
    <div className='OurPromisesPage-mobileSection-tile'>
      <div className='OurPromisesPage-mobileSection-tile-icon'>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className='OurPromisesPage-mobileSection-tile-title'>{title}</div>
      <div className='OurPromisesPage-mobileSection-tile-description'>
        {text}
      </div>
    </div>
  );
};

const OurPromises = () => {
  const location = useLocation();
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  useEffect(() => {
    if (location.hash) {
      const anchor = document.querySelector(location.hash);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div className='OurPromisesPage'>
      <section className='OurPromisesPage-desktopSection'>
        <div className='OurPromisesPage-desktopSection-connectionSquare'></div>
        <h2
          style={{ fontSize: "50px" }}
          className='OurPromisesPage-desktopSection-mainTitle'
        >
          Our
          <br /> Promises
        </h2>
        {capabilities.map((capability, index) => (
          <DesktopTileComponent
            key={index}
            icon={capability.icon}
            title={capability.title}
            text={capability.text}
          />
        ))}
      </section>
      <section className='OurPromisesPage-mobileSection'>
        <h2
          style={{ fontSize: "50px" }}
          className='OurPromisesPage-mobileSection-mainTitle'
        >
          Our
          <br />
          Promises
        </h2>
        <Swiper
          style={{ width: "100%" }}
          pagination={{ type: "bullets" }}
          index={index}
          onSlideChange={(swiper) => {
            handleChangeIndex(swiper.activeIndex);
          }}
          autoplay={{ delay: 10000 }}
        >
          {capabilities.map((capability, i) => (
            <SwiperSlide key={i}>
              <MobileTileComponent
                key={i}
                icon={capability.icon}
                title={capability.title}
                text={capability.text}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
};

export default OurPromises;

import React from "react";
import "./IntroductionPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function IntroductionPage() {
  return (
    <div className='IntroductionPage'>
      <p>
        What we promise ain't no rabbit in a hat, ain't no magic, ain't no
        Copperfield, but{" "}
        <b>
          the pure power of cutting-edge generative AI-technology combined with
          your collective intelligence.
        </b>{" "}
        We help you identify the biggest opportunities to augment your brand,
        prototype your ideas, validate your vision, and make your business fly
        with generative AI.
      </p>
      <Link
        className='roundScrollButton'
        to={{ pathname: "/", hash: "#howToAssistant" }}
        aria-label='Scroll to next section'
      >
        <FontAwesomeIcon icon={faArrowDown} />
      </Link>
    </div>
  );
}

export default IntroductionPage;

import { useState } from "react";
//import firebase services
import { auth, db } from "../firebase/config";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import { sendEmailVerification } from "firebase/auth";

// define the custom hook to signup a user
export const useSignup = () => {
  const [error, setError] = useState(null);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const { dispatch } = useAuthContext();

  const signup = async (email, password, playerName) => {
    setError(null);
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      //update displayName if user is created and playername exists
      if (res && playerName) {
        const displayName = playerName;
        //update user profile with playerName
        try {
          await updateProfile(auth.currentUser, { displayName: displayName });
        } catch (e) {
          console.log(e.message);
        }
      }
      if (res) {
        await sendEmailVerification(res.user);
        setVerificationEmailSent(true);
      }
      if (!res) {
        throw new Error("Could not complete signup");
      }
    } catch (e) {
      setError(e.message);
    }
  };

  return { error, signup, verificationEmailSent };
};

import React, { useState } from "react";
import "./BlackboxLoginPage.css";

// Bootstrap components
import Spinner from "react-bootstrap/Spinner";

// import images
import BlackboxLogo from "../assets/BlackboxLogo.png";

// import custom hooks
import { useLogin } from "../hooks/useLogin";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useResetPassword } from "../hooks/useResetPassword";

export default function BlackboxLoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // initiate custom hooks
  const { login, error } = useLogin();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { resetPassword, notification, error: resetError } = useResetPassword();
  const { height, width } = useWindowDimensions();

  // event handle functions
  const handleUsernameChange = (event) => {
    setEmail(event.target.value.toLowerCase());
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    login(email, password);
  };

  const handleResetPassword = () => {
    if (email) {
      resetPassword(email);
    } else {
      console.log("Please enter your email to reset password.");
    }
  };

  return (
    <div className='BlackboxLoginPage'>
      <div className='BlackboxLoginPage-loginCard'>
        <img
          className='BlackboxLoginPage-logo'
          src={BlackboxLogo}
          alt='BlackboxLogo'
        />
        <div className='BlackboxLoginPage-loginCard-header'></div>
        <div className='BlackboxLoginPage-loginCard-body'>
          <form>
            <label htmlFor='email'>Email</label>
            <input
              type='text'
              id='email'
              name='email'
              placeholder='email'
              value={email}
              onChange={handleUsernameChange}
            />
            <label htmlFor='password'>Password</label>
            <input
              type='password'
              id='password'
              name='password'
              placeholder='password'
              value={password}
              onChange={handlePasswordChange}
            />
            <p className='forgotPassword' onClick={handleResetPassword}>
              Forgot password?
            </p>
            <button
              className='submitButton'
              type='submit'
              onClick={handleSubmit}
            >
              {!error && isSubmitting && (
                <Spinner
                  className='loadingSpinner'
                  animation='border'
                  size='sm'
                />
              )}{" "}
              Login
            </button>
            <div>{/* ... other JSX elements */}</div>
          </form>
          {/* Display notification below the form */}
          {notification && (
            <p className='notificationMessage'>{notification}</p>
          )}
          {/* If you want to display errors separately, add this */}
          {resetError && <p className='errorMessage'>{resetError}</p>}
          {/* ... other components */}
          <div className='BlackboxLoginPage-loginCard-footer'>
            <p
              style={{
                color: "rgb(247, 168, 0)",
                width: "80%",
                margin: "0px auto",
              }}
            >
              {error}
            </p>
            <p>
              {!error && (
                <span>
                  Don't have an account?
                  <br />
                </span>
              )}
              {error && (
                <>
                  {!error.includes("verify") && (
                    <span>
                      Subscribe <a href='/register'>here</a>
                    </span>
                  )}
                </>
              )}
              {!error && (
                <>
                  <span>
                    Subscribe <a href='/register'>here</a>
                  </span>
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { auth, db, storage } from "../firebase/config";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Configuration, OpenAIApi } from "openai";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles

//import bootstrap components
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

// import styles
import "bootstrap/dist/css/bootstrap.min.css";

// import fontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faFilter,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";

// import image assets
import placeholderImageNeeds from "../assets/webpIdeas/placeholderIdeas.webp";
import { use } from "i18next";
import { set } from "lodash";

export default function BlackboxCISolutionSharingModal({
  ciSolutionsData,
  setCiSolutionsData,
  solutionModalShow,
  setSolutionModalShow,
  playersData,
  buildDeckContent,
  setBuildDeckVisible,
  solutionModalFromBuildDeck,
  customTheme,
}) {
  const [newSolution, setNewSolution] = useState({
    topic: {
      marketing: false,
      product: false,
      packaging: false,
    },
    ideaTitle: "",
    ideaDescription: "",
    tripleWin: "",
  });
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [errorIsLoadingSuggestions, setErrorIsLoadingSuggestions] =
    useState(false);
  const textAreaRef = useRef(null);

  // prop type validations

  BlackboxCISolutionSharingModal.propTypes = {
    ciSolutionsData: PropTypes.array.isRequired,
    // ... other props validation
  };

  // handle functions
  let apiContextData = [];

  const handleOpenAIRequest = async (e) => {
    e.preventDefault();
    if (newSolution.topic.marketing === true) {
      apiContextData.push(
        "Please make sure that the idea is related to marketing and can help to boost the European launch of Cheez-It Snap'd in the short term, or boost Cheez-It brand expansion in the long run."
      );
    }
    if (newSolution.topic.product === true) {
      apiContextData.push(
        "Please make sure that the idea is product related and can fuel our innovation roadmpa to expand the Cheez-It brand in Europe."
      );
    }
    if (newSolution.topic.packaging === true) {
      apiContextData.push(
        "Please make sure that the idea helps to improve how Kellanov packs it's products."
      );
    }
    if (buildDeckContent.some((item) => item.type === "Retailer Profile")) {
      const retailers = buildDeckContent.filter(
        (item) => item.type === "Retailer Profile"
      );
      const retailersStrategicPriorities = retailers.map((retailer) => {
        const name = retailer.knowledgeBaseItem.cardTitle;
        const strategicPriorities =
          retailer.knowledgeBaseItem.strategicPriorities;
        return { name, strategicPriorities };
      });
      const requestToApi = `To make sure the idea can help retailers achieve their ambitions, please take into account the following strategic priorities of the following grocery retailer(s): ${retailersStrategicPriorities
        .map((item) => {
          const priorities = item.strategicPriorities.map((priority) => {
            return `${priority.title}: ${priority.description.join(" , ")}`;
          });
          return `${item.name}: [${priorities.join(" | ")}]`;
        })
        .join(", ")}
        
        Always mention the relevant retailer(s) at least once in your triple win description. `;

      apiContextData.push(requestToApi);
    }
    if (buildDeckContent.some((item) => item.type === "Persona")) {
      const chosenPersonas = buildDeckContent.filter(
        (item) => item.type === "Persona"
      );
      const personaProfile = chosenPersonas.map((persona) => {
        const name = persona.knowledgeBaseItem.cardTitle;
        const personaDescription =
          persona.knowledgeBaseItem.illustrativeJourney.ConsumerBio;
        return { name, personaDescription };
      });
      const requestToApi = `  To make sure the idea can augment the European consumer snacking experrience, please take into account the following persona which represents our primary European consumer: ${personaProfile.map(
        (item) => {
          return `${item.name}: ${item.personaDescription}`;
        }
      )}`;

      apiContextData.push(requestToApi);
    }
    if (buildDeckContent.some((item) => item.type === "Country Profile")) {
      const chosenCountries = buildDeckContent.filter(
        (item) => item.type === "Country Profile"
      );
      const requestToApi = `  Please take into account some key information about the following country when creating your idea. The idea should tailor to the needs of retailers and consumers in this country: ${chosenCountries.map(
        (item) => {
          return `Country Name: ${
            item.knowledgeBaseItem.country
          } | Market Size Cheese-Flavoured Salty Snacks:  ${
            item.knowledgeBaseItem.allCheeseFlavouredSaltySnackSalesBillion
              ? item.knowledgeBaseItem.allCheeseFlavouredSaltySnackSalesBillion
              : "No data available"
          } | GroceryChannels: ${
            item.knowledgeBaseItem.groceryChannelMarketShareInsightDescription
          } | MostPopularGroceryRetailers: ${
            item.knowledgeBaseItem.top10PopularGroceryRetailersInsight
          } | BiggestCheese-FlavouredSaltySnackManufacturers: ${
            item.knowledgeBaseItem
              .MarketShareTop10CheeseFlavouredSaltySnackManufacturersInsight
              ? item.knowledgeBaseItem
                  .MarketShareTop10CheeseFlavouredSaltySnackManufacturersInsight
              : "No data available"
          } | BiggestCheese-FlavouredSaltySnackBrand: ${
            item.knowledgeBaseItem
              .MarketShareTop10CheeseFlavouredSaltySnackBrandsInsights
              ? item.knowledgeBaseItem
                  .MarketShareTop10CheeseFlavouredSaltySnackBrandsInsights
              : "No data available"
          } | MostPopularSnackingOccasionsInCountry: ${
            item.knowledgeBaseItem.countryOccasionsTotalFoodInsights
              ? item.knowledgeBaseItem.countryOccasionsTotalFoodInsights
              : "No data available"
          }`;
        }
      )} If possible, please mention the country at least once in your idea description and how your idea addresses the specific needs of the country.`;

      apiContextData.push(requestToApi);
    }
    if (buildDeckContent.some((item) => item.type === "Competitor SKU")) {
      const chosenCompetitorSKU = buildDeckContent.filter(
        (item) => item.type === "Competitor SKU"
      );
      const competitorSKUProfile = chosenCompetitorSKU.map((sku) => {
        const name = sku.knowledgeBaseItem.cardTitle;
        const skuDescription = sku.knowledgeBaseItem.name;
        const skuBaseIngredient = sku.knowledgeBaseItem.baseIngredient;
        return { name, skuDescription, skuBaseIngredient };
      });
      const requestToApi = `  Please alter/combine the following competitor SKU(s) into an idea that leverages the strengths of the Cheez-It brand: ${competitorSKUProfile.map(
        (item) => {
          return `${item.name}: ${item.skuDescription} a ${item.skuBaseIngredient} based snack`;
        }
      )} If possible, please mention the competing SKU(s) at least once in your idea description and how your idea combines the strengths of the Cheez-It brand with the strengths of the competing SKU(s).`;

      apiContextData.push(requestToApi);
    }
    if (buildDeckContent.some((item) => item.type === "Cheez-It SKU")) {
      const chosenCheezitSKUs = buildDeckContent.filter(
        (item) => item.type === "Cheez-It SKU"
      );
      const cheezitSKUProfile = chosenCheezitSKUs.map((sku) => {
        const brand = sku.knowledgeBaseItem.cardTitle;
        const subBrand = sku.knowledgeBaseItem.cardSubTitle;
        const skuDescription = sku.knowledgeBaseItem.productDescription;
        return { brand, skuDescription, subBrand };
      });
      const requestToApi = `  Please alter/combine the following Cheez-It SKU(s) into an idea that leverages the strengths of the Cheez-It brand: ${cheezitSKUProfile.map(
        (item) => {
          return `${item.brand}-${item.subBrand}: ${item.skuDescription}`;
        }
      )} If possible, please mention the Cheez-It SKU(s) at least once in your idea description and how your idea leverages the strengths of the Cheez-It SKU(s).`;

      apiContextData.push(requestToApi);
    }
    if (buildDeckContent.some((item) => item.type === "Idea")) {
      const chosenIdeas = buildDeckContent.filter(
        (item) => item.type === "Idea"
      );
      const ideaProfile = chosenIdeas.map((idea) => {
        const ideaTitle = idea.cardTitle;
        const ideaDescription = idea.cardContent;
        const ideatripleWin = idea.cardTriplleWin;
        return { ideaTitle, ideaDescription, ideatripleWin };
      });
      const requestToApi = `  Please creatively alter/combine the following idea(s) into a new idea that leverages the relevant strengths of the idea : ${ideaProfile.map(
        (item) => {
          return `Idea Title: ${item.ideaTitle} | Idea Description:  ${item.ideaDescription} | Idea triple win: ${item.ideatripleWin}`;
        }
      )} If possible, please mention the idea title at least once in your idea description and how your idea leverages the strengths of the idea and turned it into a new idea.`;

      apiContextData.push(requestToApi);
    }
    setErrorIsLoadingSuggestions(false);
    setIsLoadingSuggestions(true);

    const configuration = new Configuration({
      organization: "org-h1bJK5iOzVXDfN6bVEer5gaP",
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    try {
      const openai = new OpenAIApi(configuration);
      const finalMessage = `

      Kellanova (the salty snack division of Kellogg and owns brands such as Cheez-It and Pringles) is planning to introduce their American brand Cheez-it within the European market. I'm brainsorming on some cretaive ideas to fuel the innovation roadmap which are capable of achieving a triple win: augment the salty snack experience of European consumers, help retailers to achieve their stragegic ambitions and drive Kellanova's growth and profit.
      
      Please assist me with completing the following ${Object.keys(
        newSolution.topic
      )
        .filter((key) => newSolution.topic[key] === true)
        .join(`/`)} idea

          {
            "ideaTitle": "${
              newSolution.ideaTitle
            }",  /* keep this title as is in your response*/  
            "ideaDescription": "${newSolution.ideaDescription}",
            "tripleWin": "${newSolution.tripleWin}",
          }

          Make sure that the ideaDescription gives a clear explanation why the idea has the title "${
            newSolution.ideaTitle
          }" . If the title is an empty string, please generate a creative title for me.

          !important!!: ${apiContextData}

          also, whenever I have ...  in my text, make sure to complete the sentence. 

          Also, whenever I have (such as ...) in my text, make sure to add a list of relevant examples.

          Furthermore, whenever I have (e.g. ...) in my text, make sure to add a list of relevant examples.

          Finally, very Important. Always return me a completed JSON object without any additional text. The "ideaDescription" and "tripleWin" keys should have a value of at least 50 characters. The ideaTitle should at least have 8 characters. You should meet these requirments, otherwise I will not be able to submit your idea. 
            `;
      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: finalMessage }],
      });
      const AIresponse = completion.data.choices[0].message.content;
      const jsonObjectOfAIresponse = JSON.parse(AIresponse);
      console.log(
        "message sent",
        finalMessage,
        "jsonObjectOfAIresponse",
        jsonObjectOfAIresponse
      );

      setTimeout(() => {
        setNewSolution((prevState) => ({
          ...prevState,
          ideaTitle: jsonObjectOfAIresponse.ideaTitle,
        }));

        setTimeout(() => {
          setNewSolution((prevState) => ({
            ...prevState,
            ideaDescription: jsonObjectOfAIresponse.ideaDescription,
          }));

          setTimeout(() => {
            setNewSolution((prevState) => ({
              ...prevState,
              tripleWin: jsonObjectOfAIresponse.tripleWin,
            }));
          }, 100); // waits 0.1 second before executing the third update
        }, 100); // waits 0.1 second before executing the second update
      }, 100); // waits 0.1 second before executing the first update
      setIsLoadingSuggestions(false);
    } catch (error) {
      console.error("Error:", error);
      setErrorIsLoadingSuggestions(true);
      setIsLoadingSuggestions(false);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
    }
  };

  const handleTopicChange = (event) => {
    setNewSolution((prevState) => ({
      ...prevState,
      topic: {
        ...prevState.topic,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  const handleSolutionInputChange = (event) => {
    setNewSolution({ ...newSolution, [event.target.name]: event.target.value });
  };

  const handleQuillChange = (content, fieldName) => {
    setNewSolution({ ...newSolution, [fieldName]: content });
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleCiSolutionSubmit = async () => {
    let imageFileURL = placeholderImageNeeds; // Default to the current image value.

    // First, check if there's a selected file and upload it to Firebase Storage.
    if (selectedFile) {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`solutionsImages/${selectedFile.name}`);
      setUploading(true);

      try {
        await fileRef.put(selectedFile);
        imageFileURL = await fileRef.getDownloadURL(); // Directly assign the file URL.
        setUploading(false);
      } catch (error) {
        setUploading(false);
        return; // Exit if there's an error to prevent saving to Firestore.
      }
    }

    const userId = auth.currentUser.uid;
    let solutionData = {};

    // Use the imageFileURL directly.
    if (
      solutionModalFromBuildDeck &&
      buildDeckContent.some((item) => item.empty === false)
    ) {
      solutionData = {
        ...newSolution,
        image: imageFileURL, // Set the image URL here.
        userId,
        inspiration: buildDeckContent.filter((item) => item.empty === false),
        argumentsVisible: false,
        creationDate: new Date(),
      };
    } else {
      solutionData = {
        ...newSolution,
        image: imageFileURL, // Set the image URL here.
        userId,
        argumentsVisible: false,
        creationDate: new Date(),
      };
    }

    // Push to Firebase
    let newDocRef = null;
    try {
      newDocRef = await db.collection("ciSolutions").add(solutionData);
    } catch (e) {
      console.log("error message when trying to store solution", e.message);
    }

    if (newDocRef) {
      // Get the ID of the newly created solution
      const ciSolutionId = newDocRef.id;

      // Add the solutionId to the solutionData object
      const completeSolutionData = {
        ...solutionData,
        ciSolutionId: ciSolutionId,
      };

      // Update local state with the complete data
      if (Array.isArray(ciSolutionsData)) {
        setCiSolutionsData([...ciSolutionsData, completeSolutionData]);
      } else {
        console.error("ciSolutionsData is not an array:", ciSolutionsData);
        setCiSolutionsData([completeSolutionData]); // Default to just the new solution data if the existing data is not iterable.
      }
    }

    setSelectedFile(null);
    setSolutionModalShow(false);
    setBuildDeckVisible(false);
  };

  const getAudiences = () => {
    const audiences = ciSolutionsData.map((item) => item.audience);
    // Convert challenges array to Set to get unique values, then convert it back to array
    const uniqueAudiences = [...new Set(audiences)];
    return uniqueAudiences;
  };

  return (
    <Modal
      show={solutionModalShow}
      onHide={() => setSolutionModalShow(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='BlackboxCISolutions-shareSolutionsModal'
    >
      <Modal.Header
        closeButton
        className={
          customTheme
            ? "BlackboxCISolutions-shareSolutionsModal-header customTheme"
            : "BlackboxCISolutions-shareSolutionsModal-header"
        }
      >
        <Modal.Title id='contained-modal-title-vcenter'>
          Share Your Idea
        </Modal.Title>
        <Button
          className='randomSolutionGeneratorButton'
          onClick={handleOpenAIRequest}
          disabled={isLoadingSuggestions}
        >
          {isLoadingSuggestions ? (
            <Spinner className='loadingSpinner' animation='border' size='sm' />
          ) : (
            <FontAwesomeIcon
              className='randomSolutionGeneratorButtonIcon'
              icon={faRobot}
            />
          )}{" "}
          <span>Auto-Complete</span>
        </Button>
      </Modal.Header>
      <Modal.Body className='BlackboxCISolutions-shareSolutionsModal-body'>
        <Form>
          {solutionModalFromBuildDeck &&
            buildDeckContent.some((item) => item.empty === false) && (
              <div className='BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection'>
                <h4>Build on:</h4>
                <div className='BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection-items'>
                  {buildDeckContent
                    .filter((item) => item.empty === false)
                    .map((item, index) => (
                      <div className='BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection-items-item'>
                        <img src={item.imageUrl} />
                        <div>{item.title}</div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Relevant Topic(s)
            </Form.Label>
            <div className='BlackboxCISolutions-shareSolutionsModal-body-topics'>
              <Form.Check
                inline
                label='Marketing'
                name='marketing'
                type='checkbox'
                checked={newSolution.topic.marketing}
                onChange={handleTopicChange}
                className='customCheckbox'
              />
              <Form.Check
                inline
                label='Product'
                name='product'
                type='checkbox'
                checked={newSolution.topic.product}
                onChange={handleTopicChange}
                className='customCheckbox'
              />
              <Form.Check
                inline
                label='Packaging'
                name='packaging'
                type='checkbox'
                checked={newSolution.topic.packaging}
                onChange={handleTopicChange}
                className='customCheckbox'
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Idea Title
            </Form.Label>
            <Form.Control
              ref={textAreaRef}
              as='textarea'
              placeholder='provide a title for your idea.'
              name='ideaTitle'
              onChange={handleSolutionInputChange}
              value={newSolution.ideaTitle}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              rows={1}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              💡 Idea Description
            </Form.Label>
            <ReactQuill
              ref={textAreaRef}
              as='textarea'
              placeholder='shortly describe your idea...'
              name='ideaDescription'
              onChange={(content) =>
                handleQuillChange(content, "ideaDescription")
              }
              value={newSolution.ideaDescription}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              rows={3}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              🎯 Triple-Win
            </Form.Label>
            <ReactQuill
              ref={textAreaRef}
              as='textarea'
              placeholder='shortly describe why this can be a win for consumers, retailers and/or Kellanova...'
              name='tripleWin'
              onChange={(content) => handleQuillChange(content, "tripleWin")}
              value={newSolution.tripleWin}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              rows={3}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              📷 Idea Image
            </Form.Label>
            <div
              {...getRootProps()}
              className='image-dropperContainer idea-sharingModal'
            >
              <input {...getInputProps()} />
              <Button className='image-dropperContainer-chooseFileButton'>
                Choose Image
              </Button>
              {selectedFile ? (
                <div className='image-dropperContainer-image-drop-zone'>
                  {selectedFile.path}
                </div>
              ) : (
                <div className='image-dropperContainer-image-drop-zone'>
                  or drop an image here...
                </div>
              )}
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer
        className='BlackboxCISolutions-shareSolutionsModal-footer'
        style={{ background: "rgb(34,34,42)" }}
      >
        {errorIsLoadingSuggestions ? (
          <span style={{ color: "red", marginRight: "50px" }}>
            Something went wrong with the inspiration, please try again
          </span>
        ) : (
          <span></span>
        )}
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleCiSolutionSubmit(e);
          }}
          className='BlackboxCISolutions-shareSolutionsModal-footer-submitButton'
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

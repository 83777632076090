import React, { useState } from "react";

//import bootstrap components
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import DOMPurify from "dompurify";

// import styles
import "bootstrap/dist/css/bootstrap.min.css";

//import styles and assets
import "./BlackboxHomePage.css";

// Import fontAwesome icon components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faTrash,
  faPaperPlane,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, Pagination]);

export default function BlackboxHomePage() {
  const [newQuestion, setNewQuestion] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [processingAiRequest, setProcessingAiRequest] = useState(false);
  const [aiResponse, setAiResponse] = useState(null);
  const [aiRequestError, setAiRequestError] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);

  const handleChangeSwiperIndex = (i) => {
    setSwiperIndex(i);
  };

  const inspirationCards = [
    {
      title: "Context & Objectives",
      text: "Why are we doing a Cheez-It online ideation & growth hack? What are the main objectives?",
    },
    {
      title: "Expectations",
      text: "What is expected from me as a participant in the online ideation? How can I participate?",
    },
    {
      title: "Timeline",
      text: "What is the timeline of the Cheez-It online ideation?",
    },
    {
      title: "Share Ideas",
      text: "How can I share ideas on the platform?",
    },
    {
      title: "Launch Plan",
      text: "What is the current launch plan for Cheez-It in Europe?",
    },
    {
      title: "Experience Points",
      text: "How can I gain experience points and rise in the rankings?",
    },
  ];

  // Handle Functions
  const handleQuestionInputChange = (e) => {
    setNewQuestion(e.target.value);
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    setAiResponse(null);
    setCurrentQuestion(newQuestion);
    setProcessingAiRequest(true);
    setAiRequestError(null);
    console.log("question submitted:", newQuestion);
    try {
      const res = await axios.post(
        "https://cheezit-chat-i4licugc4a-ew.a.run.app/generate_response",
        {
          question: newQuestion,
        }
      );
      const data = res.data;
      setAiResponse(data.response);
    } catch (error) {
      console.error("Error:", error);
      setAiRequestError(
        "Sorry, something went wrong :(. We're currently unable to answer your question, please try again"
      );
    }

    setNewQuestion("");
    setProcessingAiRequest(false);
  };

  const handleDeleteResponse = () => {
    setAiResponse(null);
    setAiRequestError(null);
    setCurrentQuestion("");
  };

  const handleInspirationCardClick = (e) => {
    const cardText = e.currentTarget.getElementsByTagName("p")[0].textContent;
    setNewQuestion(cardText);
  };

  return (
    <div className='BlackboxHomePage'>
      <h1>Welcome to the Cheez-It Growth Hack Ideaton</h1>
      <Form>
        <Form.Group>
          <TextareaAutosize
            minRows={2}
            name='question'
            placeholder='ask me anything about the platform and the project context...'
            onChange={handleQuestionInputChange}
            value={newQuestion}
            className='BlackboxHomePage-form-questionInputField'
          />
        </Form.Group>
        <Button
          type='submit'
          onClick={handleQuestionSubmit}
          className='BlackboxHomePage-form-questionSubmitButton'
          style={{
            borderRadius: "90%",
            color: "white",
            border: "none",
            width: "30px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgb(250,115,85)",
          }}
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            className='BlackboxHomePage-form-questionSubmitButtonIcon'
            style={{ fontSize: "0.7rem" }}
          />
        </Button>
      </Form>
      <div className='BlackboxHomePage-inspiration'>
        {(processingAiRequest || aiResponse || aiRequestError) && (
          <div className='BlackboxHomePage-inspiration-titleContainer'>
            <h1 className='BlackboxHomePage-inspiration-title'>
              <FontAwesomeIcon
                className='BlackboxHomePage-inspiration-title-icon'
                icon={faLightbulb}
              />
              {currentQuestion}
            </h1>
            <button onClick={handleDeleteResponse}>
              <FontAwesomeIcon
                className='BlackboxHomePage-inspiration-title-icon'
                icon={faTrash}
              />
            </button>
          </div>
        )}
        {!processingAiRequest && !aiResponse && !aiRequestError && (
          <h1 className='BlackboxHomePage-inspiration-title'>
            <FontAwesomeIcon
              className='BlackboxHomePage-inspiration-title-icon'
              icon={faLightbulb}
            />
            Some inspiration for questions
          </h1>
        )}
        {!aiResponse && !processingAiRequest && !aiRequestError && (
          <div className='BlackboxHomePage-inspiration-cards'>
            {inspirationCards.map((inspirationCard, cardIndex) => (
              <div
                key={cardIndex}
                className='BlackboxHomePage-inspiration-cards-card'
                onClick={handleInspirationCardClick}
              >
                <h5>{inspirationCard.title}</h5>
                <p>{inspirationCard.text}</p>
              </div>
            ))}
          </div>
        )}
        {!aiResponse && !processingAiRequest && !aiRequestError && (
          <div className='BlackboxHomePage-inspiration-cards mobile'>
            <Swiper
              style={{ width: "100%" }}
              pagination={{ type: "bullets" }}
              index={swiperIndex}
              onSlideChange={(swiper) => {
                handleChangeSwiperIndex(swiper.activeIndex);
              }}
              slidesPerView={1}
              autoplay={{ delay: 10000 }}
            >
              {inspirationCards.map((inspirationCard, cardIndex) => (
                <SwiperSlide key={cardIndex} className='swiper-slide'>
                  <div
                    key={cardIndex}
                    className='BlackboxHomePage-inspiration-cards-card'
                    onClick={handleInspirationCardClick}
                  >
                    <h5>{inspirationCard.title}</h5>
                    <p>{inspirationCard.text}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        {processingAiRequest && (
          <div>
            <Spinner
              animation='border'
              role='status'
              style={{ width: "16px", height: "16px" }}
            />
            <span style={{ marginLeft: "15px" }}>
              We're currently producing a custom answer to your question, wait
              10-30 seconds...
            </span>
          </div>
        )}
        {aiRequestError && <div>{aiRequestError}</div>}
        {aiResponse && (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(aiResponse),
            }}
          ></div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Switch from "react-switch";

//import firebase database
import { auth, db } from "../firebase/config";

//import bootstrap components
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import DOMPurify from "dompurify";

// import styles
import "bootstrap/dist/css/bootstrap.min.css";

//import styles and assets
import "./BlackboxHomePageVideo.css";

// import images
import moreInfoVideoPlaceholder from "../assets/MoreInfoVideoPlaceholder.png";
import foodDescriptionCheezIt from "../assets/FoodDescriptionCheezIt.png";
import launchPlanTimeline from "../assets/LaunchPlanTimeline.png";
import ShareIdeaVisual from "../assets/InfoShareIdeaVisual.png";
import ShareIdeaModal from "../assets/ShareIdeaModal.png";
import AutocompleteFunction from "../assets/AutocompleteFunction.png";
import BuildDeckIcon from "../assets/BuildDeckIcon.png";
import AddIdeaToDeckVisual from "../assets/AddIdeaToDeckVisual.png";
import BuildIdeaDeck from "../assets/BuildIdeaDeck.png";
import ShareIdeasMenuVisual from "../assets/ShareIdeasMenuVisual.png";
import QuestionMarkIcon from "../assets/QuestionMarkIcon.png";
import IdeationPlatform from "../assets/IdeationPlatform.png";
import AskMeAnythingInputField from "../assets/AskMeAnythingInputField.png";
import NavigationMenuVisual from "../assets/NavigationMenuVisual.png";
import CheezIt from "../assets/Cheez-It.png";
import CiIcon from "../assets/CI-Icon.png";

// import webp images
import contextAndObjectives from "../assets/webpHomepage/Context&Objectives.webp";
import launchPlanVisual from "../assets/webpHomepage/LaunchPlanVisual.webp";
import howToGetStarted from "../assets/webpHomepage/HowToGetStarted.webp";
import howToShareIdeas from "../assets/webpHomepage/HowToShareIdeas.webp";
import howToGetInspired from "../assets/webpHomepage/HowToGetInspired.webp";
import howToGainExperiencePoints from "../assets/webpHomepage/HowToGainExperiencePoints.webp";

// Import fontAwesome icon components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faTrash,
  faRobot,
  faCircleInfo,
  faPaperPlane,
  faUserNinja,
  faLightbulb,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import { set } from "lodash";

SwiperCore.use([Autoplay, Pagination]);

export default function BlackboxHomePage({
  customTheme,
  setCustomTheme,
  menuActive,
}) {
  const [newQuestion, setNewQuestion] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [processingAiRequest, setProcessingAiRequest] = useState(false);
  const [aiResponse, setAiResponse] = useState(null);
  const [aiRequestError, setAiRequestError] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [moreInfoModalShow, setMoreInfoModalShow] = useState(false);
  const [moreInfoContent, setMoreInfoContent] = useState("");

  const handleChangeSwiperIndex = (i) => {
    setSwiperIndex(i);
  };

  const handleContextAndObjectivesMoreInfoClick = () => {
    setMoreInfoModalShow(true);
    setMoreInfoContent("Project Context & Objectives");
  };
  const handleLaunchPlanMoreInfoClick = () => {
    setMoreInfoModalShow(true);
    setMoreInfoContent("Current Launch Plan");
  };
  const handleHowToGetStartedMoreInfoClick = () => {
    setMoreInfoModalShow(true);
    setMoreInfoContent("How to Get Started?");
  };
  const handleHowToShareIdeasMoreInfoClick = () => {
    setMoreInfoModalShow(true);
    setMoreInfoContent("How to Share Ideas?");
  };
  const handleHowToGetInspiredMoreInfoClick = () => {
    setMoreInfoModalShow(true);
    setMoreInfoContent("How to Get Inspired?");
  };
  const handleHowToWinMoreInfoClick = () => {
    setMoreInfoModalShow(true);
    setMoreInfoContent("How to Win?");
  };

  const inspirationCards = [
    {
      imageUrl: contextAndObjectives,
      title: "Project Context & Objectives",
      text: "Why are we doing an online ideation & growth hack? What is the timeline?",
      action: handleContextAndObjectivesMoreInfoClick,
    },
    {
      imageUrl: launchPlanVisual,
      title: "Current Launch Plan",
      text: "What is the current launch plan for our Reborn Games brand?",
      action: handleLaunchPlanMoreInfoClick,
    },
    {
      imageUrl: howToGetStarted,
      title: "How to Get Started?",
      text: "What is expected from me as a participant in the online ideation? How can I participate?",
      action: handleHowToGetStartedMoreInfoClick,
    },
    {
      imageUrl: howToShareIdeas,
      title: "How to Share Ideas?",
      text: "How can I share ideas on the platform?",
      action: handleHowToShareIdeasMoreInfoClick,
    },
    {
      imageUrl: howToGetInspired,
      title: "How to Get Inspired?",
      text: "How can I get inspired to come-up with new, creative ideas for Reborn Games?",
      action: handleHowToGetInspiredMoreInfoClick,
    },
    {
      imageUrl: howToGainExperiencePoints,
      title: "How to Win?",
      text: "How can I gain experience points and rise in the rankings? What can we win?",
      action: handleHowToWinMoreInfoClick,
    },
  ];

  // Handle Functions
  const handleQuestionInputChange = (e) => {
    setNewQuestion(e.target.value);
  };

  const handleMoreInfoCloseClick = () => {
    setMoreInfoModalShow(false);
    setMoreInfoContent("");
    setAiResponse(null);
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    setMoreInfoContent("");
    setMoreInfoModalShow(true);
    setAiResponse(null);
    setCurrentQuestion(newQuestion);
    setProcessingAiRequest(true);
    setAiRequestError(null);
    console.log("question submitted:", newQuestion);
    try {
      const res = await axios.post(
        "https://cheezit-chat-i4licugc4a-ew.a.run.app/generate_response",
        {
          question: newQuestion,
        }
      );
      const data = res.data;
      setAiResponse(data.response);
      setAiRequestError(null);
      console.log("response:", data.response);
    } catch (error) {
      console.error("Error:", error);
      setAiRequestError(
        "Sorry, something went wrong :(. We're currently unable to answer your question, please try again"
      );
    }
    setNewQuestion("");
    setProcessingAiRequest(false);
  };

  useEffect(() => {
    if (aiResponse) {
      handleAskMeAnythingRequestLog();
    }
  }, [aiResponse]);

  const handleAskMeAnythingRequestLog = async () => {
    const userId = auth.currentUser.uid;
    const askMeAnythingRequestLog = {
      askMeAnythingQuestion: currentQuestion,
      askMeAnythingResponse: aiResponse,
      userId,
    };

    // Push to Firebase
    try {
      console.log("request to store chat conversation is sent");
      await db.collection("askMeAnythingRequests").add(askMeAnythingRequestLog);
    } catch (e) {
      console.log(
        "error message when trying to store the askMeAnythingRequest",
        e.message
      );
    }
  };

  const handleDeleteResponse = () => {
    setAiResponse(null);
    setAiRequestError(null);
    setCurrentQuestion("");
  };

  const handleInspirationCardClick = (e) => {
    const cardText = e.currentTarget.getElementsByTagName("p")[0].textContent;
    setNewQuestion(cardText);
  };

  return (
    <div
      className={`BlackboxHomePage videoPage ${
        customTheme ? "customTheme" : ""
      } ${menuActive ? "menuActive" : ""}`}
    >
      {customTheme && (
        <img src={CheezIt} className='FlyingCheezItInBackground CheezIt1' />
      )}
      {customTheme && (
        <img src={CheezIt} className='FlyingCheezItInBackground CheezIt2' />
      )}
      {customTheme && (
        <img src={CheezIt} className='FlyingCheezItInBackground CheezIt3' />
      )}
      <h1>Welcome to the Cheez-It Growth Hack Ideaton</h1>
      <div className='BlackboxHomePage-IntroTextContainer'>
        <p className='IntroTextOfHomePage'>
          Using th ideation platform is pretty easy. Use the{" "}
          <strong>menu bar on the left</strong> to navigate through the
          different pages.
          <br />
          Click on the <strong>
            question marks in the top right corner
          </strong>{" "}
          to get more information about what you can do on each page.
        </p>
        <p>
          In case of questions, don't hesitate to contact us at{" "}
          <a href='mailto:info@howtofly.ai'>info@howtofly.ai</a>
        </p>
        <p>
          Here's already a first fun thing to do, decide if you want to ideate
          in <strong>Dark-Stealth mode</strong> or <strong>Reborn mode</strong>?
        </p>
        <label>
          <Switch
            onChange={() => setCustomTheme(!customTheme)}
            checked={customTheme}
            offColor='#000' // Dark background when the switch is off
            onColor='rgb(205,32,38)' // Cheez-It background when the switch is on
            uncheckedIcon={
              <div className='Stealth'>
                <FontAwesomeIcon icon={faUserNinja} />
              </div>
            } // Text and style when switch is off
            checkedIcon={
              <div className='Cheez-It'>
                <img src={CiIcon} style={{ width: "30px" }} />
              </div>
            } // Text and style when switch is on
            height={40}
            width={84}
            handleDiameter={40}
            className='toggle-button'
          />
        </label>
      </div>
      <div className='BlackboxHomePage-inspiration videoPage'>
        <h1 className='BlackboxHomePage-inspiration-title'>
          <FontAwesomeIcon
            className='BlackboxHomePage-inspiration-title-icon'
            icon={faCircleInfo}
          />
          <stong>Need more info to get started?</stong> Click on one of the
          cards below to get more info.
        </h1>
        <div className='BlackboxHomePage-inspiration-cards'>
          {inspirationCards.map((inspirationCard, cardIndex) => (
            <div
              key={cardIndex}
              className='BlackboxHomePage-inspiration-cards-card'
              onClick={inspirationCard.action}
            >
              <img
                style={{ width: "95%", marginLeft: "2.5%", marginTop: "10px" }}
                src={inspirationCard.imageUrl}
              />
              <h5>{inspirationCard.title}</h5>
              <p>{inspirationCard.text}</p>
              <div className='learnMoreButtonRowVideoHomePage'>
                <Button
                  className='learnMoreButtonVideoHomePage'
                  onClick={inspirationCard.action}
                >
                  Learn More
                </Button>
              </div>
            </div>
          ))}
        </div>
        <div className='BlackboxHomePage-inspiration-cards mobile'>
          <Swiper
            style={{ width: "100%" }}
            pagination={{ type: "bullets" }}
            index={swiperIndex}
            onSlideChange={(swiper) => {
              handleChangeSwiperIndex(swiper.activeIndex);
            }}
            slidesPerView={1}
            autoplay={{ delay: 10000 }}
          >
            {inspirationCards.map((inspirationCard, cardIndex) => (
              <SwiperSlide key={cardIndex} className='swiper-slide'>
                <div
                  key={cardIndex}
                  className='BlackboxHomePage-inspiration-cards-card'
                  onClick={handleInspirationCardClick}
                >
                  <img
                    style={{
                      width: "95%",
                      marginLeft: "2.5%",
                      marginTop: "10px",
                    }}
                    src={inspirationCard.imageUrl}
                  />
                  <h5>{inspirationCard.title}</h5>
                  <p>{inspirationCard.text}</p>
                  <div className='learnMoreButtonRowVideoHomePage'>
                    <Button
                      className='learnMoreButtonVideoHomePage'
                      onClick={inspirationCard.action}
                    >
                      Learn More
                    </Button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className='BlackboxHomePage-moreQuestions'>
        <h1 className='BlackboxHomePage-inspiration-title'>
          <FontAwesomeIcon
            className='BlackboxHomePage-inspiration-title-icon'
            icon={faRobot}
          />
          <strong>Didn't find the information you were looking for?</strong> Ask
          a custom question to your AI-assistant and receive a customized answer
          back.
        </h1>
        <Form>
          <Form.Group>
            <TextareaAutosize
              minRows={2}
              name='question'
              placeholder='ask anything about the platform and the project context...'
              onChange={handleQuestionInputChange}
              value={newQuestion}
              className='BlackboxHomePage-form-questionInputField'
            />
          </Form.Group>
          <Button
            type='submit'
            onClick={handleQuestionSubmit}
            className='BlackboxHomePage-form-questionSubmitButton'
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              className='BlackboxHomePage-form-questionSubmitButtonIcon'
              style={{ fontSize: "0.7rem" }}
            />
          </Button>
        </Form>
      </div>
      {moreInfoModalShow && (
        <div className='PageInfo-card-modal'>
          <div
            className='PageInfo-card-modal-background'
            onClick={() => handleMoreInfoCloseClick()}
          ></div>
          <div className='PageInfo-card-modal-readMoreContentSidePannelContainer'>
            <div
              className='PageInfo-card-modal-readMoreContentSidePannelContainer-closeButtonContainer'
              onClick={() => handleMoreInfoCloseClick()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            {moreInfoContent === "Project Context & Objectives" && (
              <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content'>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-title'>
                  <div className='mainTitle'>Additional Info</div>
                  <div className='subTitle'>{moreInfoContent}</div>
                </div>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                  <p>
                    As you already know, our sights are set on augmenting the
                    board game experience.
                  </p>
                  <div>
                    <video controls width='100%' height='auto'>
                      <source
                        src='https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/MissionBrief_Cheez-ItGrowthHack_NewVersionShorterVersion_Updated.mp4?alt=media&token=ff796729-c297-47b1-8787-ad5d13d02d28'
                        type='video/mp4'
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <p>
                    To help us augment the board game experince , we're looking
                    to harness your unmatched expertise and groundbreaking
                    creativity throughout the upcoming two weeks (From September
                    27 until October 20 ).
                  </p>
                  <p>
                    We will be diving into four major focus areas.
                    <ol>
                      <li>
                        Making board games more accessible and easier to learn.
                      </li>
                      <li>
                        Making the gaming experience more Immersive,
                        Interactive, personalized and adaptive.
                      </li>
                      <li>
                        Making the gameplay more personalized and adaptive
                      </li>
                      <li>
                        Increasing th epotenial of social connectivity through
                        board games.
                      </li>
                    </ol>
                  </p>

                  <p>
                    Ideally we're looking for ideas that can achieve a double
                    win:
                    <ol>
                      <li>Get more people to play board games.</li>
                      <li>Get people to play board games more often.</li>
                    </ol>
                  </p>
                  <p>
                    After the two weeks of online ideating, the best ideas will
                    be shortlisted to fuel our 4-day growth hack which will take
                    place during the week of May 13 in Ghent. During this growth
                    hack, we will transform rough ideas and concepts into early
                    consumer validated prototypes.
                  </p>
                  <p>
                    Are you ready to write a new chapter in our board game
                    legacy. Let's get to work!
                  </p>
                </div>
              </div>
            )}
            {moreInfoContent === "Current Launch Plan" && (
              <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content'>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-title'>
                  <div className='mainTitle'>Additional Info</div>
                  <div className='subTitle'>{moreInfoContent}</div>
                </div>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                  The plan is to launch our first augmented board game
                  experience by the end of 2024.
                  <img
                    src={foodDescriptionCheezIt}
                    alt='Visual of Cheez-It'
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "20px",
                    }}
                  />
                  <p style={{ marginBottom: "0px" }}>
                    <strong>Launch Timeline</strong>
                  </p>
                  <p>
                    <ul>
                      <li>
                        End 2024: launch a first MVP of an augmented board game
                        experience for Risk
                      </li>
                      <li>
                        Early 2025: Launch several MVP's for Ticet-to-ride and 7
                        Wonders
                      </li>
                    </ul>
                  </p>
                  <img
                    src={launchPlanTimeline}
                    alt='Visual of Cheez-It Flavours'
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "20px",
                    }}
                  />
                  <p style={{ marginBottom: "0px" }}>
                    <strong>Board Games</strong>
                  </p>
                  <p>
                    <ul>
                      <li>Risk (End 2024)</li>
                      <li>Ticket-to-ride (mid 2025)</li>
                      <li>7 Wonders (mid 2025)</li>
                    </ul>
                    <img
                      src={contextAndObjectives}
                      alt='Visual of Cheez-It Flavours'
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    />
                  </p>
                </div>
              </div>
            )}
            {moreInfoContent === "How to Get Started?" && (
              <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content'>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-title'>
                  <div className='mainTitle'>Additional Info</div>
                  <div className='subTitle'>{moreInfoContent}</div>
                </div>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                  <p>
                    Throughout the upcoming weeks{" "}
                    <strong>(until May 1st)</strong>, we would love to leverage
                    your insights, expertise, and creativity to help us
                    <strong>identify & prioritize</strong> ideas to augment the
                    board game experience.
                  </p>
                  <p>
                    To do so, we have set-up an{" "}
                    <strong>ideation platform</strong> which you can access at
                    your <strong>own time and pace</strong>, and where you can
                    brainstorm in an <strong>anonymous, risk-free</strong>{" "}
                    setting.
                  </p>
                  <img
                    src={IdeationPlatform}
                    alt='Page Information Visual'
                    style={{
                      border: "solid rgb(24,24,29) 5px",
                      borderRadius: "10px",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  />
                  <p style={{ marginTop: "30px", marginBottom: "5px" }}>
                    <strong>Curious what the platform can do?</strong> Just use
                    the menu to navigate through the different pages and click
                    on the the
                    <strong>question mark</strong> in the top right corner of
                    each page. This will explain you in detail what you can do
                    on each page.
                  </p>
                  <img
                    src={QuestionMarkIcon}
                    alt='Page Information Visual'
                    style={{
                      border: "solid rgb(24,24,29) 5px",
                      borderRadius: "10px",
                      marginTop: "10px",
                      width: "60%",
                    }}
                  />
                  <p style={{ marginTop: "30px", marginBottom: "5px" }}>
                    Don't find the info you're looking for in the question marks
                    or on the home page? Use the{" "}
                    <strong>"Ask me anything"</strong> function to ask a
                    question. Your AI-assistant will provide you with a
                    customized answer. Be aware that this answer is
                    AI-generated, and could help you, but might not always be
                    100% correct. So be careful. In case you need a response
                    from a real person, just mail us at{" "}
                    <a href='mailto:info@howtofly.ai'>info@cigrowthhack.com</a>{" "}
                    and we'll get back to you as soon as possible.
                  </p>
                  <img
                    src={AskMeAnythingInputField}
                    alt='Page Information Visual'
                    style={{
                      border: "solid rgb(24,24,29) 5px",
                      borderRadius: "10px",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            )}
            {moreInfoContent === "How to Share Ideas?" && (
              <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content'>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-title'>
                  <div className='mainTitle'>Additional Info</div>
                  <div className='subTitle'>{moreInfoContent}</div>
                </div>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                  <p>
                    Sharing ideas is straightforward. First you need to click on
                    the "Share Ideas" menu item in the left menu bar.
                  </p>
                  <img
                    src={ShareIdeasMenuVisual}
                    alt='Share Idea Modal'
                    style={{
                      border: "solid rgb(24,24,29) 5px",
                      borderRadius: "10px",
                      marginTop: "10px",
                      width: "60%",
                    }}
                  />
                  <p style={{ marginTop: "30px", marginBottom: "5px" }}>
                    Then, click on the red <strong>"+ Share Idea"</strong>{" "}
                    button at the top of the page…
                  </p>
                  <img
                    src={ShareIdeaVisual}
                    alt='Share Idea Visual'
                    style={{
                      border: "solid rgb(24,24,29) 5px",
                      borderRadius: "10px",
                      marginTop: "10px",
                      width: "60%",
                    }}
                  />
                  <p style={{ marginTop: "30px", marginBottom: "5px" }}>
                    {" "}
                    …and start typing your idea.
                  </p>
                  <img
                    src={ShareIdeaModal}
                    alt='Share Idea Modal'
                    style={{ width: "100%" }}
                  />
                  <p style={{ marginTop: "30px", marginBottom: "5px" }}>
                    Need inspiration? Just end your sentence with … and click on
                    the auto-complete button. Now your personal AI-assistant
                    will try to finish your idea.
                  </p>
                  <img
                    src={AutocompleteFunction}
                    alt='AI-autocomplete function'
                  />
                  <p style={{ marginTop: "30px", marginBottom: "5px" }}>
                    Next to using the "+ Share Idea" button, you can also you
                    the <strong>"Build Idea"</strong> function. just click on
                    the deck icon in the{" "}
                    <strong>top corner of each page</strong> to access the Build
                    Deck.
                  </p>
                  <img
                    src={BuildDeckIcon}
                    alt='Build Deck Icon'
                    style={{
                      border: "solid rgb(24,24,29) 5px",
                      borderRadius: "10px",
                      marginTop: "10px",
                      width: "60%",
                    }}
                  />
                  <p style={{ marginTop: "30px", marginBottom: "5px" }}>
                    But before you can create an idea from the build deck, you
                    have to add ideas or pieces of inspiration. Also this
                    process is pretty straightforward. Just go to an idea or
                    inspiration card and click on the "+" button. Now the card
                    will be added to your deck.
                  </p>
                  <img
                    src={AddIdeaToDeckVisual}
                    alt='Add Idea To Deck Visual'
                  />
                  <p style={{ marginTop: "30px", marginBottom: "5px" }}>
                    Once you've added the cards you wanted to your deck (max 3),
                    you can click on the "Build Idea" button. From this point
                    onwards, everything is similar to sharing an idea from
                    scratch, except now, the autocomplete takes into account the
                    ideas / inspiration you added in your deck, when
                    auto-completing your idea. Awesome right?
                  </p>
                  <img
                    src={BuildIdeaDeck}
                    alt='Build Idea Deck Visual'
                    style={{
                      border: "solid rgb(24,24,29) 5px",
                      borderRadius: "10px",
                      marginTop: "10px",
                      width: "80%",
                    }}
                  />
                </div>
              </div>
            )}
            {moreInfoContent === "How to Get Inspired?" && (
              <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content'>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-title'>
                  <div className='mainTitle'>Additional Info</div>
                  <div className='subTitle'>{moreInfoContent}</div>
                </div>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                  <p>
                    On the ideation platform there are several ways to get
                    inspired.Just use the navigation menu on the left to go to
                    the relevant pages.
                  </p>
                  <img
                    src={NavigationMenuVisual}
                    alt='Build Idea Deck Visual'
                    style={{
                      border: "solid rgb(24,24,29) 5px",
                      borderRadius: "10px",
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "60%",
                    }}
                  />
                  <p>
                    <ul>
                      <li style={{ marginBottom: "5px" }}>
                        <strong>Pool of Ideas:</strong> Read through the ideas
                        and pro-/con-arguments of your colleagues
                      </li>
                      <li style={{ marginBottom: "5px" }}>
                        <strong>Personas & Consumer Journeys</strong> Browse
                        through the different board-game personas, read some of
                        there illustrative board game experience journeys, and
                        chat with them to learn more about there pain points,
                        needs and desires regarding board games.
                      </li>
                      <li style={{ marginBottom: "5px" }}>
                        <strong>Potential technologies to leverage</strong>
                        Within innovation, we don’t have to try to reinvent the
                        wheel. Just take a look at what products our U.S.
                        colleagues have already created throughout the years.
                        Perhaps you see something useful you can copy or adapt?
                      </li>
                      <li style={{ marginBottom: "5px" }}>
                        <strong>Our existing board games</strong>Within
                        innovation, we don’t have to try to reinvent the wheel.
                        Just take a look at what products our U.S. colleagues
                        have already created throughout the years. Perhaps you
                        see something useful you can copy or adapt?
                      </li>
                      <li style={{ marginBottom: "5px" }}>
                        <strong>
                          Augmented Board Game Experiences (competitors)
                        </strong>
                        To get a better understanding which value we can bring
                        to the cheese-flavoured salty snack category, we have to
                        get a good view on our competition. Check out all SKUs
                        in some of the largest EU markets, perhaps it might
                        inspire you to come with a better Cheez-It alternative.
                      </li>
                      <li style={{ marginBottom: "5px" }}>
                        <strong>Ask me anything:</strong> Use the "Ask me
                        anything" function on top of each page to ask your
                        AI-assistant a question. Your AI-assistant will provide
                        you with a customized answer. Be aware that this answer
                        is AI-generated, and could inspire you, but might not
                        always be 100% correct. So be careful. In case you need
                        a response from a real person, just mail us at{" "}
                        <a href='mailto:info@howtofly.ai'>info@howtofly.ai</a>{" "}
                        and we'll get back to you as soon as possible.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            )}
            {moreInfoContent === "How to Win?" && (
              <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content'>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-title'>
                  <div className='mainTitle'>Additional Info</div>
                  <div className='subTitle'>{moreInfoContent}</div>
                </div>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                  <p>
                    The online ideation is not only about collaborating with
                    your colleagues to identify new opportunties to augment and
                    imrove the board game exprience. It's also about competition
                    and showing who's the best brainstormer in town. So how can
                    you rise in the ranking and become eligible for some of our
                    top prizes? All you need to do is gain enough Experience
                    Points (XP). Here are a couple of ways to do so:
                    <br />
                    <br />
                    <strong>Sharing an Idea:</strong>
                    <ul>
                      <li>Base XP for sharing an idea: 10 XP</li>
                      <li>
                        Bonus XP based on upvotes received: +2 XP per upvote
                      </li>
                      <li>
                        Penalty XP based on downvotes received: -1 XP per
                        downvote
                      </li>
                      <li>
                        +50 XP if your idea gets shortlisted for growth hack
                      </li>
                    </ul>
                    <strong>Upvoting or Downvoting an Idea:</strong>
                    <ul>
                      <li>
                        30 XP if idea you upvoted (greenvoted) gets shortlisted
                        for growth hack
                      </li>
                    </ul>
                    <strong>
                      Sharing Arguments for Upvoting or Downvoting an Idea:
                    </strong>
                    <ul>
                      <li>Base XP for sharing a pro- or con-argument: 5 XP</li>
                    </ul>
                    <strong>Activity Streak:</strong>
                    <ul>
                      <li>5 XP per longest streak day</li>
                    </ul>
                  </p>
                </div>
              </div>
            )}
            {(processingAiRequest || aiResponse || aiRequestError) && (
              <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content'>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-title'>
                  <div className='mainTitle'>Additional Info</div>
                  <div className='subTitle'>{currentQuestion}</div>
                </div>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                  {processingAiRequest && (
                    <div>
                      <p>
                        Wait 10-30 seconds, I'm currently thinking and will
                        answer you soon!
                      </p>
                      <p>
                        Be aware, that the answer will be AI-generated and might
                        not be 100% accurate. Simply see it as a starting point
                        or inspiration.
                      </p>
                      <p>
                        If you prefer a human-generated answer, just mail to{" "}
                        <a href='mailto:info@howtofly.ai'>info@howtofly.ai</a>{" "}
                        and we'll get back to you as soon as possible.
                      </p>
                    </div>
                  )}
                  {aiResponse && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(aiResponse),
                      }}
                    ></div>
                  )}
                  {aiRequestError && !aiResponse && <div>{aiRequestError}</div>}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import React,{ useState, useEffect } from 'react';
import "./NavbarWebTop.css";
// import logo from "../assets/logo_MonthlyScend_Black.svg";
import logoBlack from "../assets/howToFly_logo_forWhiteBackground.png";
import logoAlt from "../assets/howToFly_logo_alt6.png";
import logoWhite from "../assets/howToFly_logo_forBlackBackground.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const NavbarWebTop = () => {
    const [active, setActive] = useState(false);
    const toggleNavBar = () => {
        setTimeout(() => {
            setActive(!active);
          }, 100);
      };
    const location = useLocation();
    
    useEffect(() => {
        if (location.hash) {
          const anchor = document.querySelector(location.hash);
          if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, [location]);

    return (
        <div className={active ? "NavbarWebTop" : "NavbarWebTop inactive"}>
            <div className="NavbarWebTop-logo">
                {!active?<img className="NavbarWebTop-logo-black" src={logoAlt} alt="HowToFly Logo Black" />:
                <img className="NavbarWebTop-logo-white" src={logoWhite} alt="HowToFly Logo White" />}
            </div>
            <div className="NavbarWebTop-menuToggle" onClick={toggleNavBar}>
                <FontAwesomeIcon className='toggleBars' icon={active ? faX:faBars} />
            </div>
            <div className={active ? "NavbarWebTop-leftLinks" : "NavbarWebTop-leftLinks inactive"}>
            </div>
            <div className={active ? "NavbarWebTop-rightLinks" : "NavbarWebTop-rightLinks inactive"}>
                <Link className="NavbarWebTop-rightLinks-link" to={{ pathname: '/', hash: '#approach' }} onClick={active?toggleNavBar:""}>our concept</Link>
                <Link className="NavbarWebTop-rightLinks-link" to={{ pathname: '/', hash: active ? "#experiments-mobile" : "#experiments"}} onClick={active?toggleNavBar:""}>our experiments</Link>
                <Link className="NavbarWebTop-rightLinks-link" to={{ pathname: '/', hash: '#HowWeHelp' }} onClick={active?toggleNavBar:""}>our value</Link>
                <Link className="black-callToActionNav" to={{ pathname: '/blackbox', hash: '' }}>Blackbox</Link>
            </div>
        </div>
    )
}

export default NavbarWebTop
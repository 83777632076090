// import assets
import stage1Placeholder from "../../assets/Stage1Placeholder.jpg";
import stage2Placeholder from "../../assets/Stage2Placeholder.png";
import stage3Placeholder from "../../assets/Stage3Placeholder.png";
import stage4Placeholder from "../../assets/Stage4Placeholder.png";
import stage5Placeholder from "../../assets/Stage5Placeholder.png";
import stage1EmotionShift from "../../assets/Stage1EmotionShift.png";
import stage2EmotionShift from "../../assets/Stage2EmotionShift.png";
import stage3EmotionShift from "../../assets/Stage3EmotionShift.png";
import stage4EmotionShift from "../../assets/Stage4EmotionShift.png";
import stage5EmotionShift from "../../assets/Stage5EmotionShift.png";

import persona1 from "../../assets/webpKB/persona1.webp";
import persona2 from "../../assets/webpKB/persona2.webp";
import persona3 from "../../assets/webpKB/persona3.webp";
import persona4 from "../../assets/webpKB/persona4.webp";
import persona5 from "../../assets/webpKB/persona5.webp";
import persona6 from "../../assets/webpKB/persona6.webp";
import persona7 from "../../assets/webpKB/persona7.webp";
import persona8 from "../../assets/webpKB/persona8.webp";
import persona9 from "../../assets/webpKB/persona9.webp";

const consumerPersonasIntro = {
  title: "Spirited Chip-Lover Personas & Journeys",
  description: [
    "Where Pringles primarily designs and targets Social Explores, the intention for Cheez-It is to focus on the Spirited Chip Lover.",
    "Spirited Chip Lovers are usually millennials who cherish their downtime, relying heavily on familiar snacks, particularly potato chips, to enrich their relaxation and TV-watching moments.",
    "They prioritize taste and familiarity in their snacks, as they want to ensure that their chosen snack really delivers. They seek to balance their hard work with consistent and pleasurable snacking experiences.",
    "Learn more about and chat with some of the Spirited Chip-Lovers below to get a better understanding of who they are and how Cheez-It can be relevant to them.",
  ],
};

const consumerPersonas = [
  {
    cardTitle: "Lukas (DE)",
    cardType: "Persona",
    cardSubTitle: "Spirited-Chip-Lover",
    imageUrl: persona1,
    country: "DE",
    timeFrame: "2023",
    name: "Lukas Müller",
    age: 36,
    gender: "Male",
    relationshipStatus: "Married, 3 kids",
    address: "Urban, Frankfurt, Germany",
    occupation: "IT Technician",
    income: "€40,000 annually",
    hobbies: ["Board games", "Watching Movies", "Watching TV Series"],
    snackingCharacteristics: [
      "Spirited-chip-lover",
      "Budget-Conscious Snacker",
      "On-the-go-Snacker",
      "Indulgent Snacker",
    ],
    favoriteFoodRetailers: ["Edeka (DE)", "Rewe (DE)"],
    mainMotivationsToBuySnacks: [
      "a snack for board-game-night with friends",
      "a mid-day snack to curb hunger",
      "a comfort food snack",
      "a snack during video games",
    ],
    commonPlacesToEatSnacksAwayFromHome: [
      "Workplace",
      "Train",
      "Board game cafes",
    ],
    favoriteSaltySnackCategory: "Potato Chips",
    favoriteSaltySnackBrands: ["Chio"],
    illustrativeJourney: {
      ConsumerBio: `Lukas Müller, a spirited-chip-lover from Frankfurt, Germany, is an IT Technician with a keen interest in board games, movies, and TV series. At 36, he's the proud father of three and enjoys a comfortable urban life. As a budget-conscious snacker, he seeks snacks that are not only tasty but also offer good value. Board-game nights with friends are especially important to Lukas, where his favorite potato chips, Chio, often make an appearance.,
      FoodRetailerDescription: Edeka is one of Germany's largest supermarket chains. Known for its wide variety of products, it caters to the diverse needs of urban residents like Lukas. The well-lit, spacious aisles offer an enjoyable shopping experience, with promotional stands introducing shoppers to new products.`,
      ConsumerJourney: [
        `On a chilly evening in Frankfurt, Lukas was gearing up for a board-game night with his close friends. Realizing that he was running low on snacks, he decided to make a pitstop at his favorite store, Edeka.`,
        `As he made his way through the store, Lukas stumbled upon a promotional stand for Cheez-It  . The packaging was different, and the brand ambassadors promoting the product were unfamiliar to him.`,
        `Intrigued, Lukas approached the stand, curious to learn more about this new product. He briefly scanned the nutritional information and ingredients. While he was open to trying something new, he noticed that the product had higher fat content than his usual choice.,
        The next factor that caught his eye was the price. For the quantity offered, Lukas felt that Cheez-It   was a bit on the pricier side, especially when he considered his budget and the number of snacks he needed for the evening.`,
        `Deciding to stick to his tried-and-true favorites, Lukas headed to the potato chip aisle and grabbed several packs of Chio. As he made his way to the checkout counter, he felt content, knowing that he had made a choice that his friends and wallet would appreciate.`,
      ],
      stages: [
        {
          Stage: "Stage 1: Awareness",
          StageImage: stage1Placeholder,
          Activities: [
            "Lukas: Notices the Cheez-It   promotional stand.",
            "Edeka: Showcases Cheez-It   with unfamiliar brand ambassadors.",
          ],
          EmotionShift: [
            {
              start: "Content/Unaware",
              emojiStart: "😌",
              emotionStartLevel: 1,
              end: "Curious",
              emojiEnd: "🤔",
              emotionEndLevel: 3,
              image: stage1EmotionShift,
            },
          ],
          PainPoints: [
            "Lukas: Unfamiliar brand ambassadors on promotional stand.",
            "Lukas: Unsure about trying a new product.",
          ],
          CheezItTouchpoints: ["Promotional stand", "Brand ambassadors"],
        },
        {
          Stage: "Stage 2: Research",
          StageImage: stage2Placeholder,
          Activities: [
            "Lukas: Scans nutritional information and ingredients on the Cheez-It   packaging.",
            "Edeka: Provides product information on the packaging.",
          ],
          EmotionShift: [
            {
              start: "Curious",
              emojiStart: "🤔",
              emotionStartLevel: 3,
              end: "Uncertain",
              emojiEnd: "🤨",
              emotionEndLevel: -1,
              image: stage2EmotionShift,
            },
          ],
          PainPoints: [
            "Lukas: Concerns about the higher fat content compared to his usual choice.",
          ],
          CheezItTouchpoints: ["Nutritional information", "Ingredients list"],
        },
        {
          Stage: "Stage 3: Decision",
          StageImage: stage3Placeholder,
          Activities: [
            "Lukas: Considers the price of Cheez-It  .",
            "Lukas: Decides to stick to his usual choice of potato chips.",
          ],
          EmotionShift: [
            {
              start: "Uncertain",
              emojiStart: "🤨",
              emotionStartLevel: -1,
              end: "Decisive",
              emojiEnd: "💡",
              emotionEndLevel: 3,
              image: stage3EmotionShift,
            },
          ],
          PainPoints: [
            "Lukas: Concerns about the price point of Cheez-It   for the quantity offered.",
          ],
          CheezItTouchpoints: ["Price label"],
        },
        {
          Stage: "Stage 4: Purchase",
          StageImage: stage4Placeholder,
          Activities: [
            "Lukas: Grabs several packs of his favorite Chio chips.",
            "Lukas: Makes his way to the checkout counter.",
          ],
          EmotionShift: [
            {
              start: "Decisive",
              emojiStart: "💡",
              emotionStartLevel: 3,
              end: "Content",
              emojiEnd: "😊",
              emotionEndLevel: 4,
              image: stage4EmotionShift,
            },
          ],
          PainPoints: [],
          CheezItTouchpoints: ["Shelf presence among other snacks"],
        },
        {
          Stage: "Stage 5: Post-Purchase",
          StageImage: stage5Placeholder,
          Activities: [
            "Lukas: Prepares for board-game night with friends.",
            "Lukas: Shares snacks and enjoys the evening.",
          ],
          EmotionShift: [
            {
              start: "Content",
              emojiStart: "😊",
              emotionStartLevel: 4,
              end: "Satisfied",
              emojiEnd: "👌",
              emotionEndLevel: 5,
              image: stage5EmotionShift,
            },
          ],
          PainPoints: [],
          CheezItTouchpoints: [],
        },
      ],
    },
  },
  {
    cardTitle: "Jules (FR)",
    cardType: "Persona",
    cardSubTitle: "Spirited-Chip-Lover",
    imageUrl: persona2,
    country: "FR",
    timeFrame: "2023",
    name: "Jules Dubois",
    age: 25,
    gender: "Male",
    relationshipStatus: "Single, no kids",
    address: "Rural, Provence FR",
    occupation: "Car Mechanic",
    income: "€40,000 annually",
    hobbies: ["Car racing", "Watching motorsport events"],
    snackingCharacteristics: [
      "Late-Night Snacker",
      "On-the-go-Snacker",
      "Budget-Conscious Snacker",
      "Spirited-chip-lover",
    ],
    favoriteFoodRetailers: ["Carrefour (FR)", "Auchan (FR)"],
    mainMotivationsToBuySnacks: [
      "a snack for a movie night alone",
      "a quick and convenient snack on-the-go",
      "a late-night craving snack",
      "a snack during motorsport events",
      "a treat for oneself",
    ],
    commonPlacesToEatSnacksAwayFromHome: [
      "Car",
      "Workplaces",
      "Malls & Shopping Centers",
      "Sports Stadiums & Arenas",
      "Bars & Pubs",
    ],
    favoriteSaltySnackCategory: "Potato Chips",
    favoriteSaltySnackBrands: ["Lay's", "Vico"],
    illustrativeJourney: {
      ConsumerBio: `Jules Dubois is a spirited 25-year-old car mechanic from rural Provence, France. He's passionate about car racing and often snacks while watching motorsport events. Single with no kids, he enjoys treating himself with potato chips during his late-night cravings or on-the-go during his hectic days. Known to be budget-conscious, he prefers well-known brands like Lay's and Vico.`,
      FoodRetailerDescription: `Carrefour, located in the heart of Provence, is Jules' go-to retailer. Renowned for its vast array of products, the store is Jules' first choice when he's in the mood for a treat.`,
      ConsumerJourney: [
        `Late afternoon, Jules walked into Carrefour to stock up on some snacks for an upcoming motorsport event. After wrapping up work at his garage, he was eager to get home and indulge.`,
        `Upon arriving at the snack aisle, Jules noticed a new promotional display for Cheez-It  . The packaging was vibrant and caught his attention.`,
        `He picked up a pack, considering the option of trying something new. However, he noticed that the price of the Cheez-It   was a bit higher than what he usually pays for his trusted brands for the same quantity.`,
        `Being budget-conscious, Jules decided to stick with what he knows and trusts: Lay's and Vico. He grabbed a few packs of those, certain of their value for money.`,
        `As he left the store, Jules felt satisfied, confident in his choice and eager for the upcoming race and snack time.`,
      ],
      stages: [
        {
          Stage: "Stage 1: Awareness",
          StageImage: stage1Placeholder,
          Activities: [
            "Jules: Observes the Cheez-It   promotional display.",
            "Carrefour: Showcases Cheez-It   prominently in the snack aisle.",
          ],
          EmotionShift: [
            {
              start: "Neutral",
              emojiStart: "😐",
              emotionStartLevel: 0,
              end: "Interested",
              emojiEnd: "🤔",
              emotionEndLevel: 2,
              image: stage1EmotionShift,
            },
          ],
          PainPoints: [
            "Jules: Intrigued by the new product but cautious due to his budget.",
          ],
          CheezItTouchpoints: [
            "In-store promotional display",
            "Vibrant packaging",
          ],
        },
        {
          Stage: "Stage 2: Consideration",
          StageImage: stage2Placeholder,
          Activities: [
            "Jules: Compares Cheez-It   price with his preferred brands.",
            "Jules: Evaluates the quantity and pricing.",
          ],
          EmotionShift: [
            {
              start: "Interested",
              emojiStart: "🤔",
              emotionStartLevel: 2,
              end: "Hesitant",
              emojiEnd: "😕",
              emotionEndLevel: -1,
              image: stage2EmotionShift,
            },
          ],
          PainPoints: [
            "Jules: Finds Cheez-It   to be slightly pricier for the same quantity compared to his usual brands.",
          ],
          CheezItTouchpoints: ["Product price", "Pack size"],
        },
        {
          Stage: "Stage 3: Decision",
          StageImage: stage3Placeholder,
          Activities: [
            "Jules: Decides to buy Lay's and Vico instead of Cheez-It  .",
            "Jules: Checks out and leaves the store.",
          ],
          EmotionShift: [
            {
              start: "Hesitant",
              emojiStart: "😕",
              emotionStartLevel: -1,
              end: "Content",
              emojiEnd: "😊",
              emotionEndLevel: 4,
              image: stage3EmotionShift,
            },
          ],
          PainPoints: [
            "Jules: Chooses tried-and-true brands to ensure value for money.",
          ],
          CheezItTouchpoints: ["Final decision at the shelf"],
        },
      ],
    },
  },
  {
    cardTitle: "Jermaine (UK)",
    cardType: "Persona",
    cardSubTitle: "Spirited-Chip-Lover",
    imageUrl: persona3,
    country: "UK",
    timeFrame: "2023",
    name: "Jermaine Collins",
    age: 26,
    gender: "Male",
    relationshipStatus: "In a relationship, no kids",
    address: "Urban, Birmingham UK",
    occupation: "Retail Worker",
    income: "£21,500 annually",
    hobbies: ["Music", "Video gaming", "Watching football"],
    snackingCharacteristics: [
      "Budget-Conscious Snacker",
      "Late-Night Snacker",
      "Spirited-chip-lover",
      "Indulgent Snacker",
    ],
    favoriteFoodRetailers: ["Tesco (UK)"],
    mainMotivationsToBuySnacks: [
      "a snack for a football match night",
      "a late-night gaming session snack",
      "a treat for oneself",
      "a comfort food snack",
      "a late-night craving snack",
    ],
    commonPlacesToEatSnacksAwayFromHome: [
      "Bus stops",
      "Parks",
      "Malls & Shopping Centers",
      "Friend's House",
      "Cafes & Coffee Shops",
    ],
    favoriteSaltySnackCategory: "Potato Chips",
    favoriteSaltySnackBrands: ["Doritos", "Pringles"],
    illustrativeJourney: {
      ConsumerBio: `Jermaine Collins is a 26-year-old retail worker living in urban Birmingham, UK. Earning £21,500 annually, he's a spirited-chip-lover and indulges in late-night snacking, especially during football matches or gaming sessions. He frequently snacks at bus stops, parks, and friends' homes. A fan of Doritos and Pringles, Jermaine often shops at Tesco for his snack needs.`,
      FoodRetailerDescription: `Tesco is a popular retail chain in the UK, known for its wide variety of products at reasonable prices. Jermaine prefers shopping there due to its affordability and diverse range of snack options.`,
      ConsumerJourney: [
        `It was a Saturday afternoon, and Jermaine was gearing up for an evening of video gaming followed by a football match on TV. Realizing he was low on snacks, he headed to his local Tesco.`,
        `Upon walking in, Jermaine's eyes caught the Cheez-It   shelf display. He had heard of the snack before but hadn't given it much thought, given his loyalty to his favorite brands like Doritos and Pringles.`,
        `Curiosity getting the better of him, Jermaine paused to read the Cheez-It   packaging. He wondered about the taste and the eating experience but felt hesitant due to the unfamiliarity of the brand in comparison to his regular choices.`,
        `Thinking about the gaming session and the football match later in the day, Jermaine wanted a guaranteed satisfying snack. He recalled a friend mentioning that Cheez-It   had a different taste that might not be to everyone's liking.`,
        `Deciding not to take a risk, he grabbed a pack of Doritos and Pringles, and added a cold drink to his cart. As he made his way to the checkout, he briefly glanced back at the Cheez-It   display, considering a future purchase when he felt more adventurous.`,
      ],
      stages: [
        {
          Stage: "Stage 1: Awareness",
          StageImage: stage1Placeholder,
          Activities: [
            "Jermaine: Spots the Cheez-It   display upon entering Tesco.",
            "Tesco: Prominently displays Cheez-It   for visibility.",
          ],
          EmotionShift: [
            {
              start: "Unfamiliar",
              emojiStart: "🤨",
              emotionStartLevel: 0,
              end: "Curious",
              emojiEnd: "🧐",
              emotionEndLevel: 2,
              image: stage1EmotionShift,
            },
          ],
          PainPoints: [
            "Jermaine: Unfamiliar with Cheez-It  , leading to hesitation.",
          ],
          CheezItTouchpoints: ["In-store display"],
        },
        {
          Stage: "Stage 2: Consideration",
          StageImage: stage2Placeholder,
          Activities: [
            "Jermaine: Pauses to read the Cheez-It   packaging.",
            "Jermaine: Recalls a friend's feedback on the taste of Cheez-It  .",
          ],
          EmotionShift: [
            {
              start: "Curious",
              emojiStart: "🧐",
              emotionStartLevel: 2,
              end: "Hesitant",
              emojiEnd: "😕",
              emotionEndLevel: -1,
              image: stage2EmotionShift,
            },
          ],
          PainPoints: [
            "Jermaine: Concerned about the taste and eating experience of Cheez-It  .",
          ],
          CheezItTouchpoints: ["Product Packaging"],
        },
        {
          Stage: "Stage 3: Decision",
          StageImage: stage3Placeholder,
          Activities: [
            "Jermaine: Chooses familiar brands over Cheez-It   for the upcoming gaming and football night.",
          ],
          EmotionShift: [
            {
              start: "Hesitant",
              emojiStart: "😕",
              emotionStartLevel: -1,
              end: "Decisive",
              emojiEnd: "💡",
              emotionEndLevel: 3,
              image: stage3EmotionShift,
            },
          ],
          PainPoints: [
            "Jermaine: Prefers not to risk an unsatisfactory snack for his evening plans.",
          ],
          CheezItTouchpoints: ["Comparison with familiar snack brands"],
        },
        {
          Stage: "Stage 4: Purchase",
          StageImage: stage4Placeholder,
          Activities: ["Jermaine: Buys Doritos and Pringles and checks out."],
          EmotionShift: [
            {
              start: "Decisive",
              emojiStart: "💡",
              emotionStartLevel: 3,
              end: "Satisfied",
              emojiEnd: "😊",
              emotionEndLevel: 4,
              image: stage4EmotionShift,
            },
          ],
          PainPoints: [
            "Jermaine: Wonders if he missed out on trying something new with Cheez-It  .",
          ],
          CheezItTouchpoints: ["Price Point", "Placement on the shelf"],
        },
        {
          Stage: "Stage 5: Post-Purchase",
          StageImage: stage5Placeholder,
          Activities: [
            "Jermaine: Enjoys his snacks during gaming and watching the football match.",
            "Jermaine: Reflects on the choice he made.",
          ],
          EmotionShift: [
            {
              start: "Content",
              emojiStart: "🙂",
              emotionStartLevel: 3,
              end: "Pondering",
              emojiEnd: "🤔",
              emotionEndLevel: 2,
              image: stage5EmotionShift,
            },
          ],
          PainPoints: [
            "Jermaine: Considers giving Cheez-It   a try during a less significant occasion.",
          ],
          CheezItTouchpoints: ["Feedback from friends"],
        },
      ],
    },
  },
  {
    cardTitle: "Lena (DE)",
    cardType: "Persona",
    cardSubTitle: "Spirited-Chip-Lover",
    imageUrl: persona4,
    country: "DE",
    timeFrame: "2023",
    name: "Lena Müller",
    age: 26,
    gender: "Female",
    relationshipStatus: "In a relationship, no kids",
    address: "Urban, Frankfurt DE",
    occupation: "Nurse",
    income: "€40,000 annually",
    hobbies: ["Listening to music", "Playing Guitar"],
    snackingCharacteristics: [
      "Late-Night Snacker",
      "Social Snacker",
      "Spirited-chip-lover",
    ],
    favoriteFoodRetailers: ["Aldi Süd (DE)", "Lidl (DE)"],
    mainMotivationsToBuySnacks: [
      "a snack for a movie night alone",
      "a late-night craving snack",
      "a comfort food snack",
    ],
    commonPlacesToEatSnacksAwayFromHome: [
      "Train",
      "Hospital",
      "Cafes & Coffee Shops",
      "Malls & Shopping Centers",
    ],
    favoriteSaltySnackCategory: "Potato Chips",
    favoriteSaltySnackBrands: ["Funny-Frisch", "Lorenz Snack-World"],
    illustrativeJourney: {
      ConsumerBio: `Lena Müller, a 26-year-old nurse living in urban Frankfurt, is spirited about her music and guitar. Her life is often a hustle between work and enjoying late-night movies. With an annual income of €40,000, she often seeks comfort food to satisfy her late-night cravings, mostly favoring the crispy charm of potato chips from brands like Funny-Frisch and Lorenz Snack-World. Whether it's during her commute on the train, in cafes, or even at the hospital, snacks keep her company.`,
      FoodRetailerDescription: `Aldi Süd (DE) is one of Germany's top discount supermarket chains, known for its no-frills shopping experience, offering quality products at competitive prices.`,
      ConsumerJourney: [
        `Lena, after finishing an afternoon shift, found herself with a slight hunger pang. Knowing she had another night shift later, she decided to drop by Aldi Süd (DE) to grab a quick snack.`,
        `While looking through the snack aisle, her eyes landed on the bright packaging of Cheez-It  . She remembered hearing about the product, and curiosity piqued, she approached to take a closer look.`,
        `As Lena skimmed the package, memories of similar cheese snacks that left her fingers greasy and smelly popped up. Given her upcoming shift at the hospital, she wasn't keen on leaving traces of her snack everywhere or any lingering odors.`,
        `She imagined the hassle of cleaning her hands thoroughly, especially in the current situation where hygiene was paramount. On weighing her options, she decided to skip the Cheez-It   for now.`,
        `Walking further down the aisle, she picked up her all-time favorites, Funny-Frisch and Lorenz Snack-World chips, comforted by the familiarity and the assurance of no messy fingers. Ready for her shift, Lena left Aldi Süd (DE) with her preferred snacks in tow.`,
      ],
      stages: [
        {
          Stage: "Stage 1: Awareness",
          StageImage: stage1Placeholder,
          Activities: [
            "Lena: Walks into Aldi Süd (DE) with an intent to buy a snack.",
            "Cheez-It  : Attractive packaging catches Lena's attention.",
          ],
          EmotionShift: [
            {
              start: "Unaware",
              emojiStart: "😑",
              emotionStartLevel: 0,
              end: "Curious",
              emojiEnd: "🤔",
              emotionEndLevel: 2,
              image: stage1EmotionShift,
            },
          ],
          PainPoints: [
            "Lena: Unsure about trying a new product without knowing if it would suit her preferences.",
          ],
          CheezItTouchpoints: ["In-store display", "Packaging"],
        },
        {
          Stage: "Stage 2: Consideration",
          StageImage: stage2Placeholder,
          Activities: [
            "Lena: Reads product details on Cheez-It   packaging.",
            "Lena: Remembers previous experiences with similar snacks.",
          ],
          EmotionShift: [
            {
              start: "Curious",
              emojiStart: "🤔",
              emotionStartLevel: 2,
              end: "Doubtful",
              emojiEnd: "🤨",
              emotionEndLevel: -2,
              image: stage2EmotionShift,
            },
          ],
          PainPoints: [
            "Lena: Concerned about the possibility of greasy fingers and odor when eating on-the-go.",
          ],
          CheezItTouchpoints: [
            "Packaging",
            "Snack texture and smell (assumed)",
          ],
        },
        {
          Stage: "Stage 3: Decision",
          StageImage: stage3Placeholder,
          Activities: [
            "Lena: Decides not to buy Cheez-It   due to hygiene concerns.",
            "Lena: Chooses to buy her usual favorite chips brands.",
          ],
          EmotionShift: [
            {
              start: "Doubtful",
              emojiStart: "🤨",
              emotionStartLevel: -2,
              end: "Decided",
              emojiEnd: "💡",
              emotionEndLevel: 3,
              image: stage3EmotionShift,
            },
          ],
          PainPoints: [
            "Lena: Worries about maintaining cleanliness and hygiene during her night shift at the hospital.",
          ],
          CheezItTouchpoints: [
            "Product texture (assumed based on experience with similar products).",
          ],
        },
      ],
    },
  },
  {
    cardTitle: "Lucy (UK)",
    cardType: "Persona",
    cardSubTitle: "Spirited-Chip-Lover",
    imageUrl: persona5,
    country: "UK",
    timeFrame: "2023",
    name: "Lucy Hamilton",
    age: 34,
    gender: "Female",
    relationshipStatus: "Single, no kids",
    address: "Urban, Bristol UK",
    occupation: "Freelance Graphic Designer",
    income: "€45,000 annually",
    hobbies: ["Binge-watching TV shows"],
    snackingCharacteristics: [
      "Spirited-chip-lover",
      "Late-Night Snacker",
      "Budget-Conscious Snacker",
    ],
    favoriteFoodRetailers: ["Asda (UK)", "Tesco Express (UK)"],
    mainMotivationsToBuySnacks: [
      "a snack for a movie night alone",
      "a late-night craving snack",
      "a comfort food snack",
      "a treat for oneself",
    ],
    commonPlacesToEatSnacksAwayFromHome: [
      "Train",
      "Car",
      "Workplaces",
      "Movie Theaters",
      "Cafes & Coffee Shops",
    ],
    favoriteSaltySnackCategory: "Potato Chips",
    favoriteSaltySnackBrands: ["Walkers Regular", "Walkers Quavers"],
    illustrativeJourney: {
      ConsumerBio: `Lucy Hamilton is a spirited chip-lover from Bristol, UK. Aged 34, Lucy is a single Freelance Graphic Designer who enjoys binge-watching TV shows. Earning €45,000 annually, she often indulges in late-night snacking while being budget-conscious. Her go-to salty snack brands are Walkers Regular and Walkers Quavers. Aside from her home, Lucy often snacks in her car, at workplaces, and in cafes.`,
      FoodRetailerDescription: `Asda, UK's third-largest supermarket chain, offers a wide range of groceries, clothing, and home essentials. With spacious aisles, they are known for their affordability, extensive product range, and regular promotional offers.`,
      ConsumerJourney: [
        `On a crisp Wednesday evening, Lucy was winding down her graphic design work and pondered over her plans for the night. It dawned on her that she had run out of her favorite Walkers Regular chips for her TV binge-watch session.`,
        `Deciding to stock up, she headed to her local Asda. The store was buzzing with evening shoppers. As Lucy sauntered through the snack aisle, she noticed the Cheez-It   display with its unfamiliar packaging.`,
        `Curiosity piqued, Lucy approached the stand. Upon closer inspection, she realized that the brand ambassadors and influencers promoting the snack didn't resonate with her. They seemed quite alien to her UK sensibilities.`,
        `Nevertheless, she thought of giving them a try and checked for reviews online. A quick scan on her phone revealed mixed reviews, with some highlighting concerns about the taste and the potential of having greasy fingers after consuming.`,
        `Remembering her late-night snacking routine and not wanting to risk it, Lucy reverted to her tried-and-tested Walkers Regular and Walkers Quavers.`,
        `With her snacks in the basket, she quickly passed the checkout and headed home. Settling on her couch, Lucy was reassured by the familiar crunch of her beloved chips, wondering if she'd ever dare to try the Cheez-It  .`,
      ],
      stages: [
        {
          Stage: "Stage 1: Awareness",
          StageImage: stage1Placeholder,
          Activities: [
            "Lucy: Realizes she's out of her favorite snack.",
            "Asda: Showcases Cheez-It   in the snack aisle.",
          ],
          EmotionShift: [
            {
              start: "Unaware",
              emojiStart: "😑",
              emotionStartLevel: 0,
              end: "Curious",
              emojiEnd: "🤨",
              emotionEndLevel: 2,
              image: stage1EmotionShift,
            },
          ],
          PainPoints: ["Lucy: Unfamiliarity with the brand and product."],
          CheezItTouchpoints: ["In-store display", "Influencers on packaging"],
        },
        {
          Stage: "Stage 2: Research",
          StageImage: stage2Placeholder,
          Activities: [
            "Lucy: Checks online reviews of Cheez-It  .",
            "Asda: Provides nutritional and product details on the shelf label.",
          ],
          EmotionShift: [
            {
              start: "Curious",
              emojiStart: "🤨",
              emotionStartLevel: 2,
              end: "Hesitant",
              emojiEnd: "😕",
              emotionEndLevel: -1,
              image: stage2EmotionShift,
            },
          ],
          PainPoints: [
            "Lucy: Finds mixed reviews and concerns about the product.",
            "Lucy: Influencers on packaging don’t resonate with her.",
          ],
          CheezItTouchpoints: ["Online Reviews", "Product Packaging"],
        },
        {
          Stage: "Stage 3: Decision",
          StageImage: stage3Placeholder,
          Activities: ["Lucy: Chooses familiar brands over Cheez-It  ."],
          EmotionShift: [
            {
              start: "Hesitant",
              emojiStart: "😕",
              emotionStartLevel: -1,
              end: "Decided",
              emojiEnd: "💡",
              emotionEndLevel: 3,
              image: stage3EmotionShift,
            },
          ],
          PainPoints: [
            "Lucy: Concerns about greasy fingers after consuming.",
            "Lucy: Reviews have raised doubts about taste.",
          ],
          CheezItTouchpoints: ["Snack Aisle", "Price Point"],
        },
        {
          Stage: "Stage 4: Purchase",
          StageImage: stage4Placeholder,
          Activities: [
            "Lucy: Buys her favorite Walkers brands.",
            "Asda: Smooth checkout process.",
          ],
          EmotionShift: [
            {
              start: "Decided",
              emojiStart: "💡",
              emotionStartLevel: 3,
              end: "Content",
              emojiEnd: "😌",
              emotionEndLevel: 4,
              image: stage4EmotionShift,
            },
          ],
          PainPoints: ["Lucy: Unsure about trying unfamiliar brands."],
          CheezItTouchpoints: ["Shelf placement"],
        },
        {
          Stage: "Stage 5: Post-Purchase",
          StageImage: stage5Placeholder,
          Activities: [
            "Lucy: Enjoys her binge-watching session with her familiar snacks.",
            "Lucy: Reflects on her decision not to try Cheez-It  .",
          ],
          EmotionShift: [
            {
              start: "Content",
              emojiStart: "😌",
              emotionStartLevel: 4,
              end: "Reflective",
              emojiEnd: "🤔",
              emotionEndLevel: 2,
              image: stage5EmotionShift,
            },
          ],
          PainPoints: [
            "Lucy: Wonders if Cheez-It   would've added variety to her snacking.",
          ],
          CheezItTouchpoints: ["Future Consideration"],
        },
      ],
    },
  },
  {
    cardTitle: "Liam Turner (UK)",
    cardType: "Persona",
    cardSubTitle: "Spirited-Chip-Lover",
    imageUrl: persona6,
    name: "Liam Turner",
    age: 34,
    gender: "Male",
    relationshipStatus: "Married, 2 kids",
    address: "Urban, Liverpool, UK",
    occupation: "Sales Manager",
    income: "€68,000 annually",
    hobbies: ["Watching TV series", "attending local football matches"],
    snackingCharacteristics: [
      "Routine-chip-lover",
      "Late-Night Snacker",
      "On-the-go-Snacker",
      "Budget-Conscious Snacker",
      "Family Snacker",
    ],
    favoriteFoodRetailers: ["Lidl (UK)", "Morrisons (UK)"],
    mainMotivationsToBuySnacks: [
      "a snack for a movie night at home",
      "a mid-day snack to curb hunger",
      "a snack during video games",
      "a comfort food snack",
      "a late-night craving snack",
    ],
    commonPlacesToEatSnacksAwayFromHome: [
      "Workplaces",
      "Train",
      "Car",
      "Movie Theaters",
      "Sports Stadiums & Arenas",
    ],
    favoriteSaltySnackCategory: "Potato Chips",
    favoriteSaltySnackBrands: ["Walkers Salt & Vinegar"],
    illustrativeJourney: {
      ConsumerBio: `Liam Turner is a spirited 34-year-old chip lover from urban Liverpool. As a sales manager, he earns €68,000 annually. Married with 2 kids, Liam is a late-night snacker and a routine chip lover. He enjoys watching TV series, attending local football matches, and often snacks during video games. His favorites include Walkers Salt & Vinegar chips. Being budget-conscious, he frequently shops at Lidl and Morrisons.`,
      FoodRetailerDescription: `Lidl is known for offering a wide range of products at affordable prices. Their vast assortment of snacks caters to different tastes, making it a popular choice among families.`,
      ConsumerJourney: [
        `One Saturday afternoon, after attending a local football match, Liam decided to head to Lidl for some snacks. His plan was to binge-watch his favorite series later in the evening.`,
        `Upon entering the snack aisle, Liam spotted the Cheez-It   display. Having tried it once before, he recalled how much he enjoyed the cheesy flavor.`,
        `However, as he was about to grab a pack, he remembered his wife's aversion to the strong cheese smell. He hesitated, picturing her reaction if he opened a pack at home.`,
        `Liam considered buying them anyway, thinking he could eat them in secret. But the thought of hiding from his wife to enjoy a snack didn't seem right.`,
        `He eventually decided against the Cheez-It  , opting for his trusted Walkers Salt & Vinegar chips. With a sigh, he imagined a time when he could freely enjoy the cheesy snacks without any worries.`,
      ],
      stages: [
        {
          Stage: "Stage 1: Awareness",
          StageImage: stage1Placeholder,
          Activities: [
            "Liam: Spots the Cheez-It   display.",
            "Lidl: Strategically places the Cheez-It   for visibility.",
          ],
          EmotionShift: [
            {
              start: "Casual",
              emojiStart: "😀",
              emotionStartLevel: 2,
              end: "Interested",
              emojiEnd: "🤩",
              emotionEndLevel: 4,
              image: stage1EmotionShift,
            },
          ],
          PainPoints: ["Liam: Remembers wife's dislike for cheese smell."],
          CheezItTouchpoints: ["In-store display"],
        },
        {
          Stage: "Stage 2: Consideration",
          StageImage: stage2Placeholder,
          Activities: [
            "Liam: Recalls his previous positive experience with Cheez-It  .",
            "Liam: Weighs the pros and cons of buying the snack.",
          ],
          EmotionShift: [
            {
              start: "Excited",
              emojiStart: "😃",
              emotionStartLevel: 4,
              end: "Hesitant",
              emojiEnd: "😬",
              emotionEndLevel: 0,
              image: stage2EmotionShift,
            },
          ],
          PainPoints: ["Liam: Thinks of wife's potential negative reaction."],
          CheezItTouchpoints: ["Product packaging"],
        },
        {
          Stage: "Stage 3: Decision",
          StageImage: stage3Placeholder,
          Activities: [
            "Liam: Decides against buying Cheez-It  .",
            "Liam: Chooses his favorite Walkers Salt & Vinegar chips instead.",
          ],
          EmotionShift: [
            {
              start: "Hesitant",
              emojiStart: "😬",
              emotionStartLevel: 0,
              end: "Resigned",
              emojiEnd: "🙁",
              emotionEndLevel: -2,
              image: stage3EmotionShift,
            },
          ],
          PainPoints: [
            "Liam: Prioritizes family harmony over personal snacking preference.",
          ],
          CheezItTouchpoints: ["Shelf position of Cheez-It   vs. other brands"],
        },
        {
          Stage: "Stage 4: Purchase",
          StageImage: stage4Placeholder,
          Activities: [
            "Liam: Checks out with only the Walkers Salt & Vinegar chips.",
          ],
          EmotionShift: [
            {
              start: "Resigned",
              emojiStart: "🙁",
              emotionStartLevel: -2,
              end: "Contented",
              emojiEnd: "😌",
              emotionEndLevel: 3,
              image: stage4EmotionShift,
            },
          ],
          PainPoints: [
            "Liam: Wishes for a neutral-smelling cheesy snack option.",
          ],
          CheezItTouchpoints: ["Price Point", "Loyalty or promotion offers"],
        },
        {
          Stage: "Stage 5: Post-Purchase",
          StageImage: stage5Placeholder,
          Activities: [
            "Liam: Enjoys his evening with the purchased snack.",
            "Liam: Thinks of other ways to enjoy Cheez-It   without disturbing his wife.",
          ],
          EmotionShift: [
            {
              start: "Contented",
              emojiStart: "😌",
              emotionStartLevel: 3,
              end: "Hopeful",
              emojiEnd: "🤞",
              emotionEndLevel: 4,
              image: stage5EmotionShift,
            },
          ],
          PainPoints: ["Liam: Longs for the Cheez-It   experience again."],
          CheezItTouchpoints: [
            "Feedback from other family members",
            "TV-Advertisement",
            "Online Reviews",
          ],
        },
      ],
    },
  },
  {
    cardTitle: "Piotr Nowak (PL)",
    cardType: "Persona",
    cardSubTitle: "Spirited-Chip-Lover",
    imageUrl: persona7,
    name: "Piotr Nowak",
    age: 31,
    gender: "Male",
    relationshipStatus: "Married, 1 kid",
    address: "Urban, Warsaw, Poland",
    occupation: "Teacher",
    income: "€18,000 annually",
    hobbies: ["Photography", "Reading", "Biking"],
    snackingCharacteristics: [
      "Spirited-chip-lover",
      "Budget-Conscious Snacker",
      "On-the-go-Snacker",
    ],
    favoriteFoodRetailers: ["Biedronka", "Lidl (PL)"],
    mainMotivationsToBuySnacks: [
      "a snack for movie night with family",
      "a quick and convenient snack on-the-go",
      "a snack during biking trips",
      "a treat for oneself",
    ],
    commonPlacesToEatSnacksAwayFromHome: [
      "Parks",
      "Cycling trails",
      "Coffee shops",
      "Bus",
      "Friend's house",
    ],
    favoriteSaltySnackCategory: "Potato Chips",
    favoriteSaltySnackBrands: ["Lay's", "Star Snacks"],
    illustrativeJourney: {
      ConsumerBio: `Piotr Nowak is a 31-year-old spirited chip lover living in urban Warsaw, Poland. He's a teacher with a yearly income of €18,000. Piotr is married with one child and enjoys photography, reading, and biking in his free time. When it comes to snacks, Piotr is budget-conscious and often looks for on-the-go options. His favorite retailers for snacks are Biedronka and Lidl. Whether it's movie night with the family, a quick bite during his biking trips, or just a treat for himself, Piotr loves his potato chips and prefers brands like Lay's and Star Snacks.`,
      FoodRetailerDescription: `Biedronka is one of Poland's most popular and affordable supermarkets, offering a range of products including snacks. The store layout is easy to navigate, and they often promote local brands.`,
      ConsumerJourney: [
        `It was a warm Saturday afternoon and Piotr was preparing for a biking trip with his friends. Remembering he needed snacks, he decided to make a quick stop at his nearby Biedronka.`,
        `Upon entering the store, Piotr's eyes quickly scanned the snack aisle. He noticed a new product - Cheez-It  . The packaging looked a bit like those of crackers, which Piotr isn't particularly fond of.`,
        `While the promotion of the product was eye-catching, the wheat base of Cheez-It   made Piotr hesitant. He's accustomed to potato chips and occasionally corn-based snacks.`,
        `He briefly thought about giving them a try, but the uncertainty of the taste and texture deterred him. Instead, he grabbed a couple of Lay's packs, his trusty biking snack, and a few drinks.`,
        `After checking out, Piotr made his way to the cycling trails, feeling confident about his snack choice, yet curious about what Cheez-It   might have tasted like.`,
      ],
      stages: [
        {
          Stage: "Stage 1: Awareness",
          StageImage: stage1Placeholder,
          Activities: [
            "Piotr: Notices Cheez-It   on the snack aisle.",
            "Biedronka: Promotes Cheez-It   with noticeable shelf space.",
          ],
          EmotionShift: [
            {
              start: "Focused",
              emojiStart: "🚴",
              emotionStartLevel: 3,
              end: "Curious",
              emojiEnd: "🧐",
              emotionEndLevel: 2,
              image: stage1EmotionShift,
            },
          ],
          PainPoints: [
            "Piotr: The Cheez-It   packaging reminds him of crackers.",
          ],
          CheezItTouchpoints: [
            "In-store shelf promotion",
            "Product description on the packaging",
          ],
        },
        {
          Stage: "Stage 2: Consideration",
          StageImage: stage2Placeholder,
          Activities: [
            "Piotr: Weighs the pros and cons of trying a new snack.",
            "Piotr: Considers the wheat base and potential taste of Cheez-It  .",
          ],
          EmotionShift: [
            {
              start: "Curious",
              emojiStart: "🧐",
              emotionStartLevel: 2,
              end: "Hesitant",
              emojiEnd: "😕",
              emotionEndLevel: -1,
              image: stage2EmotionShift,
            },
          ],
          PainPoints: [
            "Piotr: Uncertain about the taste of a wheat-based crisp.",
          ],
          CheezItTouchpoints: [
            "Product Packaging",
            "Comparison with other familiar snacks",
          ],
        },
        {
          Stage: "Stage 3: Decision",
          StageImage: stage3Placeholder,
          Activities: [
            "Piotr: Chooses Lay's potato chips over Cheez-It  .",
            "Piotr: Adds his favorite drinks to the basket for his biking trip.",
          ],
          EmotionShift: [
            {
              start: "Hesitant",
              emojiStart: "😕",
              emotionStartLevel: -1,
              end: "Confident",
              emojiEnd: "😁",
              emotionEndLevel: 4,
              image: stage3EmotionShift,
            },
          ],
          PainPoints: [
            "Piotr: The unfamiliarity of Cheez-It   leads him to go with a safer option.",
          ],
          CheezItTouchpoints: ["In-store shelf placement", "Product Packaging"],
        },
        {
          Stage: "Stage 4: Post-Purchase",
          StageImage: stage4Placeholder,
          Activities: [
            "Piotr: Reflects on his purchase while biking.",
            "Piotr: Shares his snack choice with friends during the trip.",
          ],
          EmotionShift: [
            {
              start: "Confident",
              emojiStart: "😁",
              emotionStartLevel: 4,
              end: "Satisfied",
              emojiEnd: "🙂",
              emotionEndLevel: 3,
              image: stage4EmotionShift,
            },
          ],
          PainPoints: [
            "Piotr: Still curious about what Cheez-It   might taste like.",
          ],
          CheezItTouchpoints: [
            "Thoughts on the product he didn't purchase",
            "Comparing his experience with friends' snack choices",
          ],
        },
      ],
    },
  },
  {
    cardTitle: "Priya (UK)",
    cardType: "Persona",
    cardSubTitle: "Spirited-Chip-Lover",
    imageUrl: persona8,
    country: "UK",
    timeFrame: "2023",
    name: "Priya Patel",
    age: 28,
    gender: "Female",
    relationshipStatus: "Single",
    address: "Urban, Birmingham UK",
    occupation: "Marketing Specialist",
    income: "€52,000 annually",
    hobbies: ["Binge-watching series", "Yoga"],
    snackingCharacteristics: [
      "Routine-chip-lover",
      "On-the-go-Snacker",
      "Late-Night Snacker",
      "Nostalgic Snacker",
      "Budget-Conscious Snacker",
    ],
    favoriteFoodRetailers: ["Tesco (UK)", "Sainsbury’s (UK)"],
    mainMotivationsToBuySnacks: [
      "a snack for a movie night alone",
      "a mid-day snack to curb hunger",
      "a comfort food snack",
      "a nostalgia-driven snack (something from childhood or a different country)",
      "a treat for oneself",
    ],
    commonPlacesToEatSnacksAwayFromHome: [
      "Workplaces",
      "Train",
      "self-driving Car",
      "Cafes & Coffee Shops",
      "Malls & Shopping Centers",
    ],
    favoriteSaltySnackCategory: "Lentil Chips",
    favoriteSaltySnackBrands: ["Hippeas"],
    illustrativeJourney: {
      ConsumerBio: `Priya Patel, a spirited 28-year-old Marketing Specialist living in urban Birmingham, UK. With a penchant for binge-watching series and practicing yoga, Priya finds solace in snacking. Being a nostalgic and budget-conscious snacker, she often finds herself drawn to snacks reminiscent of her childhood or ones that are easy on the pocket.`,
      FoodRetailerDescription: `Tesco, one of the UK's largest and most popular retailers, offering a wide range of groceries and general merchandise. Known for its vast selection of products and competitive prices, Tesco attracts a diverse clientele.`,
      ConsumerJourney: [
        `After a challenging day at work, Priya was in dire need of relaxation. She had plans to binge-watch her favorite series and decided to drop by her local Tesco to grab some snacks for the night.`,
        `Navigating through the snack aisle, her eyes landed on the Cheez-It   shelf. She recalled seeing some online influencers promoting the snack but felt detached as they didn't quite resonate with her tastes.`,
        `As she approached the display, her nostalgic side reminded her of similar snacks from her childhood. Intrigued, Priya picked up the package, inspecting it for nutritional details and price. She was slightly discouraged by the lack of certain nutritional details and felt the price point was a tad bit high.`,
        `Torn between trying something new and sticking with her trusted Hippeas Lentil Chips, Priya was inclined towards the latter. The missing nutritional information on Cheez-It   made her slightly apprehensive, given her preference for a balanced diet.`,
        `She made her way to the checkout counter with her usual snacks, hoping for a relaxed evening ahead. As she waited in line, she noticed a discount on Cheez-It   for loyalty cardholders but felt the promotion lacked genuine value for her.`,
        `Back at home, as she delved into her series, Priya felt content with her choices. While she was curious about Cheez-It  , she hoped to try them some other time, perhaps when she had more information or when they were on a better offer.`,
      ],
      stages: [
        {
          Stage: "Stage 1: Awareness",
          StageImage: stage1Placeholder,
          Activities: [
            "Priya: Discovers Cheez-It   at Tesco.",
            "Tesco: Features Cheez-It   prominently in the snack aisle.",
          ],
          EmotionShift: [
            {
              start: "Curious",
              emojiStart: "🧐",
              emotionStartLevel: 2,
              end: "Slightly Skeptical",
              emojiEnd: "😒",
              emotionEndLevel: 1,
              image: stage1EmotionShift,
            },
          ],
          PainPoints: [
            "Priya: Feels influencers chosen for promotions don't align with her tastes.",
          ],
          CheezItTouchpoints: ["In-store display", "Influencer advertisements"],
        },
        {
          Stage: "Stage 2: Consideration",
          StageImage: stage2Placeholder,
          Activities: [
            "Priya: Compares Cheez-It   with her usual snacks, thinking about the price, nutritional info, and personal preferences.",
            "Tesco: Offers a discount for Cheez-It   to loyalty cardholders.",
          ],
          EmotionShift: [
            {
              start: "Slightly Skeptical",
              emojiStart: "😒",
              emotionStartLevel: 1,
              end: "Indecisive",
              emojiEnd: "🤔",
              emotionEndLevel: 2,
            },
          ],
          PainPoints: [
            "Priya: Hesitates due to lack of certain nutritional details and perceived high price.",
          ],
          CheezItTouchpoints: ["Loyalty card discount offer"],
        },

        {
          Stage: "Stage 3: Decision",
          StageImage: stage3Placeholder,
          Activities: [
            "Priya: Chooses her trusted Hippeas Lentil Chips over Cheez-It  .",
            "Tesco: Speedy checkout process, leading to a smooth purchasing experience.",
          ],
          EmotionShift: [
            {
              start: "Indecisive",
              emojiStart: "🤔",
              emotionStartLevel: 2,
              end: "Content",
              emojiEnd: "😊",
              emotionEndLevel: 4,
            },
          ],
          PainPoints: [
            "Priya: Would've appreciated more detailed information on Cheez-It   packaging.",
          ],
          CheezItTouchpoints: ["Checkout line display"],
        },

        {
          Stage: "Stage 4: Post-Purchase",
          StageImage: stage4Placeholder,
          Activities: [
            "Priya: Enjoys her series at home with her trusted snack.",
            "Tesco: Sends her a feedback survey via email the next day.",
          ],
          EmotionShift: [
            {
              start: "Content",
              emojiStart: "😊",
              emotionStartLevel: 4,
              end: "Satisfied",
              emojiEnd: "😁",
              emotionEndLevel: 5,
            },
          ],
          PainPoints: [
            "Priya: Curious about the missed opportunity to try a new snack but is happy with her choice.",
          ],
          CheezItTouchpoints: ["Feedback survey"],
        },

        {
          Stage: "Stage 5: Reflection",
          StageImage: stage5Placeholder,
          Activities: [
            "Priya: Considers giving Cheez-It   a try in the future when on a better offer or when more product info is available.",
            "Tesco: Continues to promote the product in the store and online.",
          ],
          EmotionShift: [
            {
              start: "Satisfied",
              emojiStart: "😁",
              emotionStartLevel: 5,
              end: "Open-minded",
              emojiEnd: "🙂",
              emotionEndLevel: 3,
            },
          ],
          PainPoints: [
            "Priya: Hopes for more detailed product information in the future.",
          ],
          CheezItTouchpoints: ["Online advertisements", "In-store promotions"],
        },
      ],
    },
  },
  {
    cardTitle: "Isabella García (SP)",
    cardType: "Persona",
    cardSubTitle: "Spirited-Chip-Lover",
    imageUrl: persona9,
    name: "Isabella García",
    age: 34,
    gender: "Female",
    relationshipStatus: "Married, 2 kids",
    address: "Suburban, Valencia, Spain",
    occupation: "Part-time Retail Associate",
    income: "€28,000 annually",
    hobbies: ["Gardening", "Dancing", "DIY crafts with kids"],
    snackingCharacteristics: [
      "Spirited-chip-lover",
      "Family Snacker",
      "Budget-Conscious Snacker",
      "Nostalgic Snacker",
    ],
    favoriteFoodRetailers: ["Mercadona", "Lidl (ES)"],
    mainMotivationsToBuySnacks: [
      "a snack for family movie night",
      "a quick and convenient snack for kids",
      "a snack during playtime with kids",
      "a treat for herself after a long day",
      "a nostalgia-driven snack (reminiscing flavors from her own childhood)",
    ],
    commonPlacesToEatSnacksAwayFromHome: [
      "Parks",
      "Beach",
      "School events",
      "Children's play areas",
      "Family gatherings",
    ],
    favoriteSaltySnackCategory: "Potato Chips",
    favoriteSaltySnackBrands: ["Lay's", "Ruffles"],
    illustrativeJourney: {
      ConsumerBio: `Isabella García is a spirited-chip-lover from suburban Valencia, Spain. At 34, she balances her role as a part-time retail associate with the joys and challenges of motherhood. With an annual income of €28,000, Isabella is always on the lookout for budget-friendly snacking options for her family, especially for movie nights, school events, and family outings to parks or beaches. Nostalgic flavors from her childhood, like Lay's and Ruffles potato chips, hold a special place in her heart.`,
      FoodRetailerDescription: `Mercadona is one of Spain's most popular supermarket chains, known for its wide variety of quality products at competitive prices. It is Isabella's go-to place for her grocery shopping, especially when looking for snacks for her family.`,
      ConsumerJourney: [
        `On a sunny Saturday, Isabella decided to stop by her favorite Mercadona after dropping her kids at a playdate. She was on a mission: finding the perfect snack for their family movie night.`,
        `As she browsed the snack aisle, Isabella's eyes landed on the Cheez-It section. The bright packaging seemed inviting, but she hesitated. While she was familiar with the brand, she recalled it being a wheat-based crisp that resembled a cracker.`,
        `Isabella's thoughts wandered to her children's preferences. Would they appreciate a switch from their usual potato chips to something more cracker-like?`,
        `Looking at the price tag, she felt the   was a bit steep, especially for a snack they hadn't tried before. "What if they don't like it?", she pondered. `,
        `After some consideration, she decided to stick to her family's tried and true favorites: Lay's and Ruffles potato chips. She felt confident that these would be a hit during their movie night.`,
      ],
      stages: [
        {
          Stage: "Stage 1: Awareness",
          StageImage: stage1Placeholder,
          Activities: [
            "Isabella: Visits her favorite Mercadona.",
            "Mercadona: Showcases Cheez-It   on the snack aisle.",
          ],
          EmotionShift: [
            {
              start: "Eager",
              emojiStart: "🤩",
              emotionStartLevel: 3,
              end: "Curious",
              emojiEnd: "🤔",
              emotionEndLevel: 2,
              image: stage1EmotionShift,
            },
          ],
          PainPoints: [
            "Isabella: Uncertain about the wheat-based crisp texture.",
          ],
          CheezItTouchpoints: [
            "Packaging on the shelf",
            "Aisle placement in Mercadona",
          ],
        },
        {
          Stage: "Stage 2: Consideration",
          StageImage: stage2Placeholder,
          Activities: [
            "Isabella: Reflects on her children's snacking preferences.",
            "Isabella: Compares the price of   to other snacks.",
          ],
          EmotionShift: [
            {
              start: "Curious",
              emojiStart: "🤔",
              emotionStartLevel: 2,
              end: "Hesitant",
              emojiEnd: "😕",
              emotionEndLevel: -1,
              image: stage2EmotionShift,
            },
          ],
          PainPoints: [
            "Isabella: Concerns over children’s acceptance of the product.",
            "Isabella: Believes the price point is too high for a trial.",
          ],
          CheezItTouchpoints: ["Product description", "Price tag in Mercadona"],
        },
        {
          Stage: "Stage 3: Decision",
          StageImage: stage3Placeholder,
          Activities: [
            "Isabella: Chooses to buy her family's regular favorites, Lay's and Ruffles.",
          ],
          EmotionShift: [
            {
              start: "Hesitant",
              emojiStart: "😕",
              emotionStartLevel: -1,
              end: "Decided",
              emojiEnd: "💡",
              emotionEndLevel: 3,
              image: stage3EmotionShift,
            },
          ],
          PainPoints: [
            "Isabella: Prefers to avoid the risk of purchasing an unfamiliar snack at a higher price point.",
          ],
          CheezItTouchpoints: [
            "Comparison with familiar brands",
            "Final shelf placement before checkout",
          ],
        },
      ],
    },
  },
];

export { consumerPersonasIntro, consumerPersonas };

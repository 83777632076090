import React, { useState, useEffect } from "react";

// import KnowledgeBase data (JSON files)
import {
  consumerPersonas,
  consumerPersonasIntro,
} from "./knowledgeBaseData/consumerPersonas.js";
import {
  countryProfilesIntro,
  countryProfiles,
} from "./knowledgeBaseData/countryProfiles.js";
import {
  retailerProfiles,
  retailerProfilesIntro,
} from "./knowledgeBaseData/retailerProfiles.js";
import {
  cheezItSKUs,
  cheezItSKUsIntro,
} from "./knowledgeBaseData/cheezItSKUs.js";
import {
  competitorSKUs,
  competitorSKUsIntro,
} from "./knowledgeBaseData/competitorSKUs.js";

// import custom components
import MasonryLayout from "./MasonryLayout.js";
import BlackboxMekkoGraph from "./BlackboxMekkoGraph.js";
import BlackboxCIKnowledgeBaseCountryItem from "./BlackboxCIKnowledgeBaseCountryItem.js";
import BlackboxCIKnowledgeBasePersonaItem from "./BlackboxCIKnowledgeBasePersonaItem.js";
import BlackboxCIKnowledgeBaseRetailerItem from "./BlackboxCIKnowledgeBaseRetailerItem.js";
import BlackboxCIKnowledgeBaseCheezItSKUs from "./BlackboxCIKnowledgeBaseCheezItSKUs.js";

// import bootstrap components
import { Button, DropdownButton, Dropdown } from "react-bootstrap";

// import font-awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

// import css stylesheets
import "./BlackboxCIKnowledgeBase.css";
import { set } from "lodash";

// Main Component ////////////////////////////////////////////////////////////////////////

export default function BlackboxCIKnowledgeBase({
  visibleContent,
  handleAddToBuildDeck,
}) {
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState([]);
  const [filters, setFilters] = useState({});
  const [sortedBySales, setSortedBySales] = useState(false);
  const [filteredSKUs, setFilteredSKUs] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");
  const [readMoreActiveSidePanelIndex, setReadMoreActiveSidePanelIndex] =
    useState(null);
  const [chosenKnowledgeBaseItem, setChosenKnowledgeBaseItem] = useState({});

  // UseEffect to set the relevant data and Filter and sort knowledge base items ////////////////

  useEffect(() => {
    let result = [];
    if (visibleContent === "BlackboxCountryProfiles") {
      result = [...countryProfiles];
      setPageTitle(countryProfilesIntro.title);
      setPageDescription(countryProfilesIntro.description);
    }
    if (visibleContent === "BlackboxConsumerPersonasAndJourneys") {
      result = [...consumerPersonas];
      setPageTitle(consumerPersonasIntro.title);
      setPageDescription(consumerPersonasIntro.description);
    }
    if (visibleContent === "BlackboxCheezItSKUs") {
      result = [...cheezItSKUs];
      setPageTitle(cheezItSKUsIntro.title);
      setPageDescription(cheezItSKUsIntro.description);
    }
    if (visibleContent === "BlackboxRetailerProfiles") {
      result = [...retailerProfiles];
      setPageTitle(retailerProfilesIntro.title);
      setPageDescription(retailerProfilesIntro.description);
    }

    // Filter
    for (const key in filters) {
      if (filters[key]) {
        result = result.filter((sku) => sku[key] === filters[key]);
      }
    }

    // Sort
    if (sortedBySales) {
      result.sort((a, b) => b.sales - a.sales);
    }

    setFilteredSKUs(result);
  }, [filters, sortedBySales, visibleContent]);

  const handleFilter = (key, value) => {
    setFilters({ [key]: value === filters[key] ? "" : value });
    setActiveFilter(key);
  };

  const key = "someKey";

  const buttonTitle =
    filters[key] ||
    (key === "manufacturerMekkoLegend" && "Manufacturer") ||
    (key === "flavourMekkoLegend" && "Flavour") ||
    (key === "shapeMekkoLegend" && "Shape") ||
    (key === "mainUSPMekkoLegend" && "Main USP") ||
    (key === "baseIngredientMekkoLegend" && "Base Ingredient") ||
    (key === "packFormat" && "Pack Format") ||
    (key === "mainTargetAudienceMekkoLegend" && "Main Target Audience");

  // add or remove filters here

  const uniqueKeys = Object.keys(competitorSKUs[0]).filter(
    (key) =>
      (key === "manufacturerMekkoLegend" ||
        key === "flavourMekkoLegend" ||
        key === "shapeMekkoLegend" ||
        key === "mainUSPMekkoLegend" ||
        key === "baseIngredientMekkoLegend" ||
        key === "packFormat") &&
      typeof competitorSKUs[0][key] === "string"
  );

  //////////////////// ///////////////// Main Component Render //////////////////// /////////////////

  return (
    <div className='BlackboxCIKnowledgeBase'>
      <div className='BlackboxCIKnowledgeBase-titleRow'>
        <h1 className='BlackboxCIKnowledgeBase-titleRow-title'>{pageTitle}</h1>
        <p>
          {pageDescription.map((paragraph, index) => (
            <span key={index}>
              <span>{paragraph}</span>{" "}
            </span>
          ))}
        </p>
      </div>
      {false && (
        <div className='BlackboxCIKnowledgeBase-rowForFilterAndSortButtons'>
          {true && (
            <div className='BlackboxCIKnowledgeBase-rowForFilterAndSortButtons-filterButtons'>
              {uniqueKeys.map((key) => {
                const buttonTitle =
                  filters[key] ||
                  (key === "manufacturerMekkoLegend" && "Manufacturer") ||
                  (key === "flavourMekkoLegend" && "Flavour") ||
                  (key === "shapeMekkoLegend" && "Shape") ||
                  (key === "mainUSPMekkoLegend" && "Main USP") ||
                  (key === "baseIngredientMekkoLegend" && "Base Ingredient") ||
                  (key === "packFormat" && "Pack Format") ||
                  (key === "mainTargetAudienceMekkoLegend" &&
                    "Main Target Audience");
                return (
                  <DropdownButton
                    title={buttonTitle}
                    key={key}
                    className={
                      activeFilter === key
                        ? "dropdownFilters btn-block active-filter"
                        : "dropdownFilters btn-block"
                    }
                  >
                    {[...new Set(competitorSKUs.map((sku) => sku[key]))].map(
                      (value) => (
                        <Dropdown.Item
                          onClick={() => handleFilter(key, value)}
                          key={value}
                          active={filters[key] === value}
                          className='dropdown-item'
                          data-width='100%'
                        >
                          {value}
                        </Dropdown.Item>
                      )
                    )}
                  </DropdownButton>
                );
              })}
            </div>
          )}
          <div className='BlackboxCIKnowledgeBase-rowForFilterAndSortButtons-sortButtons'>
            <Button
              variant={sortedBySales ? "primary" : "outline-primary"}
              onClick={() => setSortedBySales((prev) => !prev)}
              className={
                sortedBySales
                  ? "sortBySalesButton sortActive"
                  : "sortBySalesButton"
              }
            >
              <div>Sort by Sales</div>
              {sortedBySales && <FontAwesomeIcon icon={faCaretDown} />}
              {!sortedBySales && <FontAwesomeIcon icon={faCaretRight} />}
            </Button>
          </div>
        </div>
      )}
      <MasonryLayout className='BlackboxCIKnowledgeBase-rowForCollectionOfCards'>
        {filteredSKUs.map((knowledgeBaseItem, knowledgeBaseItemIndex) => (
          <div
            key={knowledgeBaseItemIndex}
            className='BlackboxCIKnowledgeBase-rowForCollectionOfCards-card'
          >
            <div className='BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-imageContainer'>
              <img
                src={knowledgeBaseItem.imageUrl}
                className={
                  visibleContent === "BlackboxCheezItSKUs" ? "cheezItImage" : ""
                }
              />
            </div>
            <div className='BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer'>
              <div className='BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-titleContainer'>
                <h5>
                  <strong>{knowledgeBaseItem.cardTitle}</strong>
                  <br />
                  {knowledgeBaseItem.cardSubTitle}
                </h5>
              </div>
              <div className='BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer'>
                <Button
                  onClick={() => {
                    setReadMoreActiveSidePanelIndex(
                      readMoreActiveSidePanelIndex === knowledgeBaseItemIndex
                        ? null
                        : knowledgeBaseItemIndex
                    );
                    setChosenKnowledgeBaseItem(knowledgeBaseItem);
                  }}
                  className='BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button'
                >
                  Read More
                </Button>
                <Button
                  onClick={() => handleAddToBuildDeck(knowledgeBaseItem)}
                  className='BlackboxCIKnowledgeBase-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button addToDeckButton'
                >
                  +
                </Button>
              </div>
            </div>
          </div>
        ))}
      </MasonryLayout>
      {readMoreActiveSidePanelIndex !== null && (
        <div className='BlackboxCompetitorCollection-rowForCollectionOfCards-card-modal'>
          <div
            className='BlackboxCompetitorCollection-rowForCollectionOfCards-card-modal-background'
            onClick={() => setReadMoreActiveSidePanelIndex(null)}
          ></div>
          <div className='BlackboxCompetitorCollection-rowForCollectionOfCards-card-modal-readMoreContentSidePannelContainer'>
            <div
              className='BlackboxCompetitorCollection-rowForCollectionOfCards-card-modal-readMoreContentSidePannelContainer-closeButtonContainer'
              onClick={() => setReadMoreActiveSidePanelIndex(null)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            {visibleContent === "BlackboxCountryProfiles" && (
              <BlackboxCIKnowledgeBaseCountryItem
                chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
              />
            )}
            {visibleContent === "BlackboxConsumerPersonasAndJourneys" && (
              <BlackboxCIKnowledgeBasePersonaItem
                chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
              />
            )}
            {visibleContent === "BlackboxRetailerProfiles" && (
              <BlackboxCIKnowledgeBaseRetailerItem
                chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
              />
            )}
            {visibleContent === "BlackboxCheezItSKUs" && (
              <BlackboxCIKnowledgeBaseCheezItSKUs
                chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import { useState } from "react";
import { auth } from "../firebase/config";
import { sendPasswordResetEmail } from "firebase/auth";

export function useResetPassword() {
  const [notification, setNotification] = useState(null);
  const [error, setError] = useState(null);

  const resetPassword = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setNotification("Password reset email sent successfully.");
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return { resetPassword, notification, error };
}

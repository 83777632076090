import React, { useState } from "react";

// import bootstrap components
import { Button } from "react-bootstrap";

// import custom components
import CheezitPersonaChatbot from "./CheezitPersonaChatbot";

// import css files
import "./BlackboxCIKnowledgeBasePersonaItem.css";

export default function BlackboxCIKnowledgeBasePersonaItem({
  chosenKnowledgeBaseItem,
}) {
  const [personaCardVisible, setPersonaCardVisible] = useState(true);
  const [chatPageVisible, setChatPageVisible] = useState(false);
  const [journeyPageVisible, setJourneyPageVisible] = useState(false);

  const journeyTopics = [
    "Stage",
    "Activities",
    "EmotionShift",
    "PainPoints",
    "CheezItTouchpoints",
  ];

  const formatTopicTitle = (key) => {
    if (key === "hobbies") {
      return "Hobbies";
    }
    if (key === "snackingCharacteristics") {
      return "Snacking Characteristics";
    }
    if (key === "favoriteFoodRetailers") {
      return "Favorite Food Retailers";
    }
    if (key === "mainMotivationsToBuySnacks") {
      return "Main Motivations To Buy Snacks";
    }
    if (key === "commonPlacesToEatSnacksAwayFromHome") {
      return "Common Places To Eat Snacks Away From Home";
    }
    if (key === "favoriteSaltySnackBrands") {
      return "Favorite Salty Snack Brands";
    }
  };
  const handlePersonaCardButtonClick = () => {
    setPersonaCardVisible(true);
    setChatPageVisible(false);
    setJourneyPageVisible(false);
  };
  const handleChatButtonClick = () => {
    setPersonaCardVisible(false);
    setChatPageVisible(true);
    setJourneyPageVisible(false);
  };
  const handleJourneyButtonClick = () => {
    setPersonaCardVisible(false);
    setChatPageVisible(false);
    setJourneyPageVisible(true);
  };
  return (
    <div className='BlackboxCIKnowledgeBaseItem'>
      <h5 className='BlackboxCIKnowledgeBaseItem-title'>
        {chosenKnowledgeBaseItem.cardTitle}
      </h5>
      <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow'>
        <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn'>
          <img
            src={chosenKnowledgeBaseItem.imageUrl}
            alt={`image of ${chosenKnowledgeBaseItem.cardTitle}`}
            className='BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn-image'
          />
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn'>
          <Button
            className={
              personaCardVisible
                ? "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button activeButton"
                : "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button"
            }
            onClick={handlePersonaCardButtonClick}
          >
            Persona Card
          </Button>
          <Button
            className={
              chatPageVisible
                ? "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button activeButton"
                : "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button"
            }
            onClick={handleChatButtonClick}
          >
            Chat
          </Button>
          <Button
            className={
              journeyPageVisible
                ? "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button activeButton"
                : "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button"
            }
            onClick={handleJourneyButtonClick}
          >
            Journey
          </Button>
        </div>
      </div>
      {personaCardVisible && (
        <div className='persona-card'>
          <div className='infoContainer'>
            <h5>General Info</h5>
            <div className='info'>
              <strong>Name:</strong> {chosenKnowledgeBaseItem.name}
            </div>
            <div className='info'>
              <strong>Age:</strong> {chosenKnowledgeBaseItem.age}
            </div>
            <div className='info'>
              <strong>Relationship Status:</strong>{" "}
              {chosenKnowledgeBaseItem.relationshipStatus}
            </div>
            <div className='info'>
              <strong>Address:</strong> {chosenKnowledgeBaseItem.address}
            </div>
            <div className='info'>
              <strong>Occupation:</strong> {chosenKnowledgeBaseItem.occupation}
            </div>
            <div className='info'>
              <strong>Income:</strong> {chosenKnowledgeBaseItem.income}
            </div>
            <div className='info'>
              <strong>Favourite Salty Snack Category:</strong>{" "}
              {chosenKnowledgeBaseItem.favoriteSaltySnackCategory}
            </div>
          </div>
          {[
            "hobbies",
            "favoriteFoodRetailers",
            "mainMotivationsToBuySnacks",
            "commonPlacesToEatSnacksAwayFromHome",
            "favoriteSaltySnackBrands",
          ].map((key) => (
            <div className='infoContainer'>
              <section key={key}>
                <h5>{formatTopicTitle(key)}</h5>
                <ul>
                  {chosenKnowledgeBaseItem[key].map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </section>
            </div>
          ))}
        </div>
      )}
      {chatPageVisible && (
        <div className='chatbotContainer'>
          <CheezitPersonaChatbot
            chosenKnowledeBaseItem={chosenKnowledgeBaseItem}
          />
        </div>
      )}
      {journeyPageVisible && (
        <div className='journeyContainer'>
          <h5>Illustrative Journey</h5>
          <div className='journey'>
            <div className='journeyDescription'>
              {chosenKnowledgeBaseItem.illustrativeJourney.ConsumerJourney.map(
                (paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                )
              )}
            </div>
            <h5 className='stagesTitle'>Journey Stages</h5>
            <div className='journeyStagesGridTable'>
              <div className='journey-grid'>
                <div className='journey-header'>
                  {chosenKnowledgeBaseItem.illustrativeJourney.stages.map(
                    (stage, index) => (
                      <div className='journey-column-header'>
                        <ColorBeforeColon text={stage.Stage} key={index} />
                      </div>
                    )
                  )}
                </div>

                {journeyTopics.map((topic, topicIndex) => (
                  <div key={topicIndex} className='journey-row'>
                    <div
                      className={`journey-row-header-topic ${topic}`}
                      key={topicIndex}
                    >
                      {topic}
                    </div>
                    {chosenKnowledgeBaseItem.illustrativeJourney.stages.map(
                      (stage, stageIndex) => (
                        <div key={stageIndex} className='journey-cell'>
                          {topic === "Stage" ? (
                            <img
                              src={stage.StageImage}
                              alt={stage.Stage}
                              className='journeyStageImage'
                            />
                          ) : (
                            stage[topic].map((item, itemIndex) =>
                              topic === "EmotionShift" &&
                              typeof item === "object" ? (
                                <div>
                                  <strong>Start:</strong>
                                  <br />
                                  {item.emojiStart} {item.start}
                                  <br />
                                  <strong>End:</strong>
                                  <br />
                                  {item.emojiEnd} {item.end}
                                </div>
                              ) : (
                                <BoldBeforeColon
                                  text={
                                    typeof item === "object"
                                      ? `${item.start} -> ${item.end}`
                                      : item
                                  }
                                  key={itemIndex}
                                />
                              )
                            )
                          )}
                        </div>
                      )
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// special component to make text bold before colon

class BoldBeforeColon extends React.Component {
  formatText(text) {
    const parts = text.split(":");
    if (parts.length > 1) {
      return (
        <div className='itemInJourneyCell'>
          <strong>{parts[0]}:</strong>
          <br />
          {parts.slice(1).join(":")}
        </div>
      );
    }
    return text;
  }

  render() {
    return (
      <div className='contentOfJourneyCells'>
        {this.formatText(this.props.text)}
      </div>
    );
  }
}

class ColorBeforeColon extends React.Component {
  formatText(text) {
    const parts = text.split(":");
    if (parts.length > 1) {
      return (
        <div>
          <strong style={{ color: "rgb(247,168,0)" }}>{parts[0]}</strong>
          <br />
          {parts.slice(1).join(":")}
        </div>
      );
    }
    return text;
  }

  render() {
    return <div>{this.formatText(this.props.text)}</div>;
  }
}

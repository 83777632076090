import React, { useState, useEffect, useRef } from "react";
import { auth, db } from "../firebase/config";
import axios from "axios";
import "./CheezitPersonaChatbot.css";
import Spinner from "react-bootstrap/Spinner";

function CheezitPersonaChatbot({ chosenKnowledeBaseItem }) {
  const [selectedPersona, setSelectedPersona] = useState(
    chosenKnowledeBaseItem
  );
  const [personaProfileForChatbot, setPersonaProfileForChatbot] = useState({});
  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [chatBotError, setChatBotError] = useState(null);

  const chatBoxRef = useRef(null);

  useEffect(() => {
    chatBoxRef.current?.scrollIntoView({ behavior: "smooth" });
    setSelectedPersona(chosenKnowledeBaseItem);
    const personProfileForChatbot = {
      name: chosenKnowledeBaseItem.name,
      age: chosenKnowledeBaseItem.age,
      hobbies: chosenKnowledeBaseItem.hobbies,
      snackingCharacteristics: chosenKnowledeBaseItem.snackingCharacteristics,
      mainMotivationsToBuySnacks:
        chosenKnowledeBaseItem.mainMotivationsToBuySnacks,
      commonPlacesToEatSnacksAwayFromHome:
        chosenKnowledeBaseItem.commonPlacesToEatSnacksAwayFromHome,
      favoriteSaltySnackBrands: chosenKnowledeBaseItem.favoriteSaltySnackBrands,
      ConsumerBio: chosenKnowledeBaseItem.illustrativeJourney.ConsumerBio,
    };
    setPersonaProfileForChatbot(personProfileForChatbot);
  }, [chatHistory, chosenKnowledeBaseItem]);

  const handleSendMessage = async () => {
    setIsTyping(true);
    setChatBotError(null);
    if (message.trim() === "") return;
    const newMessage = {
      role: "You",
      text: message,
    };
    setChatHistory((prevChat) => [...prevChat, newMessage]);
    setMessage("");

    try {
      const response = await axios.post(
        "https://cheezit-consumer-persona-chatbot-i4licugc4a-ew.a.run.app/send_message",
        {
          human_input: message,
          persona: personaProfileForChatbot,
        }
      );
      const botMessage = {
        role: selectedPersona.name,
        text: response.data.message,
      };
      setChatHistory((prevChat) => [...prevChat, botMessage]);
    } catch (error) {
      console.error(`Error sending this message:${message} to the api:`, error);
      setChatBotError("Something went wrong, please try again");
    }
    setIsTyping(false);
  };

  // trigger the chatHistory Logging when component umounts (after return statement)

  const chatHistoryRef = useRef(chatHistory);

  useEffect(() => {
    chatHistoryRef.current = chatHistory;
  }, [chatHistory]);

  useEffect(() => {
    return () => {
      handleChatConversationLog(chatHistoryRef.current);
    };
  }, []);

  const handleChatConversationLog = async (chatConversationHistory) => {
    const userId = auth.currentUser.uid;
    const chatConversationLog = {
      chatConversationHistory,
      personaName: selectedPersona.cardTitle,
      userId,
    };
    console.log("chatConversationLog", chatConversationLog);

    // Push to Firebase
    try {
      console.log("request to store chat conversation is sent");
      await db.collection("chatConversations").add(chatConversationLog);
    } catch (e) {
      console.log(
        "error message when trying to store chatConversation",
        e.message
      );
    }
  };

  return (
    <div className='PersonaChatbot'>
      <div className='chat-container'>
        <div className='chat-header'>
          <p>
            <b>Consumer Persona Chatbot</b>
          </p>
        </div>
        {/* Chat display */}
        <div className='chat-box'>
          {chatHistory.map((messageObj, index) => (
            <div
              key={index}
              className={`persona-chat-message ${messageObj.role}`}
              ref={index === chatHistory.length - 1 ? chatBoxRef : null}
              style={
                index === chatHistory.length - 1
                  ? { marginBottom: "20px" }
                  : null
              }
            >
              <div className='message-content'>
                <span className='role'>
                  <b>{messageObj.role}: </b>
                </span>
                <span className='text'>{messageObj.text}</span>
              </div>
            </div>
          ))}
        </div>

        {/* Text input and send button */}
        <div className='chat-input'>
          <div className='chat-input-interface'>
            <input
              type='text'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder='Type a message...'
              required
            />
            <button type='button' onClick={handleSendMessage}>
              Send
            </button>
          </div>
          {isTyping && (
            <div className='chat-input-requestFeedback'>
              <Spinner
                style={{ width: "14px", height: "14px", marginRight: "5px" }}
              />
              {selectedPersona.name} is typing...
            </div>
          )}
          {chatBotError && (
            <div
              className='chat-input-requestFeedback'
              style={{ color: "red" }}
            >
              {chatBotError}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheezitPersonaChatbot;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase/config';

// Import libraries
import { jStat } from 'jstat';
import { tTestTwoSample } from 'simple-statistics';

// Import styles
import './BlackboxExperimentDataDetails.css';

// Import assets
import ConceptA from '../assets/ConceptA.png';
import ConceptB from '../assets/ConceptB.png';

// Import icon components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRocket, faStar, faDashboard, faFlask, faQuestion, faHatWizard, faBullseye, faRankingStar, faGear, faRightFromBracket, faMagnifyingGlass, faFilter } from "@fortawesome/free-solid-svg-icons";

// Import components
import AverageConceptSatisfaction from './AverageConceptSatisfaction';
import BarChart from './Barchart';
import ScatterPlot from './ScatterPlot';
import BoxPlotChart from './BoxPlotChart';

// Initial chart data
const initialChartData = [
  ['Concept', 'Average Satisfaction Score', 'Average Net Promotor Score'],
  ['Concept A', 0, 0],
  ['Concept B', 0, 0],
];

function BlackboxExperimentDataDetails({handleDataSetupSwitch}) {
  // State variables
  const [data, setData] = useState([]);
  const [GroupASatisfactionScores, setGroupASatisfactionScores] = useState([]);
  const [GroupBSatisfactionScores, setGroupBSatisfactionScores] = useState([]);
  const [GroupANetPromotorScores, setGroupANetPromotorScores] = useState([]);
  const [GroupBNetPromotorScores, setGroupBNetPromotorScores] = useState([]);
  const [chartData, setChartData] = useState(initialChartData);
  const { clientId, experimentId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from Firestore
        const querySnapshot = await db
          .collection('blackboxClients')
          .doc(`client${clientId}`)
          .collection('blackBoxExperiments')
          .doc(`experiment${experimentId}`)
          .collection('blackboxFeedbackEntries')
          .get();

        const data = querySnapshot.docs.map((doc) => doc.data());

        // Update state variables with fetched data
        setData(data);
        const dataGroupA = data.filter((entry) => entry.concept === 'Concept A');
        const dataGroupB = data.filter((entry) => entry.concept === 'Concept B');

        const GroupASatisfactionScores = dataGroupA.map((entry) => entry.conceptSatisfactionScore);
        setGroupASatisfactionScores(GroupASatisfactionScores);
        const GroupANetPromotorScores = dataGroupA.map((entry) => entry.conceptNetPromotorScore);
        setGroupASatisfactionScores(GroupASatisfactionScores);
        const GroupBSatisfactionScores = dataGroupB.map((entry) => entry.conceptSatisfactionScore);
        setGroupBSatisfactionScores(GroupBSatisfactionScores);
        const GroupBNetPromotorScores = dataGroupB.map((entry) => entry.conceptNetPromotorScore);
        setGroupBNetPromotorScores(GroupBNetPromotorScores);

        console.log('Group A Satisfaction Scores', GroupASatisfactionScores);
        console.log('Group B Satisfaction Scores', GroupBSatisfactionScores);
        console.log('Group A Net Promotor Scores', GroupANetPromotorScores);
        console.log('Group B Net Promotor Scores', GroupBNetPromotorScores);

        // Update chart data with calculated values
        setChartData([
          ['Concept Score', 'Concept A', 'Concept B'],
          ['Usability Score ', jStat.mean(GroupASatisfactionScores), jStat.mean(GroupBSatisfactionScores)],
          ['NPS ', jStat.mean(GroupANetPromotorScores), jStat.mean(GroupBNetPromotorScores)],
        ]);
      } catch (error) {
        console.log('Error getting document:', error);
      }
    };

    fetchData();
  }, []);

        

  return (
        <div className="BlackboxTool-content-experiment">
          <div className="BlackboxTool-content-experiment-titleRow">
            <h1>Experiment KN-004 – Double-Saddled vs. Stick Chips ( <span onClick={handleDataSetupSwitch}>check set-up details</span> )</h1>
            <div className="BlackboxTool-content-experiment-titleRow-filterButton"><FontAwesomeIcon icon={faFilter} /><span className="BlackboxTool-content-experiment-titleRow-filterButton-text">Filter</span></div>
          </div>
            <div className="BlackboxTool-content-experiment-overviewRow">
                <div className="BlackboxTool-content-experiment-overviewRow-conceptTile">
                  <img src={ConceptA} />
                  <img src={ConceptB} />
                  <div className="BlackboxTool-content-experiment-overviewRow-conceptTile-vsCircle">VS</div>
                  <p><b>Hypothesis:</b> A long stick-shaped potato chip with unseasoned grab point (B) will be experienced as much more convenient during gaming compared to the traditional double-saddled chip (A) if both are packed in a traditional Pringles can.
                  <br/>
                  <br/>
                  <b>Key Metrics: </b>Usability & Net Promotor Score (NPS)</p>
                </div>
                <div className="BlackboxTool-content-experiment-overviewRow-conceptKeyInfo">
                  <div className="BlackboxTool-content-experiment-overviewRow-conceptKeyInfo-row">
                    <div className="BlackboxTool-content-experiment-overviewRow-conceptKeyInfo-row-item">A/B-Testing</div>
                    <div className="BlackboxTool-content-experiment-overviewRow-conceptKeyInfo-row-item">€10K</div>
                    <div className="BlackboxTool-content-experiment-overviewRow-conceptKeyInfo-row-item"><span style={{color:'lightgreen'}}>COMPLETED</span></div>
                  </div>
                  <div className="BlackboxTool-content-experiment-overviewRow-conceptKeyInfo-row">
                    <div className="BlackboxTool-content-experiment-overviewRow-conceptKeyInfo-row-item">N = 50</div>
                    <div className="BlackboxTool-content-experiment-overviewRow-conceptKeyInfo-row-item"><span style={{color:'lightgreen'}}>T = 4.3</span></div>
                    <div className="BlackboxTool-content-experiment-overviewRow-conceptKeyInfo-row-item"><span style={{color:'lightgreen'}}>P = 0.02</span></div>
                  </div>
                </div>
            </div>
            <div className='BlackboxTool-content-experiment-chartRow'>
              <div className="BlackboxTool-content-experiment-chartRow-chartTile">
                <h3>Boxplot of the usability scores of concept A & B</h3>
                <BoxPlotChart />
              </div>
              <div className="BlackboxTool-content-experiment-chartRow-chartTile">
                <h3>Average NPS and usability scores of concept A & B</h3>
                <BarChart chartData={chartData} />
              </div>
            </div>
            <div className='BlackboxTool-content-experiment-chartRow'>
              <div className="BlackboxTool-content-experiment-chartRow-chartTile">
                <h3>Usability Score vs Net Promotor Score of concept A & B</h3>
                <ScatterPlot data={data} />
              </div>
              <div className="BlackboxTool-content-experiment-chartRow-chartTile">
                <h3>Pro’s & Con’s for hypothesis</h3>
                <div className="BlackboxTool-content-experiment-chartRow-chartTile-prosCons">
                  <div><div className="BlackboxTool-content-experiment-chartRow-chartTile-prosCons-pro-icon">+</div><div className="BlackboxTool-content-experiment-chartRow-chartTile-prosCons-pro-text">73% of Gen Z participants in the test group reported that the long stick-shaped potato chips were easier to eat with one hand, allowing for uninterrupted gaming.</div></div>
                  <div><div className="BlackboxTool-content-experiment-chartRow-chartTile-prosCons-pro-icon">+</div><div className="BlackboxTool-content-experiment-chartRow-chartTile-prosCons-pro-text">80% of Gen Z participants in the test group indicated that the stick shape of the chips made it easier to reach for a chip without having to tilt the can.</div></div>
                  <div><div className="BlackboxTool-content-experiment-chartRow-chartTile-prosCons-con-icon">-</div><div className="BlackboxTool-content-experiment-chartRow-chartTile-prosCons-pro-text">Sticks have been launched in the US in 2010 with limited success (see report)</div></div>
                </div>
                
              </div>
            </div>
        </div>
  );
}

export default BlackboxExperimentDataDetails;
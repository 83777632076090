import React from 'react';
import './BlackboxMainWebsiteDescription.css';
import { Link } from 'react-router-dom';

const BlackboxMainWebsiteDescription = (props) => {
  return (
    <div className={props.normalOrder ? 'BlackboxMainWebsiteDescription': 'BlackboxMainWebsiteDescription switch'} id="approach">
        <div className={props.normalOrder ? 'BlackboxMainWebsiteDescription-textHolder': 'BlackboxMainWebsiteDescription-textHolder switch'} >
            <div className='BlackboxMainWebsiteDescription-textHolder-text'>
                <h1>{props.title}</h1>
                <p className='myText'>{props.text}</p>
                <Link to={{ pathname: '/blackbox', hash: 'BlackboxLandingPage'}} className='BlackboxLearnMoreButton'>Learn more</Link>
            </div>
        </div>
        <div className={props.normalOrder ? 'BlackboxMainWebsiteDescription-imageHolder': 'BlackboxMainWebsiteDescription-imageHolder switch'}>
            <img className='BlackboxMainWebsiteDescription-imageHolder-image' alt={`Illustration of ${props.title}`} src={props.image}/>
        </div>
    </div>
  )
}

export default BlackboxMainWebsiteDescription
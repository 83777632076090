import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import "./MobileExperimentPage.css";

SwiperCore.use([Autoplay, Pagination]);

function MobilePricingPage(props) {
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  return (
    <div className="MobileExperimentPage" id="experiments-mobile">
      <div className="MobileExperimentPage-introText">
        <h1>Recent Experiments</h1>
        <p>
          We love to experiment with both physical and digital concepts but
          wherever we can bring them together, that's where we get really
          excited. Check out some of our most recent experiments below.
        </p>
      </div>
      <div className="MobileExperimentPage-experimentCards">
        <Swiper
          index={index}
          onSlideChange={(swiper) => {
            handleChangeIndex(swiper.activeIndex);
          }}
          autoplay={{ delay: 10000 }}
        >
          {props.currentExperiments.map((card, i) => (
            <SwiperSlide key={i} >
              <div className="MobileExperimentPage-experimentCards-card">
                <img src={card.image} alt={card.title} />
                <h1>{card.title}</h1>
                <p>{card.description}</p>
                <div className="subscriptionCTA">
                  <div className="subscriptionCTA-button">
                    Case coming soon
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="MobileExperimentPage-progress">
        <div className="MobileExperimentPage-progressDots">
          <span className="swipeIndicator">{index === 0 ? " " : "<"}</span>
          {props.currentExperiments.map((card, i) => (
            <div
              key={i}
              className={
                index === i
                  ? "MobileExperimentPage-progressDots-dotActive"
                  : "MobileExperimentPage-progressDots-dotInActive"
              }
            />
          ))}
          <span className="swipeIndicator">
            {index === props.currentExperiments.length - 1 ? " " : ">"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default MobilePricingPage;
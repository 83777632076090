import React, { useEffect, useState, useRef } from "react";
import BlackboxLogo from "../assets/BlackboxLogo.png";
import { useSignup } from "../hooks/useSignup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase/config";
import { collection, query, where, getDocs } from "firebase/firestore";

// import bootstrap components
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

// import styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./BlackboxRegisterPage.css";

export default function RegisterPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [frontEndError, setFrontEndError] = useState(null);
  const [isRegistering, setIsRegistering] = useState(false); // for the submit button
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const passwordVisibilityTimeoutRef = useRef(null);
  const confirmPasswordVisibilityTimeoutRef = useRef(null);

  const { signup, error, verificationEmailSent } = useSignup();

  useEffect(() => {
    return () => {
      if (passwordVisibilityTimeoutRef.current) {
        clearTimeout(passwordVisibilityTimeoutRef.current);
      }
      if (confirmPasswordVisibilityTimeoutRef.current) {
        clearTimeout(confirmPasswordVisibilityTimeoutRef.current);
      }
    };
  }, []);

  // registration form check (front-end)

  const validateForm = () => {
    if (password !== confirmPassword) {
      return "Passwords do not match";
    }
    if (password.length < 6) {
      return "Password must be at least 6 characters";
    }
    if (playerName.length < 2) {
      return "Please provide a playername of at least 2 characters";
    }
    return null;
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(true);

    // Clear existing timeout
    if (passwordVisibilityTimeoutRef.current) {
      clearTimeout(passwordVisibilityTimeoutRef.current);
    }

    // Set a timeout to hide the password again after 5 seconds
    passwordVisibilityTimeoutRef.current = setTimeout(() => {
      setIsPasswordVisible(false);
    }, 1500); // 5000 milliseconds = 5 seconds
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(true);

    // Clear existing timeout
    if (confirmPasswordVisibilityTimeoutRef.current) {
      clearTimeout(confirmPasswordVisibilityTimeoutRef.current);
    }

    // Set a timeout to hide the confirm password again after 5 seconds
    confirmPasswordVisibilityTimeoutRef.current = setTimeout(() => {
      setIsConfirmPasswordVisible(false);
    }, 1500);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    setFrontEndError(null);
    setIsRegistering(true);
    console.log("registering");

    // Check email suffix in invitations list
    try {
      const emailSuffix = email.split("@")[1];
      const querySuffix = query(
        collection(db, "allowedEmailSuffixes"),
        where("emailSuffix", "==", emailSuffix)
      );
      const querySuffixSnapshot = await getDocs(querySuffix);

      if (querySuffixSnapshot.empty) {
        setFrontEndError(
          "Please use the correct company email address to register."
        );
        return;
      }
    } catch (e) {
      setFrontEndError(
        "An error occured during registration, please contact the admin (info@cigrowthhack.com) if this persists."
      );
    }

    // Check email in invitation list
    try {
      const q = query(
        collection(db, "invitations"),
        where("email", "==", email)
      );
      const querySnapshot = await getDocs(q);
      console.log("querySnapshot", querySnapshot);

      if (querySnapshot.empty) {
        setFrontEndError(
          "You're unable to register with this email, contact the project team (info@cigrowthhack.com) if this might be a mistake."
        );
        return;
      }
    } catch (e) {
      setFrontEndError(
        "An error occured during registration, please contact the admin (info@cigrowthhack.com) if this persists."
      );
    }

    const validationError = validateForm();
    if (validationError) {
      setFrontEndError(validationError);
      setIsRegistering(false);
      return;
    }

    signup(email, password, playerName); //signup should now handle sending verification email
  };

  return (
    <div className='BlackboxRegisterPage'>
      {!verificationEmailSent && (
        <div className='BlackboxRegisterPage-registerCard'>
          <div className='BlackboxRegisterPage-registerCard-header'>
            <img
              className='BlackboxRegisterPage-logo'
              src={BlackboxLogo}
              alt='BlackboxLogo'
            />
            <h1 style={{ fontSize: "20px" }}>Register</h1>
          </div>
          <div className='BlackboxRegisterPage-registerCard-body'>
            <form>
              <div className='BlackboxRegisterPage-registerCard-body-inputArea'>
                <label htmlFor='email'>Email</label>
                <input
                  type='text'
                  id='email'
                  name='email'
                  value={email}
                  onChange={(event) =>
                    setEmail(event.target.value.toLowerCase())
                  }
                />
              </div>
              <div className='BlackboxRegisterPage-registerCard-body-inputArea'>
                <label htmlFor='password'>Password</label>
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  id='password'
                  name='password'
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <FontAwesomeIcon
                  icon={isPasswordVisible ? faEyeSlash : faEye}
                  onClick={togglePasswordVisibility}
                  className='BlackboxRegisterPage-registerCard-body-inputArea-visibilityIcon'
                />
              </div>
              <div className='BlackboxRegisterPage-registerCard-body-inputArea'>
                <label htmlFor='confirmPassword'>Confirm Password</label>
                <input
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  id='confirmPassword'
                  name='confirmPassword'
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
                <FontAwesomeIcon
                  icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                  onClick={toggleConfirmPasswordVisibility}
                  className='BlackboxRegisterPage-registerCard-body-inputArea-visibilityIcon'
                />
              </div>
              <div className='BlackboxRegisterPage-registerCard-body-inputArea'>
                <label htmlFor='playerName'>Player Name (Anonymous)</label>
                <input
                  type='text'
                  id='playerName'
                  name='playerName'
                  value={playerName}
                  onChange={(event) => setPlayerName(event.target.value)}
                />
              </div>
              <button
                className='submitButton'
                type='submit'
                onClick={handleRegister}
              >
                {isRegistering && (
                  <Spinner
                    className='loadingSpinner'
                    animation='border'
                    size='sm'
                  />
                )}{" "}
                Register Now
              </button>
            </form>
          </div>
          <div className='BlackboxRegisterPage-registerCard-footer'>
            {(frontEndError || error) && (
              <div className='BlackboxRegisterPage-registerCard-body-registrationError'>
                <p
                  style={{
                    color: "rgb(247, 168, 0)",
                    width: "80%",
                    margin: "0px auto",
                  }}
                >
                  {frontEndError}
                </p>
                <p
                  fontStyle={{
                    color: "rgb(247, 168, 0)",
                    width: "80%",
                    margin: "0px auto",
                  }}
                >
                  {error}
                </p>
              </div>
            )}
            <p>
              Already have an account? <a href='/'>Login Here</a>
            </p>
          </div>
        </div>
      )}
      {verificationEmailSent && (
        <div className='BlackboxRegisterPage-registerCard-registeredButEmailNotYerVerrified'>
          <FontAwesomeIcon
            icon={faPaperPlane}
            style={{ fontSize: "60px", marginBottom: "40px" }}
          />
          <p>
            You registered successfully,
            <br />
            check your inbox to verify your email address.
            <br />
            (if you didn't receive an email, check your spam folder)
          </p>
          <Button
            style={{ background: "rgba(41,41,51)", marginTop: "20px" }}
            href='/'
          >
            Go to Login
          </Button>
        </div>
      )}
    </div>
  );
}

import React from "react";
import "./BlackboxValueDescription.css";
import { Link } from "react-router-dom";

const BlackboxValueDescription = (props) => {
  return (
    <div
      className={
        props.normalOrder
          ? "BlackboxValueDescription"
          : "BlackboxValueDescription switch"
      }
      id='approach'
    >
      <div
        className={
          props.normalOrder
            ? "BlackboxValueDescription-textHolder"
            : "BlackboxValueDescription-textHolder switch"
        }
      >
        <div className='BlackboxValueDescription-textHolder-text'>
          <h1>{props.title}</h1>
          <p className='myText'>{props.text1}</p>
          <p className='myText'>{props.text2}</p>
          <p className='myText'>{props.text3}</p>
          <p className='myText'>{props.text4}</p>
        </div>
      </div>
      <div
        className={
          props.normalOrder
            ? "BlackboxValueDescription-imageHolder"
            : "BlackboxValueDescription-imageHolder switch"
        }
      >
        <img
          className='BlackboxValueDescription-imageHolder-image'
          alt={`Illustration of ${props.title}`}
          src={props.image}
        />
      </div>
    </div>
  );
};

export default BlackboxValueDescription;

// LandingPage.js
import React, {useEffect, useState} from 'react';
import './SevenStepPageMobile.css';
import ExperimentDetails from '../assets/BlackboxVisual_ExperimentDetails.png';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, Pagination]);


const sevenSteps = [
  {number: "01.", title: 'Assess Customer Needs', text: 'Understand and prioritize the needs of your customers or their "Jobs-to-be-done". We help you study your market, segment your customers, conduct internal and external interviews, and run workshops to gather detailed information about what customers really want.', image: ExperimentDetails},
  {number: "02.", title: 'Determine KPIs', text: 'Once the most important needs are identified, we can help you define Key Performance Indicators (KPIs) that are directly related to these needs. These KPIs will measure how well customer needs are addressed by a current or future product or service.', image: ExperimentDetails},
  {number: "03.", title: 'Document Best Practices', text: 'We help you document the current best practices (or champions) for addressing the most important customer needs. By understanding the current as-is within your organization and the industry, we can identify gaps and pinpoint potential areas for improvement.', image: ExperimentDetails},
  {number: "04.", title: 'Generate Promising Alternatives', text: 'Now we can start generating promising alternatives (or challengers) to the current best practices. Through creative workshops, we help you and your colleagues unleash their creativity to come up with promising new ways to meet customer needs.', image: ExperimentDetails},
  {number: "05.", title: 'Prototype & Run Experiments', text: 'During this step, we can help you develop working prototypes and set-up disciplined business experiments to test how well promising alternatives (the challengers) perform against established best practices (the champions).', image: ExperimentDetails},
  {number: "06.", title: 'Analyse Results & Learn', text: 'After running the experiments, it\'s time to analyze the results and learn from them. This includes studying the data collected during the tests, understanding the impact of the new ideas, and drawing conclusions about their significance.', image: ExperimentDetails},
  {number: "07.", title: 'Implement and Iterate', text: 'Finally, if a new champion prevails, we implement it and continue the process iteratively. This step is about integrating the successful ideas into your business and continuously testing new ones to keep improving.', image: ExperimentDetails},
  {number: <p>#</p>, title: 'Need Help...', text: 'Our crew of designers, developers, and seasoned entrepreneurs can help you conduct internal and external interviews, facilitate and process creative brainstorming sessions, build working prototypes or minimal viable products, and run and analyze disciplined business experiments to make your business fly.', image: ExperimentDetails}
];



const TileComponent = ({ number, title, text }) => {
    return (
      <div className="SevenStepMobile-section-tile">
        <div className="SevenStepMobile-section-tile-number">
          {number}
        </div>
        <div className="SevenStepMobile-section-tile-title">
          {title}
        </div>
        <div className="SevenStepMobile-section-tile-description">
          {text}
        </div>
      </div>
    );
  }

const SevenStepPageMobile = () => {
  const location = useLocation();
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  useEffect(() => {
      if (location.hash) {
        const anchor = document.querySelector(location.hash);
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  return (
    <div className='SevenStepPageMobile'>
        <section className='SevenStepMobile-section'>
            <h2 className='SevenStepMobile-section-mainTitle'><span className='SevenStepMobile-section-mainTitle-5ways'>7 steps</span><br/>to elevate innovation<br/>with experiments</h2>
            <Swiper
            style={{width: '100%'}}
            pagination={{ type: 'bullets' }}
            index={index}
            onSlideChange={(swiper) => {
              handleChangeIndex(swiper.activeIndex);
            }}
            autoplay={{ delay: 10000 }}
            >
            {sevenSteps.map((step, i) => (
              <SwiperSlide key={i} >
                <TileComponent key={i} number={step.number} title={step.title} text={step.text} />
              </SwiperSlide>))}
            </Swiper>
        </section>
    </div>
  );
};

export default SevenStepPageMobile;
import { useState } from "react";
import { auth, db } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  getDoc,
  doc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";

export function useLogin() {
  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const login = async (email, password) => {
    setError(null);

    let res = null;
    try {
      console.log(`Trying to log in with email: ${email}`);

      // Initial login attempt with provided email
      res = await signInWithEmailAndPassword(auth, email, password);

      if (res.user) {
        console.log(
          `Logged in with ${email}. Email verified status: ${res.user.emailVerified}`
        );
      } else {
        console.log(`No user returned for ${email}`);
      }
    } catch (e) {
      console.error(`Error logging in with ${email}: ${e.message}`);
    }

    // If email is not verified, or there's no result from the first attempt, try alternate emails
    if (!res || !res.user || !res.user.emailVerified) {
      let newEmail = null;

      if (email.endsWith("@kellanova.com")) {
        newEmail = email.replace("@kellanova.com", "@kellogg.com");
      } else if (email.endsWith("@marbleousconcepts.com")) {
        newEmail = email.replace("@marbleousconcepts.com", "@howtofly.be");
      }

      if (newEmail) {
        try {
          console.log(`Trying alternate email: ${newEmail}`);
          res = await signInWithEmailAndPassword(auth, newEmail, password);

          if (res.user) {
            console.log(
              `Logged in with ${newEmail}. Email verified status: ${res.user.emailVerified}`
            );
          } else {
            console.log(`No user returned for alternate email ${newEmail}`);
          }
        } catch (e) {
          console.error(
            `Error logging in with alternate email ${newEmail}: ${e.message}`
          );
          setError(`Error: ${e.message}`);
          return; // If error occurs with alternate email, stop execution
        }
      }
    }

    // If the user is still not verified after all attempts, throw an error
    if (!res.user.emailVerified) {
      setError(
        "Please verify your email before logging in. You should have received an email with a verification link. (if not, check your spam folder)"
      );
      return;
    }

    dispatch({ type: "LOGIN", payload: res.user });

    // Log the login event with user ID and timestamp
    const loginEventCollection = collection(db, "loginEvents");
    await addDoc(loginEventCollection, {
      userId: res.user.uid,
      timestamp: serverTimestamp(),
    });

    // After login, check if a player exists for this user
    const playerRef = doc(db, "players", res.user.uid);
    const playerSnapshot = await getDoc(playerRef);

    if (!playerSnapshot.exists()) {
      // Player doesn't exist, create one
      const newPlayer = {
        userUid: res.user.uid,
        playerName: res.user.displayName,
        playerImageURL:
          "https://firebasestorage.googleapis.com/v0/b/howtofly-a47a3.appspot.com/o/AvatarPlaceholder.png?alt=media&token=e867a5a3-2a28-4be7-97d8-9f2ab1d7ecb9",
        playerScore: 0,
        allowed: false,
      };

      await setDoc(playerRef, newPlayer);
    }

    // Navigate to the desired route after successful login and necessary operations
    navigate(`/ideationplatform`);
  };

  return { error, login };
}

import React, { useEffect, useState } from "react";
import { useLogout } from "../hooks/useLogout";
import { auth, db } from "../firebase/config";
import Countdown from "react-countdown";

// Import styles
import "./BlackboxTool.css";

// import font-awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

// Import assets
import conceptB from "../assets/ConceptB.png";
import ExperimentProposal from "../assets/ExperimentProposal.png";
import ShareIdeaVisual from "../assets/InfoShareIdeaVisual.png";
import ShareIdeaModal from "../assets/ShareIdeaModal.png";
import AutocompleteFunction from "../assets/AutocompleteFunction.png";
import VotingButtons from "../assets/VotingButtons.png";
import ChatbotInputField from "../assets/ChatbotInputField.png";
import InspirationQuestions from "../assets/InspirationQuestions.png";
import ShareIdeasMenu from "../assets/ShareIdeasMenu.png";
import PersonaReadMore from "../assets/PersonaReadMore.png";
import PersonaChat from "../assets/PersonaChat.png";
import PersonaJourney from "../assets/PersonaJourney.png";
import PersonaPlusButton from "../assets/PersonaPlusButton.png";
import HomePageInfoCards from "../assets/HomePageInfoCards.png";

// Import components
import BlackboxToolMenu from "./BlackboxToolMenu.js";
import BlackboxToolHeader from "./BlackboxToolHeader.js";
import BlackboxDashboard from "./BlackboxDashboard.js";
import BlackboxTargetAudiences from "./BlackboxTargetAudiences.js";
import BlackboxCISolutions from "./BlackboxCISolutions.js";
import BlackboxExperimentProposals from "./BlackboxExperimentProposals.js";
import BlackboxExperiments from "./BlackboxExperiments.js";
import BlackboxExperimentDataDetails from "./BlackboxExperimentDataDetails.js";
import BlackboxExperimentSetupDetails from "./BlackboxExperimentSetupDetails.js";
import BlackboxRankingPage from "./BlackboxRankingPage.js";
import BlackboxCompetitorCollection from "./BlackboxCompetitorCollection.js";
import BlackboxPlayerPage from "./BlackboxPlayerPage.js";
import CheezitPersonaChatbot from "./CheezitPersonaChatbot";
import BlackboxHomePage from "./BlackboxHomePage";
import BlackboxHomePageVideo from "./BlackboxHomePageVideo";
import BlackboxCIKnowledgeBase from "./BlackboxCIKnowledgeBase";
import BlackboxCISolutionSharingModal from "./BlackboxCISolutionSharingModal";
import BlackboxCISkuSharingModal from "./BlackboxCISkuSharingModal";
import { use } from "i18next";
import { set } from "lodash";

// import bootstrap components
import { Button } from "react-bootstrap";
import { cols } from "jstat";

function BlackboxTool({ content }) {
  const { logout } = useLogout();
  const [customTheme, setCustomTheme] = useState(false);
  const [playerAllowed, setPlayerAllowed] = useState(false);
  const [countdownDate, setCountdownDate] = useState(
    new Date("September 27, 2023 10:00:00").getTime()
  );
  const [buildDeckVisible, setBuildDeckVisible] = useState(false);
  const [buildDeckContent, setBuildDeckContent] = useState([
    {
      imageUrl:
        "https://t3.ftcdn.net/jpg/03/45/05/92/360_F_345059232_CPieT8RIWOUk4JqBkkWkIETYAkmz2b75.jpg",
      title: "Slot 1",
      subTitle: "This slot is currently still empty",
      type: "This slot is currently still empty",
      empty: true,
    },
    {
      imageUrl:
        "https://t3.ftcdn.net/jpg/03/45/05/92/360_F_345059232_CPieT8RIWOUk4JqBkkWkIETYAkmz2b75.jpg",
      title: "Slot 2",
      subTitle: "This slot is currently still empty",
      type: "This slot is currently still empty",
      empty: true,
    },
    {
      imageUrl:
        "https://t3.ftcdn.net/jpg/03/45/05/92/360_F_345059232_CPieT8RIWOUk4JqBkkWkIETYAkmz2b75.jpg",
      title: "Slot 3",
      subTitle: "This slot is currently still empty",
      type: "This slot is currently still empty",
      empty: true,
    },
  ]);
  const [pageInfoModalShow, setPageInfoModalShow] = useState(false);
  const [solutionModalShow, setSolutionModalShow] = useState(false);
  const [solutionModalFromBuildDeck, setSolutionModalFromBuildDeck] =
    useState(false);
  const [skuSharingModalShow, setSkuSharingModalShow] = useState(false);
  const [buildIdeaErrorMessage, setBuildIdeaErrorMessage] = useState("");
  const [visibleContent, setVisibleContent] = useState(content);
  const [playersData, setPlayersData] = useState([]);
  const [needsData, setNeedsData] = useState([]);
  const [needsVotesData, setNeedsVotesData] = useState([]);
  const [argumentsData, setArgumentsData] = useState([]);
  const [argumentsLikesData, setArgumentsLikesData] = useState([]);
  const [ciSolutionsData, setCiSolutionsData] = useState([]);
  const [ciSolutionsVotesData, setCiSolutionsVotesData] = useState([]);
  const [currentUserId, setCurrentUser] = useState(auth.currentUser.uid);
  const [appliedFilterOptions, setAppliedFilterOptions] = useState([
    {
      Ongoing: true,
      Completed: false,
      "P-value > 0.05": false,
      "P-value < 0.05": false,
    },
    {
      Ongoing: false,
      Completed: true,
      "P-value > 0.05": false,
      "P-value < 0.05": false,
    },
  ]);
  const [menuActive, setMenuActive] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Determine if the user is on a mobile device
  const [mobileDevice, setMobileDevice] = useState(window.innerWidth < 900);

  // set userUid
  const userUid = auth.currentUser.uid;

  useEffect(() => {
    const handleResize = () => {
      console.log("handleResize", window.innerWidth);
      setWindowWidth(window.innerWidth);
      setMobileDevice(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const set countdown logic

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      if (distance < 0) {
        clearInterval(interval);
        return;
      }
    }, 1000);

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [countdownDate]);

  // check wether the player is allowed to play => Countdown clock to start the game
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Time's up!</span>;
    } else {
      // Render a countdown
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };

  // Triggering the useEffect Hook
  useEffect(() => {
    fetchNeedsData();
    fetchNeedsVotesData();
    fetchArgumentsData();
    fetchArgumentsLikesData();
    fetchPlayersData();
    fetchCiSolutionsData();
    fetchCiSolutionsVotesData();
  }, []);

  // fetching functions

  const fetchPlayersData = async (currentUserId) => {
    try {
      const playersCollection = db.collection("players");
      const snapshot = await playersCollection.get();

      // Use forEach to loop over the documents in the snapshot
      snapshot.forEach((doc) => {
        const playerData = doc.data();
        // Check if userUid of the player is equal to currentUserId
        if (playerData.userUid === auth.currentUser.uid) {
          // Check the value of the 'allowed' key and perform your logic here
          if (playerData.allowed) {
            setPlayerAllowed(true);
            console.log("playerAllowed", playerAllowed);
          } else {
            setPlayerAllowed(false);
          }
        }
      });

      // Optionally, if you still want to set the entire players data to a state or variable.
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        playerId: doc.id,
      }));
      setPlayersData(data);
    } catch (error) {
      console.error("Error fetching players data: ", error);
    }
  };

  const fetchNeedsData = async () => {
    try {
      const needsCollection = db.collection("needs");
      const snapshot = await needsCollection.get();
      // Include the doc.id in the mapped data
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        needId: doc.id,
      }));
      setNeedsData(data);
    } catch (error) {
      console.error("Error fetching needs data: ", error);
    }
  };

  const fetchCiSolutionsData = async () => {
    try {
      const ciSolutionsCollection = db.collection("ciSolutions");
      const snapshot = await ciSolutionsCollection.get();
      // Include the doc.id in the mapped data
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        ciSolutionId: doc.id,
      }));
      setCiSolutionsData(data);
    } catch (error) {
      console.error("Error fetching solutions data: ", error);
    }
  };

  const fetchNeedsVotesData = async () => {
    try {
      const needsVotesCollection = db.collection("needsVotes");
      const snapshot = await needsVotesCollection.get();
      // Include the doc.id in the mapped data
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        needVoteId: doc.id,
      }));
      setNeedsVotesData(data);
    } catch (error) {
      console.error("Error fetching needsVotes data: ", error);
    }
  };

  const fetchCiSolutionsVotesData = async () => {
    try {
      const ciSolutionsVotesCollection = db.collection("ciSolutionsVotes");
      const snapshot = await ciSolutionsVotesCollection.get();
      // Include the doc.id in the mapped data
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        ciSolutionsVoteId: doc.id,
      }));
      setCiSolutionsVotesData(data);
    } catch (error) {
      console.error("Error fetching ciSolutionsVotes data: ", error);
    }
  };

  const fetchArgumentsData = async () => {
    try {
      const argumentsCollection = db.collection("arguments");
      const snapshot = await argumentsCollection.get();
      // Include the doc.id in the mapped data
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        argumentId: doc.id,
      }));
      setArgumentsData(data);
    } catch (error) {
      console.error("Error fetching arguments data: ", error);
    }
  };

  const fetchArgumentsLikesData = async () => {
    try {
      const argumentsLikesCollection = db.collection("argumentsLikes");
      const snapshot = await argumentsLikesCollection.get();
      // Include the doc.id in the mapped data
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        argumentLikeId: doc.id,
      }));
      setArgumentsLikesData(data);
    } catch (error) {
      console.error("Error fetching arguments data: ", error);
    }
  };

  // Event handling functions

  const handleDataSetupSwitch = () => {
    if (visibleContent === "dataDetails") {
      setVisibleContent("setupDetails");
    } else {
      setVisibleContent("dataDetails");
    }
  };

  const handleMenuClick = (e) => {
    setVisibleContent(e.currentTarget.id);
    if (mobileDevice) {
      setMenuActive(false);
    }
  };

  const handleSignOut = () => {
    logout();
  };

  const handleCiSolutionVote = async (index, vote, item) => {
    const voteCreationDate = new Date();
    try {
      const userId = auth.currentUser.uid;
      const voteRef = db
        .collection("ciSolutionsVotes")
        .doc(`${userId}_${item.ciSolutionId}`);

      try {
        const doc = await voteRef.get();

        if (!doc.exists) {
          // No existing vote by this user for this need, so add it
          try {
            await voteRef.set({
              userId: userId,
              ciSolutionId: item.ciSolutionId,
              vote: vote,
              voteCreationDate: voteCreationDate,
            });
            setCiSolutionsVotesData((prevData) => [
              ...prevData,
              { userId, ciSolutionId: item.ciSolutionId, vote },
            ]);
          } catch (error) {
            console.error("Error setting the ciSolution vote data: ", error);
          }
        } else {
          const existingVote = doc.data().vote;

          if (existingVote !== vote) {
            // Update the existing vote if it's different
            try {
              await voteRef.update({
                vote: vote,
                voteCreationDate: voteCreationDate,
              });

              // Update the local state for the modified vote
              setCiSolutionsVotesData((prevData) =>
                prevData.map((voteData) =>
                  voteData.userId === userId &&
                  voteData.ciSolutionId === item.ciSolutionId
                    ? { ...voteData, vote: vote }
                    : voteData
                )
              );
            } catch (error) {
              console.error("Error updating the vote data: ", error);
            }
          }
          if (existingVote === vote) {
            // Update the existing vote if it's different
            try {
              await voteRef.update({
                vote: "cancel",
                voteCreationDate: voteCreationDate,
              });

              // Update the local state for the modified vote
              setCiSolutionsVotesData((prevData) =>
                prevData.map((voteData) =>
                  voteData.userId === userId &&
                  voteData.ciSolutionId === item.ciSolutionId
                    ? { ...voteData, vote: "cancel" }
                    : voteData
                )
              );
            } catch (error) {
              console.error("Error updating the vote data: ", error);
            }
          }
        }

        // Update UI accordingly (Optional based on your requirement)
        // e.g. add to vote count, change user vote indication, etc.
      } catch (error) {
        console.error("Error fetching the vote reference: ", error);
      }
    } catch (error) {
      console.error("Error handling the vote: ", error);
    }
  };

  const handleSolutionsVote = (index, vote) => {
    setCiSolutionsData((prevData) => {
      const newData = [...prevData];
      const currentItem = newData[index];

      if (currentItem.userVote === vote) {
        return prevData; // if the user clicks the same vote, don't do anything
      }

      if (vote === "agree") {
        currentItem.agreeVotes += 1;
        if (currentItem.userVote === "disagree") {
          currentItem.disagreeVotes =
            currentItem.disagreeVotes > 0 ? currentItem.disagreeVotes - 1 : 0;
        }
      } else if (vote === "disagree") {
        currentItem.disagreeVotes += 1;
        if (currentItem.userVote === "agree") {
          currentItem.agreeVotes =
            currentItem.agreeVotes > 0 ? currentItem.agreeVotes - 1 : 0;
        }
      }

      currentItem.userVote = vote;

      return newData;
    });
  };

  const handleAddToBuildDeck = (knowledgeBaseItem) => {
    try {
      setBuildDeckVisible(true);
      setBuildDeckContent((prevData) => {
        // Find the index of the first empty slot
        const emptyIndex = prevData.findIndex((item) => item.empty);
        // If an empty slot is found, replace its content
        if (emptyIndex !== -1) {
          const newData = [...prevData];
          const newContent = {
            imageUrl: knowledgeBaseItem.imageUrl,
            title: knowledgeBaseItem.cardTitle,
            subTitle: knowledgeBaseItem.cardSubTitle,
            type: knowledgeBaseItem.cardType,
            knowledgeBaseItem: knowledgeBaseItem,
          };
          newData[emptyIndex] = {
            ...newContent,
            empty: false,
          };
          setBuildIdeaErrorMessage("");
          return newData;
        } else {
          // Display a user error if there are no empty slots
          setBuildIdeaErrorMessage(
            "Your card wasn't added to the deck because it's already full. Remove an item befor you can add a new one."
          );
          return prevData; // Return the previous data unchanged
        }
      });
    } catch (error) {
      console.error("Error adding to build deck: ", error);
    }
  };

  const handleRemoveFromBuildDeck = (index) => {
    setBuildDeckContent((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        imageUrl:
          "https://t3.ftcdn.net/jpg/03/45/05/92/360_F_345059232_CPieT8RIWOUk4JqBkkWkIETYAkmz2b75.jpg",
        title: "Slot " + (index + 1),
        subTitle: "This slot is currently still empty",
        empty: true,
      };
      setBuildIdeaErrorMessage("");
      return newData;
    });
  };

  const handleShareIdeaClickFromBuildDeck = (fromBuildDeck) => {
    if (
      fromBuildDeck &&
      !buildDeckContent.some((item) => item.empty === false)
    ) {
      setBuildIdeaErrorMessage(
        "Please add at least one item to your deck before sharing an idea."
      );
    } else {
      setSolutionModalShow(true);
      setSolutionModalFromBuildDeck(fromBuildDeck);
      setBuildIdeaErrorMessage("");
    }
  };

  return (
    <div className={customTheme ? "BlackboxTool customTheme" : "BlackboxTool"}>
      {false && (
        <div
          style={{
            background: "black",
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p>Thanks for registering!</p>
          <p>The Ideation will start in ...</p>
          <Countdown date={countdownDate} renderer={renderer} />
        </div>
      )}
      {true && (
        <>
          <BlackboxToolMenu
            handleMenuClick={handleMenuClick}
            handleSignOut={handleSignOut}
            visibleContent={visibleContent}
            menuActive={menuActive}
            setMenuActive={setMenuActive}
          />
          {buildDeckVisible && (
            <div className='BuildDeck-card-modal'>
              <div
                className='BuildDeck-card-modal-background'
                onClick={() => setBuildDeckVisible(false)}
              ></div>
              <div className='BuildDeck-card-modal-readMoreContentSidePannelContainer'>
                <div
                  className='BuildDeck-card-modal-readMoreContentSidePannelContainer-closeButtonContainer'
                  onClick={() => setBuildDeckVisible(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className='BuildDeck-card-modal-readMoreContentSidePannelContainer-content'>
                  <div className='BuildDeck-card-modal-readMoreContentSidePannelContainer-content-title'>
                    Idea Builder
                  </div>
                  {buildDeckContent.some((item) => item.empty === false) && (
                    <div
                      className='buildIdeaErrorMessage'
                      style={{ color: "rgb(250,115,85)" }}
                    >
                      {buildIdeaErrorMessage}
                    </div>
                  )}
                  <br />
                  <div>
                    <p>
                      Next to creating ideas from scratch, you can also build an
                      idea by <strong>combining different ideas</strong> and{" "}
                      <strong>inspirational content</strong> such as country
                      profiles, retailer profiles, or competitor SKUs.
                    </p>
                    <p>
                      All you need to do is click on the + sign on top of the
                      idea cards or inspiration cards to add them to the deck.
                      Once you have the right combination, click on the build
                      idea button.
                    </p>
                    <br />
                  </div>
                  <div className='BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body'>
                    {buildDeckContent.map((item, index) => (
                      <div
                        className={
                          item.empty
                            ? "BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item empty"
                            : "BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item"
                        }
                      >
                        <div className='BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-image'>
                          <img src={item.imageUrl} alt={item.title} />
                        </div>
                        <div className='BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-content'>
                          <h1>{item.title}</h1>
                          <p>{item.type}</p>
                        </div>
                        <div className='BuildDeck-card-modal-readMoreContentSidePannelContainer-content-body-item-removeItemButtonContainer'>
                          {!item.empty && (
                            <Button
                              onClick={() => handleRemoveFromBuildDeck(index)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {!buildDeckContent.some((item) => item.empty === false) && (
                    <div
                      className='buildIdeaErrorMessage'
                      style={{ color: "rgb(250,115,85)" }}
                    >
                      {buildIdeaErrorMessage}
                    </div>
                  )}

                  <Button
                    onClick={() => handleShareIdeaClickFromBuildDeck(true)}
                    className='createIdeaButtonBuildDeck'
                  >
                    Build Idea
                  </Button>
                </div>
              </div>
            </div>
          )}
          {pageInfoModalShow && (
            <div className='PageInfo-card-modal'>
              <div
                className='PageInfo-card-modal-background'
                onClick={() => setPageInfoModalShow(false)}
              ></div>
              <div className='PageInfo-card-modal-readMoreContentSidePannelContainer'>
                <div
                  className='PageInfo-card-modal-readMoreContentSidePannelContainer-closeButtonContainer'
                  onClick={() => setPageInfoModalShow(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content'>
                  <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-title'>
                    <div className='mainTitle'>Page Info</div>
                    <div className='subTitle'>
                      What can you do on this page?
                    </div>
                  </div>
                  {visibleContent === "BlackboxHomePage" && (
                    <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                      <p>
                        On this page, you can find some extra information about
                        the growth hack and ideation platform.{" "}
                      </p>
                      <p>
                        Just click on one of the info cards available to get
                        more information on a specific topic.
                      </p>
                      <img
                        src={HomePageInfoCards}
                        style={{ width: "100%", marginTop: "10px" }}
                      />
                      <p>
                        Ready to start ideating? Then just click on "share
                        ideas" in the navigation menu.
                      </p>
                      <img
                        src={ShareIdeasMenu}
                        style={{
                          border: "solid rgb(24,24,29) 5px",
                          borderRadius: "10px",
                          marginTop: "10px",
                          width: "50%",
                        }}
                      />
                    </div>
                  )}
                  {visibleContent === "BlackboxFactsBelieves" && (
                    <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                      <p>
                        On this page, you can share your ideas.
                        <br />
                        <br />
                        Sharing ideas is straightforward.
                        <br />
                        Just click on the share idea button at the top of the
                        page…
                      </p>
                      <img src={ShareIdeaVisual} alt='Share Idea Visual' />
                      <p>…and start typing your idea.</p>
                      <img
                        src={ShareIdeaModal}
                        alt='Share Idea Modal'
                        style={{ width: "100%" }}
                      />
                      <p>
                        Need inspiration? Just end your sentence with … and
                        click on the auto-complete button. Now your personal
                        AI-assistant will try to finish your idea.
                      </p>
                      <img
                        src={AutocompleteFunction}
                        alt='AI-autocomplete function'
                      />
                      <p>
                        No idea to share, remember you can also vote on ideas,
                        and share pro & con arguments why we should prioritize
                        or deprioritize this idea for the sprint workshops.
                      </p>
                      <img
                        src={VotingButtons}
                        alt='Voting Buttons'
                        style={{
                          border: "solid rgb(24,24,29) 5px",
                          marginTop: "10px",
                        }}
                      />
                      <p>
                        <strong>!Important:</strong> You can only share 5
                        upvotes. So if your out of green votes, you first need
                        to deselect an upvote before you can place an upvote
                        once again.
                      </p>
                    </div>
                  )}
                  {visibleContent === "BlackboxConsumerPersonasAndJourneys" && (
                    <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                      <p>
                        On this page, you can get inspiration from consumer
                        personas.
                        <br />
                        <br />
                        The inspiration process is prety straightforward. Just
                        click on the <strong>"read more"</strong> of one of the
                        persona cards, and learn more about the
                        key-characteristics of a specific persona.
                      </p>
                      <img
                        src={PersonaReadMore}
                        alt='Persona Read More Button'
                      />
                      <p>
                        Interested to chat and brainstorm with a specific
                        persona?
                        <br />
                        Then simply click on the chat button and start chatting.
                      </p>
                      <img
                        src={PersonaChat}
                        alt='Persona Chat Button'
                        style={{
                          width: "70%",
                          border: "solid 5px rgb(24,24,29)",
                        }}
                      />
                      <p style={{ marginTop: "10px" }}>
                        <strong>!Important:</strong> Be aware this is a purely
                        fictional conversation with an AI-chatbot that imagines
                        how the personas - with their specific characteristics -
                        would react if they were real.
                      </p>
                      <p>
                        To further inpspire you, we've also created a fictional
                        consumer journey for each persona, which can help you to
                        better understand the consumer's potential needs and
                        pain points, and spot potential opportunities for
                        Cheez-It.
                      </p>
                      <img
                        src={PersonaJourney}
                        alt='Persona Journey'
                        style={{
                          border: "solid rgb(24,24,29) 5px",
                          marginTop: "10px",
                          width: "70%",
                        }}
                      />
                      <p>
                        Inspired by one of the personas? Then click on the{" "}
                        <strong>plus sign</strong> on the persona card to add it
                        to your <strong>build deck</strong>. Once you have the
                        right combination, click on the build idea button. By
                        doing so, the AI-autocomplete will try to finish your
                        idea, taking the needs and pain points of the chosen
                        persona into account.
                      </p>
                      <img
                        src={PersonaPlusButton}
                        alt='PersonaPlusButton'
                        style={{
                          border: "solid rgb(24,24,29) 5px",
                          marginTop: "10px",
                          width: "70%",
                        }}
                      />
                    </div>
                  )}
                  {visibleContent === "BlackboxCountryProfiles" && (
                    <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                      <p>
                        On this page, you can get inspiration from different
                        country profiles.
                        <br />
                        <br />
                        The inspiration process is prety straightforward. Just
                        click on the <strong>"read more"</strong> of one of the
                        country cards, and learn more about the
                        key-characteristics of a specific country.
                      </p>
                      <p>
                        Inspired by one of the countries? Then click on the{" "}
                        <strong>plus sign</strong> on the country card to add it
                        to your <strong>build deck</strong>. Once you have the
                        right combination, click on the build idea button. By
                        doing so, the AI-autocomplete will try to finish your
                        idea, taking the key-elements of the chosen country into
                        account.
                      </p>
                    </div>
                  )}
                  {visibleContent === "BlackboxRetailerProfiles" && (
                    <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                      <p>
                        On this page, you can get inspiration from different
                        retailer profiles.
                        <br />
                        <br />
                        The inspiration process is prety straightforward. Just
                        click on the <strong>"read more"</strong> of one of the
                        retailer cards, and learn more about the
                        key-characteristics of a specific retailer.
                      </p>
                      <p>
                        Inspired by one of the retailers? Then click on the{" "}
                        <strong>plus sign</strong> on the retailer card to add
                        it to your <strong>build deck</strong>. Once you have
                        the right combination, click on the build idea button.
                        By doing so, the AI-autocomplete will try to finish your
                        idea, taking the key-elements of the chosen retailer
                        into account.
                      </p>
                    </div>
                  )}
                  {visibleContent === "BlackboxCheezItSKUs" && (
                    <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                      <p>
                        On this page, you can get inspiration from different
                        Cheez-It SKUs.
                        <br />
                        <br />
                        The inspiration process is prety straightforward. Just
                        click on the <strong>"read more"</strong> of one of the
                        Cheez-It SKU cards, and learn more about the
                        key-characteristics of a specific Cheez-It SKU.
                      </p>
                      <p>
                        Inspired by one of the Cheez-It SKUs? Then click on the{" "}
                        <strong>plus sign</strong> on the Cheez-It SKU card to
                        add it to your <strong>build deck</strong>. Once you
                        have the right combination, click on the build idea
                        button. By doing so, the AI-autocomplete will try to
                        finish your idea, taking the key-elements of the chosen
                        Cheez-It SKU into account.
                      </p>
                    </div>
                  )}
                  {visibleContent === "BlackboxMarketInspiration" && (
                    <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                      <p>
                        On this page, you can get inspiration from different
                        Competitor SKUs.
                        <br />
                        <br />
                        The inspiration process is prety straightforward. Just
                        click on the <strong>"read more"</strong> of one of the
                        Competitor SKU cards, and learn more about the
                        key-characteristics of a specific Competitor SKU.
                      </p>
                      <p>
                        Inspired by one of the Competitor SKUs? Then click on
                        the <strong>plus sign</strong> on the Competitor SKU
                        card to add it to your <strong>build deck</strong>. Once
                        you have the right combination, click on the build idea
                        button. By doing so, the AI-autocomplete will try to
                        finish your idea, taking the key-elements of the chosen
                        Competitor SKU into account.
                      </p>
                    </div>
                  )}
                  {visibleContent === "BlackboxPlayerPage" && (
                    <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                      <p>
                        On this page, you can find an overview of your key
                        activities as a player.
                      </p>
                      <p>
                        Additionaly, you can alter your profile pic by clicking
                        on the pencil sign. You cannot change your profile name,
                        if you want to do so please mail to{" "}
                        <a href='mailto:info@cigrowthhack.com'>
                          info@cigrowthhack.com
                        </a>
                        .
                      </p>
                    </div>
                  )}
                  {visibleContent === "BlackboxRankings" && (
                    <div className='PageInfo-card-modal-readMoreContentSidePannelContainer-content-body'>
                      <p>
                        On this page, you can find your position in the player
                        ranking. To rise in the ranking, you need to gain
                        additional Experience Points (XP). Here are a couple of
                        ways to do so:
                        <br />
                        <br />
                        <strong>Sharing an Idea:</strong>
                        <ul>
                          <li>Base XP for sharing an idea: 10 XP</li>
                          <li>
                            Bonus XP based on upvotes received: +2 XP per upvote
                          </li>
                          <li>
                            Penalty XP based on downvotes received: -1 XP per
                            downvote
                          </li>
                          <li>
                            +50 XP if your idea gets shortlisted for growth hack
                          </li>
                        </ul>
                        <strong>Upvoting or Downvoting an Idea:</strong>
                        <ul>
                          <li>
                            30 XP if idea you upvoted (greenvoted) gets
                            shortlisted for growth hack
                          </li>
                        </ul>
                        <strong>
                          Sharing Arguments for Upvoting or Downvoting an Idea:
                        </strong>
                        <ul>
                          <li>
                            Base XP for sharing a pro- or con-argument: 5 XP
                          </li>
                        </ul>
                        <strong>Activity Streak:</strong>
                        <ul>
                          <li>+10 XP for a 3-day streak</li>
                          <li>+20 XP for a 7-day streak</li>
                          <li>+40 XP for a 14-day streak</li>
                        </ul>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <BlackboxCISolutionSharingModal
            solutionModalShow={solutionModalShow}
            setSolutionModalShow={setSolutionModalShow}
            buildDeckContent={buildDeckContent}
            ciSolutionsData={ciSolutionsData}
            setCiSolutionsData={setCiSolutionsData}
            setBuildDeckVisible={setBuildDeckVisible}
            solutionModalFromBuildDeck={solutionModalFromBuildDeck}
            skuSharingModalShow={skuSharingModalShow}
            setSkuSharingModalShow={setSkuSharingModalShow}
            customTheme={customTheme}
          />
          <BlackboxCISkuSharingModal
            skuSharingModalShow={skuSharingModalShow}
            setSkuSharingModalShow={setSkuSharingModalShow}
            customTheme={customTheme}
          />
          <div
            className={
              menuActive
                ? "BlackboxTool-content menuActive"
                : "BlackboxTool-content menuInActive"
            }
          >
            <BlackboxToolHeader
              playersData={playersData}
              visibleContent={visibleContent}
              menuActive={menuActive}
              setMenuActive={setMenuActive}
              setBuildDeckVisible={setBuildDeckVisible}
              buildDeckContent={buildDeckContent}
              setPageInfoModalShow={setPageInfoModalShow}
              customTheme={customTheme}
            />
            <div className='BlackboxTool-content-body'>
              {visibleContent === "BlackboxHomePage" && (
                <BlackboxHomePageVideo
                  customTheme={customTheme}
                  setCustomTheme={setCustomTheme}
                />
              )}
              {visibleContent === "BlackboxDashboard" && <BlackboxDashboard />}

              {visibleContent === "BlackboxPlayerPage" && (
                <BlackboxPlayerPage
                  currentUserId={currentUserId}
                  playersData={playersData}
                  needsData={needsData}
                  ciSolutionsData={ciSolutionsData}
                  ciSolutionsVotesData={ciSolutionsVotesData}
                  argumentsData={argumentsData}
                />
              )}

              {visibleContent === "BlackboxFactsBelieves" && (
                <BlackboxCISolutions
                  ciSolutionsData={ciSolutionsData}
                  setCiSolutionsData={setCiSolutionsData}
                  handleCiSolutionVote={handleCiSolutionVote} //Used in the BlackboxNeedTile component
                  ciSolutionsVotesData={ciSolutionsVotesData} // Used in the BlackboxNeedTile component
                  playersData={playersData}
                  currentUserId={currentUserId}
                  handleAddToBuildDeck={handleAddToBuildDeck}
                  solutionModalShow={solutionModalShow}
                  setSolutionModalShow={setSolutionModalShow}
                  handleShareIdeaClickFromBuildDeck={
                    handleShareIdeaClickFromBuildDeck
                  }
                  solutionModalFromBuildDeck={solutionModalFromBuildDeck}
                />
              )}

              {[
                "BlackboxConsumerPersonasAndJourneys",
                "BlackboxCountryProfiles",
                "BlackboxRetailerProfiles",
                "BlackboxCheezItSKUs",
              ].includes(visibleContent) && (
                <BlackboxCIKnowledgeBase
                  visibleContent={visibleContent}
                  handleAddToBuildDeck={handleAddToBuildDeck}
                />
              )}

              {visibleContent === "BlackboxMarketInspiration" && (
                <div style={{ margin: "100px auto" }}>
                  <BlackboxCompetitorCollection
                    handleAddToBuildDeck={handleAddToBuildDeck}
                    setSkuSharingModalShow={setSkuSharingModalShow}
                  />
                </div>
              )}

              {visibleContent === "BlackboxRankings" && (
                <div style={{ margin: "100px auto" }}>
                  <BlackboxRankingPage
                    playersData={playersData}
                    currentUserId={currentUserId}
                    ciSolutionsData={ciSolutionsData}
                    ciSolutionsVotesData={ciSolutionsVotesData}
                    argumentsData={argumentsData}
                    argumentsLikesData={argumentsLikesData}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BlackboxTool;

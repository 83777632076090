import gridsImage from "../../assets/GridsImage.png";
import rolldImage from "../../assets/RolldImage.png";
import seasonalPrintImage from "../../assets/SeasonalPrintImage.png";
import puffdPizzaImage from "../../assets/PuffdPizzaImage.png";
import ranchColabImage from "../../assets/RanchColabImage.png";
import extraCrunchyImage from "../../assets/ExtraCrunchyImage.png";
import smokdImage from "../../assets/SmokdImage.png";

// import images
import cheezit1 from "../../assets/webpKB/cheezit1.webp";
import cheezit2 from "../../assets/webpKB/cheezit2.webp";
import cheezit3 from "../../assets/webpKB/cheezit3.webp";
import cheezit4 from "../../assets/webpKB/cheezit4.webp";
import cheezit5 from "../../assets/webpKB/cheezit5.webp";
import cheezit6 from "../../assets/webpKB/cheezit6.webp";

const cheezItSKUsIntro = {
  title: "Cheez-It SKUs",
  description: [
    "Within innovation, we don’t have to try to reinvent the wheel. So let’s have a look at what products our U.S. colleagues have already created throughout the years. Do you see anything useful we can copy or adapt?",
  ],
};

const cheezItSKUs = [
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Original Snack Crackers",
    imageUrl: cheezit1,
    productDescription: [
      "The one. The only. The Original. It ™s the iconic Cheez-It® flavor you know and love. The square shape, the rigid edges and that hole in the middle  “ everything about this baked snack cracker is the real deal, especially the cheese.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola oil with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% or less of salt, paprika, yeast, paprika extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [1.5, 3, 7, 12, 12.4, 18, 21],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Extra Toasty Snack Crackers",
    imageUrl: cheezit2,
    productDescription: [
      "You asked. We toasted. After years of fan requests for an extra toasty Cheez-It®, the wait is finally over. So here it is  “ a full box of our delicious Cheez-It® Extra Toasty Crackers, and you made it happen.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola oil with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color). Contains 2% or less of salt, dextrose, paprika, yeast, paprika extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [3, 7, 12.4, 21],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Extra Toasty Cheddar Jack Snack Crackers",
    imageUrl: cheezit3,
    productDescription: [
      "Cheez-It® Extra Toasty Cheddar Jack perfectly combines the cheesy, marbled taste of Monterey Jack with the tang of Cheddar Cheese, all with an extra toasty bite.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola oil with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% of less of salt, dextrose, whey, paprika, cheddar cheese (milk, cheese cultures, salt, enzymes), buttermilk, tomato powder, garlic powder, monosodium glutamate, yeast, onion powder, monterey jack cheese (milk, cheese cultures, salt, enzymes), sugar, malic acid, annatto extract color, natural and artificial flavors, disodium inosinate, disodium guanylate, spice extract, yeast extract, paprika extract color, yellow 5, yellow 6, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [3, 7, 12.4, 21],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Extra Cheesy Snack Crackers",
    imageUrl: cheezit4,
    productDescription: [
      "We've re-invented the wheel. The wheel of cheese and crackers, that is! It's more of the Cheez-It® goodness you love.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% or less of salt, maltodextrin, paprika, buttermilk, nonfat milk, monosodium glutamate, cheddar cheese (milk, cheese cultures, salt, enzymes), yeast extract, lactic acid, sugar, whey, citric acid, yeast, paprika extract color, disodium inosinate, disodium guanylate, garlic, natural flavor, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [12.4],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Extra Big Snack Crackers",
    imageUrl: cheezit5,
    productDescription: [
      "Cheez-It® Extra Big crackers are the great taste of Cheez-It® in a larger size for bigger snacking. Cheez-It® Extra Big baked snack crackers are perfect for pairing with your favorite dips. Or, top these 100% real cheese crackers with your favorite toppings for the biggest Cheez-It® experience you've ever had.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola oil with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% or less of salt, paprika, yeast, paprika extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [11.7],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "White Cheddar Snack Crackers",
    imageUrl: cheezit6,
    productDescription: [
      "They ™re made with 100% real cheddar cheese! Of course they're always a fan favorite. There's no secret behind the delicious goodness of Cheez-It® White Cheddar Snack Crackers. This cheesy, crunchy snack can only be this delicious with 100% real cheddar cheese!",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (soybean and palm oil with TBHQ for freshness), white cheddar cheese (milk, cheese cultures, salt, enzymes).   Contains 2% or less of salt, whey, cheddar cheese (milk, cheese cultures, salt, enzymes), monosodium glutamate, whey protein concentrate, butter (cream, salt), baking soda, yeast, natural and artificial flavor, lactic acid, calcium lactate, disodium phosphate, citric acid, turmeric extract color, annatto extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [7, 12.4, 21],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Pepper Jack Snack Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4821791/kicproductimage-96897_kicproductimage-96897.png",
    productDescription: [
      "Not for the mild-mannered, these Cheez-It® Pepper Jack Crackers are the real deal, baby. Made with a natural kick of pepper jack, we bake these snack crackers with a whole lot of flavor. Made with 100% real cheese!",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola with TBHQ for freshness), white cheddar cheese (milk, cheese cultures, salt, enzymes).  Contains 2% or less of salt, monterey jack cheese (milk, cheese cultures, salt, enzymes), green bell pepper, red bell pepper, natural jalapeno flavor, spice, monosodium glutamate, baking soda, yeast, turmeric extract color, annatto extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [12.4],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Cheddar Jack Snack Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4509101/kicproductimage-96899_kicproductimage-96899.png",
    productDescription: [
      "The cheesy, crunchy, satisfaction of Monterey Jack and Cheddar Cheese combined! Cheez-It® Cheddar Jack crackers bring cheesy perfection to a whole new level. The swirl of beautifully marbled Jack, the tang of golden cheddar, baked until perfectly crispy.  Plus, they're made with 100% real cheese.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% or less of salt, paprika, whey, cheddar cheese (milk, cheese cultures, salt, enzymes), buttermilk, tomato powder, garlic powder, monosodium glutamate, yeast, onion powder, natural and artificial flavors, monterey jack cheese (milk, cheese cultures, salt, enzymes), sugar, malic acid, annatto extract color, disodium inosinate, disodium guanylate, yeast extract, paprika extract color, yellow 5, yellow 6, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [1.02, 12.4],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Whole Grain Snack Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4821772/kicproductimage-96893_kicproductimage-96893.png",
    productDescription: [
      "Cheez-It® Whole Grain crackers are packed with whole grain goodness and 100% real cheese, so you know they're tasty. It's your favorite cheesy, crunchy flavor, now baked with eight grams of whole grains.  The square shape, the rigid edges and that hole in the middle  “ everything about this whole grain baked snack is the real deal.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), whole wheat flour, vegetable oil (soybean and palm oil with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% or less of salt, paprika, yeast, paprika extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [12.4],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Italian Four Cheese Snack Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4821788/kicproductimage-96894_kicproductimage-96894.png",
    productDescription: [
      "We ™re pretty good at what we do. We ™ve flawlessly combined not one, not two, not even three, but four Italian cheeses into our awesomely delicious Cheez-It® Italian Four Cheese Crackers! This snack is baked to cheesy perfection, as always, plus, made with 100% real cheese.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola with TBHQ for freshness), white cheddar cheese (milk, cheese cultures, salt, enzymes).  Contains 2% or less of salt, rice flour, monosodium glutamate, monterey jack, romano, parmesan, mozzarella and asiago cheeses (milk, cheese cultures, salt, enzymes), dextrose, baking soda, yeast, autolyzed yeast extract, garlic powder, lactic acid, onion powder, citric acid, disodium inosinate, disodium guanylate, artificial flavor, turmeric extract color, annatto extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [12.4],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Hot & Spicy Snack Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4821786/kicproductimage-96889_kicproductimage-96889.png",
    productDescription: [
      "Ready to spice up your snack life? Bring some heat to the table with Cheez-It® Hot & Spicy snack crackers. We've taken cheesy perfection and added a dash of spicy kick. Plus, of course, made with 100% real cheese.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% or less of salt, paprika for color, dextrose, yeast, vinegar solids, red pepper, onion powder, tomato powder, paprika, garlic powder, sodium diacetate, green pepper sauce (jalapeno pepper, water, distilled vinegar, salt, corn starch), spices, citric acid, natural and artificial flavors, paprika extract color, disodium inosinate, disodium guanylate, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [7, 12.4],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Reduced Fat Snack Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4509119/prod_img-6661551_prod_img-6661551.png",
    productDescription: [
      "At. Long. Last. Everything you know and love about Cheez-It® Original Baked Snack Crackers with reduced fat.  The square shape, the rigid edges and that hole in the middle  “ everything about this Cheez-It® Reduced Fat snack is the real deal.  Made with 100% real cheese!",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola oil with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% or less of salt, paprika, yeast, paprika extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [6, 11.5, 19],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Buffalo Wing Snack Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_6528915/prod_img-6662507_prod_img-6662507.png",
    productDescription: [
      "Make snack time more fun with Cheez-It® baked snack crackers, featuring the deliciously craveable flavor of buffalo wing. A delectable baked snack made with 100% real cheese that's been carefully aged for a one-of-a-kind taste in every crunchy bite. Cheez-It® Buffalo Wing crackers are perfect for game time, party spreads, school lunches, late-night snacking and more ”the irresistible options are endless. You'll love the bold flavor in every perfectly toasted handful!",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% or less of salt, maltodextrin, paprika for color, sodium diacetate, yeast, natural flavors, monosodium glutamate, garlic powder, sugar, cayenne pepper sauce (aged red peppers, vinegar, salt, garlic), blue cheese (milk, salt, cheese cultures, enzymes), hydrolyzed corn and soy protein, butter (cream, salt), whey, yeast extract, disodium inosinate, disodium guanylate, buttermilk, malic acid, cheddar cheese (milk, salt, cheese cultures, enzymes), paprika extract color, lactic acid, citric acid, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [12.4],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Scooby-Doo Snack Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4821767/prod_img-8178334_prod_img-8178334.png",
    productDescription: [
      "It ™s no mystery that Cheez-It® SCOOBY DOO! Baked Snack Crackers featuring loveable character Scooby-Doo are doggone delicious. Includes 1, 21-ounce box of bite-size Cheez-It® Baked Snack Cheese Crackers. Made with 100% real cheese, imprinted with fun Scooby-inspired images, and bursting with the bold, cheesy flavor the entire family will love. Enjoy them straight from the box while watching SCOOBY DOO! on TV or pack into small snack bags for school lunch, work, sports practice or on-the-go; take a travel-ready box on the road for camping, the park, family picnics, grilling parties, or everyday errands. The cheesy possibilities are endless.The antidote to flavorless crackers, Cheez-It® Baked Snacks also make a great addition to homemade snack mixes and party mixes. You'll love the one-of-a-kind flavor of real cheese in every tasty handful of Cheez-It® SCOOBY DOO! Baked Snack Crackers.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), soybean oil (with TBHQ for freshness), white cheddar cheese (milk, cheese cultures, salt, enzymes).   Contains 2% or less of salt, paprika, yeast, baking soda, paprika extract color, turmeric extract color, annatto extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [21],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Puff'd® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Double Cheese Snacks",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_1380809/prod_img-6831670_optimized_doublecheese.png",
    productDescription: [
      "Taste the fun of puffy and airy, bite-sized squares baked with 100% real cheese inside and out. Includes 1, 5.75-ounce bag of Cheez-It® Puff ™d® Double Cheese Cheesy Baked Snacks. Double cheese means double flavor and double fun. This snack satisfies your senses with an irresistible crunch followed by melt-in-your-mouth cheesiness that kids and adults crave. Baked with 100% real cheese into every puffy cracker for maximum tastiness; Pack Cheez-It® Puff ™d® in your home pantry for an afternoon pick-me-up or after school snack. Great as a bite when hanging out with friends. Stock up on bags of this family favorite snack food for TV watching, gaming, play time, or anytime. Hungry for a snack that won ™t weigh everyone down? Serve up fun with crisp, crunchable, always munchable Cheez-It® Puff ™d® Cheesy Baked Snacks.",
    ],
    productIngredients: [
      "Ingredients: Degerminated yellow corn meal, vegetable oil (soybean, high oleic soybean, canola and/or corn oil with TBHQ for freshness), whey, cheddar cheese (cultured milk, salt, enzymes).  Contains 2% or less of salt, monosodium glutamate, corn syrup solids, sugar, citric acid, oat fiber, low fat cheddar cheese (nonfat milk, cultures, salt, enzymes), lactic acid, yeast extract, paprika extract color, disodium inosinate, disodium guanylate, natural flavor.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [5.75],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Puff'd® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "White Cheddar Snacks",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_1380811/kicproductimage-96902_optimized_white.png",
    productDescription: [
      "Defy your cheezpectations with this puffy and airy craveable snack from Cheez-It.",
    ],
    productIngredients: [
      "Ingredients: Degerminated yellow corn meal, vegetable oil (soybean, high oleic soybean, canola and/or corn oil with TBHQ for freshness), whey, cheddar cheese (cultured milk, salt, enzymes).  Contains 2% or less of salt, lactic acid, monosodium glutamate, yeast extract, onion powder, oat fiber, low fat cheddar cheese (nonfat milk, cultures, salt, enzymes), citric acid, disodium inosinate, disodium guanylate, garlic powder, natural flavor.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [3, 5.75, 9.6],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Puff'd® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Cheddar Jack Snacks",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_2639106/prod_img-2640078_optimized.png",
    productDescription: [
      "Up your snacking game with Cheez-It® Puff ™d®!  The puffy and airy, bite-sized snack baked with 100% real cheese inside and out. Cheez-It® Puff ™d® Cheddar Jack perfectly combines the cheesy, marbled taste of Monterey Jack, with the tang of Cheddar Cheese, and the puffy, airy, craveable squares you know and love.",
    ],
    productIngredients: [
      "Ingredients: Degerminated yellow corn meal, vegetable oil (soybean, high oleic soybean, canola and/or corn with TBHQ for freshness), whey, cheddar cheese (cultured milk, salt, enzymes), salt.  Contains 2% or less of sour cream (cream, nonfat milk, cultures), garlic powder, onion powder, sugar, citric acid, monosodium glutamate, oat fiber, low fat cheddar cheese (nonfat milk, cultures, salt, enzymes), monterey jack cheese (cultured pasteurized milk, salt, enzymes), paprika extract color, natural flavor, disodium inosinate, disodium guanylate, lactic acid.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [5.75],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Snap'd® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Double Cheese Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_10629117/prod_img-883421_snapd-double-cheese.png",
    productDescription: [
      "Thin, crispy, cheesy baked snacks with big cheese taste inside and out.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), soybean oil (with TBHQ for freshness), modified corn starch, dried potatoes, white cheddar cheese (milk, cheese cultures, salt, enzymes). Contains 2% or less of salt, oat fiber, sugar, cheddar cheese (milk, cheese cultures, salt, enzymes), monosodium glutamate, whey, onion powder, sour cream (cream, nonfat milk, cultures), lactic acid, citric acid, buttermilk, dextrose, paprika extract color, annatto extract color, soy lecithin, garlic powder, cultured nonfat milk, disodium inosinate, disodium guanylate, natural flavor, turmeric extract color.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [1.5, 3.6, 7.5, 9, 12],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Snap'd® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Cheddar Sour Cream & Onion Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_6747760/prod_img-6831676_prod_img-6831676.png",
    productDescription: [
      "Cheez-It® Snap'd® Cheddar Sour Cream & Onion Crackers are thin and crispy cheesy baked snacks.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), soybean oil (with TBHQ for freshness), modified corn starch, dried potatoes, white cheddar cheese (milk, cheese cultures, salt, enzymes). Contains 2% or less of salt, oat fiber, sugar, buttermilk, whey, monosodium glutamate, onion powder, sour cream (cream, nonfat milk, cultures), cheddar cheese (milk, salt, cheese cultures, enzymes), natural flavors, cultured nonfat milk, citric acid, paprika extract color, lactic acid, reduced lactose whey, butter (cream), dried parsley, yeast extract, disodium inosinate, disodium guanylate, invert sugar, malic acid, soy lecithin, turmeric extract color, annatto extract color, garlic powder.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [0.75, 3.6, 7.5, 9, 12],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Snap'd® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "JalapeÃ±o Jack Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_10629118/prod_img-6831679_prod_img-6831679.png",
    productDescription: [
      "Cheez-It® Snap'd® JalapeÃ±o Jack Crackers are thin and crispy cheesy baked snacks made with the irresistible flavor combination of 100% real cheese and spicy jalapeÃ±o. Sometimes you need a little kick in the taste buds. That's where we can help.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), soybean oil (with TBHQ for freshness), modified corn starch, dried potatoes, white cheddar cheese (milk, cheese cultures, salt, enzymes).  Contains 2% or less of salt, oat fiber, sugar, monosodium glutamate, cheddar cheese (milk, cheese cultures, salt, enzymes), whey, onion powder, monterey jack cheese (milk, cheese cultures, salt, enzymes), lactic acid, garlic powder, citric acid, green bell pepper powder, buttermilk, dextrose, soy lecithin, jalapeno pepper powder, dried parsley, green chili pepper powder, sodium diacetate, disodium inosinate, disodium guanylate, natural flavors, turmeric extract color, annatto extract color.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [7.5],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Snap'd® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Parmesan Ranch",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_10399497/prod_img-12006716_snapdparm.png",
    productDescription: [
      "Cheez-It® Snap'd® Parmesan Ranch are thin and crispy cheesy baked snacks. A great way to make your lunch more exciting with the powerful flavor of Parmesan met with the zesty Ranch flavor creates the perfect accompaniment to any sandwich!",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), soybean oil (with TBHQ for freshness), dried potatoes, modified corn starch, white cheddar cheese (milk, cheese cultures, salt, enzymes).  Contains 2% or less of salt, oat fiber, sugar, cheddar and parmesan cheese (milk, salt, cultures, enzymes), whey, monosodium glutamate, lactic acid, onion powder, garlic powder, buttermilk, citric acid, soy lecithin, natural flavors, paprika color, parsley, spices, vinegar, milk, cultured buttermilk, milkfat, sour cream (cream, nonfat milk, cultures), turmeric extract color, annatto extract color.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [7.5],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Grooves® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Bold Cheddar Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4821775/prod_img-2933467_boldched.png",
    productDescription: [
      "Cheez-It® Grooves® Bold brings you deep flavor and deep crunch.  We took the 100% real cheese taste of Cheez-It® Original crackers and baked it into gloriously crunchy Cheez-It® Grooves® Bold. Now you can snack on cheesy satisfaction with a huge crunch!",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), rice flour, soybean oil (with TBHQ for freshness), white cheddar cheese (milk, cheese cultures, salt, enzymes).  Contains 2% or less of sugar, salt, soy lecithin, buttermilk, monosodium glutamate, leavening (baking soda, sodium acid pyrophosphate, monocalcium phosphate), onion powder, spices, cheddar cheese (milk, cheese cultures, salt, enzymes), lactic acid, corn syrup solids, citric acid, whey, natural flavors, paprika extract color, turmeric extract color, annatto extract color.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [9],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Grooves® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Sharp White Cheddar Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4509122/prod_img-1406176_whiteched.png",
    productDescription: [
      "Cheez-It® Grooves® bring you deep flavor and deep crunch, with huge white cheddar grooves. Cheez-It® Grooves® Sharp White Cheddar crispy snack crackers are made with 100% real cheese to bring you that irresistibly cheesy, sharp white cheddar flavor!",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), rice flour, soybean oil (with TBHQ for freshness), white cheddar cheese (milk, cheese cultures, salt, enzymes).   Contains 2% or less of sugar, salt, soy lecithin, cheddar cheese (milk, cheese cultures, salt, enzymes), whey, leavening (baking soda, sodium acid pyrophosphate, monocalcium phosphate), natural and artificial flavors, monosodium glutamate, lactic acid, buttermilk, yeast extract, citric acid, disodium inosinate, disodium guanylate, butter (cream, salt).",
    ],
    backendDescirptionforOpenAI: [],
    formats: [6, 9, 17],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Grooves® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Zesty Cheddar Ranch Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4509102/prod_img-1406111_zesty.png",
    productDescription: [
      "Cheez-It® Grooves® brings you deep flavor and deep crunch.  We make our Cheez-It® Grooves® Zesty Cheddar Ranch crackers with 100% real cheese and pack them with zesty cheddar ranch taste! ",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), rice flour, soybean oil (with TBHQ for freshness), white cheddar cheese (milk, cheese cultures, salt, enzymes). Contains 2% or less of sugar, salt, soy lecithin, monosodium glutamate, onion, buttermilk, leavening (baking soda, sodium acid pyrophosphate, monocalcium phosphate), natural flavors, whey, garlic powder, cheddar cheese (milk, salt, cheese cultures, enzymes), reduced lactose whey, yeast extract, tomato powder, parsley, maltodextrin, cornstarch, citric acid, spices, dextrose, bell pepper, lactic acid, butter (cream), paprika extract color, turmeric extract color, annatto extract color.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [6, 9],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Grooves®",
    cardType: "Cheez-It SKU",
    cardSubTitle: " Scorchin' Hot Cheddar Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_9539887/prod_img-10689205_hotched.png",
    productDescription: [
      "Cheez-It® Grooves® Scorchin ™ Hot Cheddar are made with 100% real cheese and pack a blazing hit of extra bold, fiery flavor to every crunchy ridge.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), rice flour, soybean oil (with TBHQ for freshness), white cheddar cheese (milk, cheese cultures, salt, enzymes), sugar.  Contains 2% or less of salt, soy lecithin, whey, monosodium glutamate, leavening (baking soda, sodium acid pyrophosphate, monocalcium phosphate), garlic powder, onion powder, cheddar cheese (cultured milk, salt, enzymes), buttermilk, natural flavors, cornstarch, milkfat, citric acid, yeast extract, nonfat milk, lactic acid, sodium diacetate, disodium inosinate, disodium guanylate, mixed tocopherols for freshness, red 40 lake, yellow 6 lake, yellow 5 lake, turmeric extract color, blue 1 lake, annatto extract color.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [7.5],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It Duoz® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Jalapeno & Cheddar Jack Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4821783/prod_img-6539264_prod_img-6539264.png",
    productDescription: [
      "Every so often you need a little spice in your life! JalapeÃ±o Cheez-It® crackers and Cheddar Jack Cheez-It® crackers, mixed together in one box. Mix up your snack time and by adding a kick! Made with 100% real cheese.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola with TBHQ for freshness), white cheddar cheese (milk, cheese cultures, salt, enzymes), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% or less of salt, maltodextrin, whey, paprika, cheddar cheese (milk, cheese cultures, salt, enzymes), jalapeno pepper, monosodium glutamate, buttermilk, yeast, tomato powder, garlic powder, baking soda, parsley, onion powder, disodium inosinate, disodium guanylate, natural and artificial flavors, annatto extract color, monterey jack cheese (milk, cheese cultures, salt, enzymes), sugar, malic acid, spice extract, yeast extract, paprika extract color, citric acid, yellow 5, yellow 6, turmeric extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [7, 12.4],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It Duoz® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Sharp Cheddar & Parmesan Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4509108/kicproductimage-96904_kicproductimage-96904.png",
    productDescription: [
      "Because two is better than one. Sharp Cheddar gets a little better when you add Parmesan. Cheez-It Duoz® Sharp Cheddar and Parmesan Crackers mixes up Sharp Cheddar and Parmesan crackers creating an irresistibly cheesy duo. Cheesy, crunchy perfection, made with 100% real cheese.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola with TBHQ for freshness), white cheddar cheese (milk, cheese cultures, salt, enzymes), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% or less of salt, whey, paprika, monosodium glutamate, buttermilk, parmesan cheese (milk, cheese cultures, salt, enzymes), cheddar cheese (milk, cheese cultures, salt, enzymes), garlic powder, yeast, tomato powder, sugar, onion powder, baking soda, disodium inosinate, disodium guanylate, natural and artificial flavors, citric acid, dried parsley, spice, annatto extract color, monterey jack cheese (milk, cheese cultures, salt, enzymes), malic acid, spice extract, yeast extract, paprika extract color, yellow 5, yellow 6, turmeric extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [12.4],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It Duoz® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Bacon & Cheddar Crackers",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_5929261/prod_img-6505197_prod_img-6505197.png",
    productDescription: [
      "Bacon and cheddar. It ™s a flavor match made in heaven! Bacon flavor Cheez-It® crackers and Cheddar Cheez-It® crackers, mixed together in one box of cheesy, crunchy perfection. Afterall, everything's better with cheddar...even bacon! Made with 100% real cheese.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola with TBHQ for freshness), white cheddar cheese (milk, cheese cultures, salt, enzymes), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).   Contains 2% or less of salt, maltodextrin, paprika for color, whey, monosodium glutamate, cheddar cheese (milk, cheese cultures, salt, enzymes), yeast, garlic powder, buttermilk, onion powder, yeast extract, vanilla beans, tomato powder, spice, baking soda, natural and artificial flavors (including natural hickory smoke flavor), disodium inosinate, disodium guanylate, annatto extract color, monterey jack cheese (milk, cheese cultures, salt, enzymes), sugar, malic acid, spice extract, paprika extract color, citric acid, yellow 5, yellow 6, turmeric extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [12.4],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Snack Mix",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4509124/kicproductimage-96901_kicproductimage-96901.png",
    productDescription: [
      "A perfectly seasoned snack mix of Cheez-It® crackers made with real cheese, salty pretzels, mini toasted bread slices, savory wheat squares, and cheese-flavored rice balls! Cheez-It® Snack Mix is perfect for after school snacks, birthdays, game days, parties... and any time cheesy satisfaction is on the table.",
    ],
    productIngredients: [
      "Ingredients: Cheese crackers (enriched flour [wheat flour, niacin, reduced iron, vitamin B1 (thiamin mononitrate), vitamin B2 (riboflavin), folic acid], vegetable oil [high oleic soybean, soybean, palm, and/or canola with TBHQ for freshness], cheese made with skim milk [skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color], salt, paprika, yeast, paprika extract color, soy lecithin), pretzels (enriched flour [wheat flour, niacin, reduced iron, vitamin B1 (thiamin mononitrate), vitamin B2 (riboflavin), folic acid], salt, canola and/or sunflower oil, corn syrup, baking soda, barley malt extract, yeast), bread slices (enriched flour [wheat flour, niacin, reduced iron, vitamin B1 (thiamin mononitrate), vitamin B2 (riboflavin), folic acid], canola and/or soybean oil, salt, yeast, sugar, malt, wheat gluten, worcestershire sauce powder [molasses, vinegar, corn syrup, caramel color, garlic, sugar, spices, tamarind, natural flavor, maltodextrin], dextrose, spice, garlic powder, onion powder, baking soda, yeast extract, disodium inosinate, disodium guanylate), cheese flavored rice balls (rice flour, vegetable oil [canola, rice, safflower and/or sunflower], maltodextrin, salt, whey, cheddar cheese [cultured pasteurized milk, salt, enzymes], reduced lactose whey, buttermilk, natural flavor, onion powder, sugar, disodium phosphate, garlic powder, yeast extract, citric acid, blue cheese [milk, cheese cultures, salt, enzymes], lactic acid).  Contains 2% or less of soybean oil with TBHQ for freshness, worcestershire seasoning (monosodium glutamate, salt, corn syrup solids, sugar, maltodextrin, torula yeast, garlic powder, worcestershire sauce [molasses, vinegar, corn syrup, salt, caramel color, garlic, sugar, spices, tamarind, natural flavor], onion powder, molasses, modified food starch, vinegar, wheat starch, caramel color, spice, disodium inosinate, disodium guanylate, soy flour).",
    ],
    backendDescirptionforOpenAI: [],
    formats: [10.4, 40],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Snack Mix Double Cheese",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4821785/kicproductimage-96906_kicproductimage-96906.png",
    productDescription: [
      "A perfectly seasoned snack mix of white cheddar crackers, Cheez-It® Original crackers, salty pretzels, mini toasted bread slices, and crunchy cheese curls. Want to know how we make our Cheez-It® Snack Mix Double Cheese so awesomely full of flavor? Well, we just, add more cheese. That's 100% real cheese.",
    ],
    productIngredients: [
      "Ingredients: Cheese crackers (enriched flour [wheat flour, niacin, reduced iron, vitamin B1 (thiamin mononitrate), vitamin B2 (riboflavin), folic acid], vegetable oil [soybean, palm, high oleic soybean and/or canola oil with TBHQ for freshness], cheese made with skim milk [skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color], white cheddar cheese [milk, cheese cultures, salt, enzymes], salt, whey, cheddar cheese [milk, cheese cultures, salt, enzymes], paprika, monosodium glutamate, yeast, whey protein concentrate, butter [cream, salt], baking soda, natural and artificial flavors, lactic acid, calcium lactate, citric acid, paprika extract color, turmeric extract color, annatto extract color, soy lecithin), pretzels (enriched flour [wheat flour, niacin, reduced iron, vitamin B1 (thiamin mononitrate), vitamin B2 (riboflavin), folic acid], salt, canola and/or sunflower oil, corn syrup, baking soda, barley malt extract, yeast), bread slices (enriched wheat flour [wheat flour, niacin, reduced iron, vitamin B1 (thiamin mononitrate), vitamin B2 (riboflavin), folic acid], high oleic soybean and/or canola oil, yeast, salt, sugar, malt, wheat gluten, baking soda), cheese curls (cornmeal, soybean oil, cheddar cheese seasoning [maltodextrin, whey, cheddar and blue cheese (pasteurized milk, salt, annatto, cheese culture, enzymes), corn starch, salt, monosodium glutamate, butter (cream), whey protein concentrate, artificial flavors, yellow 5, yellow 6, lactic acid], salt, maltodextrin, yellow 6), soybean oil (with TBHQ for freshness).   Contains 2% or less of cheddar cheese (milk, cheese cultures, salt, enzymes), salt, whey, butter (cream, salt), monosodium glutamate, onion powder, buttermilk, garlic powder, nonfat milk, natural and artificial flavors, citric acid, yellow 5, yellow 6, disodium inosinate, disodium guanylate, yeast, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [9.75],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Variety Snack Packs ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "(Cheddar Jack, White Cheddar, Original)",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4509068/prod_img-6841856_prod_img-6841856.png",
    productDescription: [
      "With three flavors to choose from, our cheesy baked crackers are individually packed for convenience and make a delicious anytime snack! Cheez-It® Variety features Cheez-It® Cheddar Jack, Cheez-It® White Cheddar, and the iconic Cheez-It® Original. They're perfect for school lunches, after school snacks, on-the-go bites, or picnics in the park.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [0.38, 1, 22, 12, 20, 30],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Original Snack Packs ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "(12CT, 20CT, 30CT)",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4509071/prod_img-6841850_prod_img-6841850.png",
    productDescription: [
      "Cheesy, crunchy, satisfaction for any occasion! Ideal for an on-the-go bite, brown bag lunch or afternoon break - the delicious cheese options are endless. It might be hard to choose a favorite. Cheez-It® Original Snack Packs are available in various sizes, including 12, 20, 30 count.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola oil with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color).  Contains 2% or less of salt, paprika, yeast, paprika extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [0.38, 1, 22, 12, 20, 30],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Cheese Lovers Variety Packs ",
    cardType: "Cheez-It SKU",
    cardSubTitle:
      "(Original, White Cheddar, Cheez-It® Snap'd® Double Cheese and Cheddar Sour Cream & Onion)",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4509069/prod_img-8476532_prod_img-8476532.png",
    productDescription: [
      "Make snack time more fun with Cheez-It® Cheese Lovers Variety Packs made of 100% real cheese.  Get the classic goodness of Cheez-It® Original baked snack crackers and White Cheddar flavors, and Cheez-It® Snap'd® Double Cheese and Cheddar Sour Cream & Onion flavors! Travel-ready snacks perfect for game time, school lunches, or anything on-the-go.",
    ],
    backendDescirptionforOpenAI: [],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® All Day Variety Packs ",
    cardType: "Cheez-It SKU",
    cardSubTitle:
      "(Cheez-It® Original, Pop-Tarts® Strawberry, Pringles® Cheddar Cheese and Original snacks)",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_6631388/prod_img-9425025_prod_img-9425025.png",
    productDescription: [
      "Snacks to last all day! Cheez-It® All Day Variety Packs features the iconic Cheez-It® Original, Strawberry Pop-Tart®, Pringles® Cheddar Cheese and Pringles® Original snacks individually packed for convenience. They're perfect for school lunches, after school snacks, on-the-go bites, or picnics in the park.",
    ],
    backendDescirptionforOpenAI: [],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Extra Toasty Snack Pack",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_7263611/prod_img-9425021_prod_img-9425021.png",
    productDescription: [
      "You asked. We toasted. After years of fan requests for an extra toasty Cheez-It®, the wait is finally over. So here it is  “ a box of 12 individually wrapped packages of our delicious Cheez-It® Extra Toasty Crackers, and you made it happen.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), vegetable oil (high oleic soybean, soybean, palm, and/or canola oil with TBHQ for freshness), cheese made with skim milk (skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color). Contains 2% or less of salt, dextrose, paprika, yeast, paprika extract color, soy lecithin.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [12],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Snack Mix Snack Pack",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_7263612/prod_img-9425024_prod_img-9425024.png",
    productDescription: [
      "A perfectly seasoned snack mix of Cheez-It® crackers made with real cheese, salty pretzels, mini toasted bread slices, savory wheat squares, and cheese-flavored rice balls! 12 individually wrapped packages of Cheez-It® Snack Mix are perfect for after school snacks, birthdays, game days, parties... and any time cheesy satisfaction is on the table.",
    ],
    productIngredients: [
      "Ingredients: Cheese crackers (enriched flour [wheat flour, niacin, reduced iron, vitamin B1 (thiamin mononitrate), vitamin B2 (riboflavin), folic acid], vegetable oil [high oleic soybean, soybean, palm, and/or canola with TBHQ for freshness], cheese made with skim milk [skim milk, whey protein, salt, cheese cultures, enzymes, annatto extract color], salt, paprika, yeast, paprika extract color, soy lecithin), pretzels (enriched flour [wheat flour, niacin, reduced iron, vitamin B1 (thiamin mononitrate), vitamin B2 (riboflavin), folic acid], salt, canola and/or sunflower oil, corn syrup, baking soda, barley malt extract, yeast), bread slices (enriched flour [wheat flour, niacin, reduced iron, vitamin B1 (thiamin mononitrate), vitamin B2 (riboflavin), folic acid], canola and/or soybean oil, salt, yeast, sugar, malt, wheat gluten, worcestershire sauce powder [molasses, vinegar, corn syrup, caramel color, garlic, sugar, spices, tamarind, natural flavor, maltodextrin], dextrose, spice, garlic powder, onion powder, baking soda, yeast extract, disodium inosinate, disodium guanylate), cheese flavored rice balls (rice flour, vegetable oil [canola, rice, safflower and/or sunflower], maltodextrin, salt, whey, cheddar cheese [cultured pasteurized milk, salt, enzymes], reduced lactose whey, buttermilk, natural flavor, onion powder, sugar, disodium phosphate, garlic powder, yeast extract, citric acid, blue cheese [milk, cheese cultures, salt, enzymes], lactic acid).  Contains 2% or less of soybean oil with TBHQ for freshness, worcestershire seasoning (monosodium glutamate, salt, corn syrup solids, sugar, maltodextrin, torula yeast, garlic powder, worcestershire sauce [molasses, vinegar, corn syrup, salt, caramel color, garlic, sugar, spices, tamarind, natural flavor], onion powder, molasses, modified food starch, vinegar, wheat starch, caramel color, spice, disodium inosinate, disodium guanylate, soy flour).",
    ],
    backendDescirptionforOpenAI: [],
    formats: [9],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Snap'd® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Double Cheese Multi-Pack",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_6745739/prod_img-11360788_prod_img-11360788.png",
    productDescription: [
      "Thin, crispy, cheesy baked snacks with big cheese taste inside and out.",
    ],
    productIngredients: [
      "Ingredients: Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), soybean oil (with TBHQ for freshness), modified corn starch, dried potatoes, white cheddar cheese (milk, cheese cultures, salt, enzymes). Contains 2% or less of salt, oat fiber, sugar, cheddar cheese (milk, cheese cultures, salt, enzymes), monosodium glutamate, whey, onion powder, sour cream (cream, nonfat milk, cultures), lactic acid, citric acid, buttermilk, dextrose, paprika extract color, annatto extract color, soy lecithin, garlic powder, cultured nonfat milk, disodium inosinate, disodium guanylate, natural flavor, turmeric extract color.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [0.75, 9],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Snap'd® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Snack Variety Pack",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_4509075/prod_img-11367968_prod_img-11367968.jpg",
    productDescription: [
      "Packs of thin and crispy cheesy baked snacks. Makes a tasty bite at home, on the go, or as a side to your sandwich. Turn up the cheese volume with the satisfying crunch of Cheez-It® Snap'd® Variety Pack!",
    ],
    backendDescirptionforOpenAI: [],
    formats: [9],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Snap'd® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Sour Cream & Onion Multi-Pack",
    imageUrl:
      "https://images.kglobalservices.com/www.cheezit.com/en_us/product/product_9299059/prod_img-11360585_prod_img-11360585.png",
    productDescription: [
      "Cheez-It® Snap'd® Cheddar Sour Cream & Onion are thin and crispy cheesy baked snacks.",
    ],
    productIngredients: [
      "Enriched flour (wheat flour, niacin, reduced iron, vitamin B1 [thiamin mononitrate], vitamin B2 [riboflavin], folic acid), soybean oil (with TBHQ for freshness), modified corn starch, dried potatoes, white cheddar cheese (milk, cheese cultures, salt, enzymes). Contains 2% or less of salt, oat fiber, sugar, buttermilk, whey, monosodium glutamate, onion powder, sour cream (cream, nonfat milk, cultures), cheddar cheese (milk, salt, cheese cultures, enzymes), natural flavors, cultured nonfat milk, citric acid, paprika extract color, lactic acid, reduced lactose whey, butter (cream), dried parsley, yeast extract, disodium inosinate, disodium guanylate, invert sugar, malic acid, soy lecithin, turmeric extract color, annatto extract color, garlic powder.",
    ],
    backendDescirptionforOpenAI: [],
    formats: [9],
    countrySalesMillions: [0],
    countryPresence: ["USA"],
  },
  {
    cardTitle: "Cheez-It® Gridz® ",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Future Brand Innovation ( USA 2026+)",
    imageUrl: gridsImage,
    productDescription: [
      "Cheez-It Bigger and Bolder. And unexpected multi-sensorial experience which challenges what people think a Cheez-it can do",
    ],
    backendDescirptionforOpenAI: [],
    countrySalesMillions: [0],
    countryPresence: ["USA '26+"],
  },
  {
    cardTitle: "Cheez-It® Snap'd® Roll'd",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Future Brand Innovation (USA 2026+)",
    imageUrl: rolldImage,
    productDescription: [
      "Cheez-It Snapp'd in a different, rolled shape. Rediculous crunchy, Rediculous cheesy.",
    ],
    backendDescirptionforOpenAI: [],
    countrySalesMillions: [0],
    countryPresence: ["USA '25+"],
  },
  {
    cardTitle: "Cheez-It® Hidden Valley Ranch Partnership",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Future Brand Innovation (USA 2024+)",
    imageUrl: ranchColabImage,
    productDescription: [
      "Hidden Valley Ranch Partnership with cheese & ranch flavour combinations, joint display w. Cheez-It Original at WM, cross coupon support, Food & Lifestyle Influencer paid support, and Pre-built shippers & in-store display",
    ],
    backendDescirptionforOpenAI: [],
    countrySalesMillions: [0],
    countryPresence: ["USA '24+"],
  },
  {
    cardTitle: "Cheez-It® SeasonalPrints",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Future Brand Innovation (USA 2024+)",
    imageUrl: seasonalPrintImage,
    productDescription: [
      "21oz Sharing Size, with Unique Size/Price Point and displayed in Seasonal Aisle (non-compete with every day SKUs)",
    ],
    backendDescirptionforOpenAI: [],
    countrySalesMillions: [0],
    countryPresence: ["USA '24+"],
  },
  {
    cardTitle: "Cheez-It® Snap'd® Extra Crunchy Sharp White Cheddar",
    cardType: "Cheez-It SKU",
    cardSubTitle: "Future Brand Innovation (USA 2024+)",
    imageUrl: extraCrunchyImage,
    productDescription: [
      "Drive news on Snap’d as we sunset free-lunch Flavor Program with a focus on both Flavor AND Texture.",
      " ",
      "Capitalize on Snap’d positioning between cracker & chip to deliver unique “Hard-Crunch” proposition identified as opportunity within Crispy-crunchy Hunting Ground",
    ],
    backendDescirptionforOpenAI: [],
    countrySalesMillions: [0],
    countryPresence: ["USA '24+"],
  },
];

export { cheezItSKUs, cheezItSKUsIntro };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const MasonryLayout = ({ children, gap }) => {
  const [columnWrapper, setColumnWrapper] = useState({});
  const [result, setResult] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      console.log("handleResize", window.innerWidth);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = windowWidth < 900 ? 1 : 3;

  useEffect(() => {
    const newColumnWrapper = {};
    for (let i = 0; i < columns; i++) {
      newColumnWrapper[`column${i}`] = [];
    }
    for (let i = 0; i < children.length; i++) {
      const columnIndex = i % columns;
      newColumnWrapper[`column${columnIndex}`].push(
        <div key={`column${columnIndex}-item${i}`}>{children[i]}</div>
      );
    }
    setColumnWrapper(newColumnWrapper);
  }, [children]);

  useEffect(() => {
    const newResult = [];
    for (let i = 0; i < columns; i++) {
      newResult.push(
        <div
          key={`column${i}`}
          style={{
            marginLeft: i > 0 ? gap : 0,
            flex: 1,
          }}
        >
          {columnWrapper[`column${i}`]}
        </div>
      );
    }
    setResult(newResult);
  }, [columnWrapper]);

  return <div style={{ display: "flex" }}>{result}</div>;
};

MasonryLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  gap: PropTypes.number,
};

MasonryLayout.defaultProps = {
  gap: 20,
};

export default MasonryLayout;

import React, { useEffect } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faLinkedin,
  faMail,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const today = new Date();

const Footer = ({ backgroundColor }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const anchor = document.querySelector(location.hash);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div className='Footer' style={{ backgroundColor: backgroundColor }}>
      <div className='Footer-content'>
        <div className='Footer-content-description'>
          <h1 className='footerTitle'>about HowToFly</h1>
          <p>
            In an era where technology and creativity is rapidly converging,
            HowToFly is on a mission to unlock the full potential of human
            creativity and ingenuity by augmenting it with generative AI. We
            want to anable a future where human creativity knows no limits.
          </p>
        </div>
        <div className='Footer-content-links'>
          <h1 className='footerTitle'>SITE LINKS</h1>
          <Link to={{ pathname: "/", hash: "#approach" }}>APPROACH</Link>
          <Link to={{ pathname: "/", hash: "#experiments" }}>EXPERIMENTS</Link>
          <Link to={{ pathname: "/", hash: "#HowWeHelp" }}>WHY US</Link>
        </div>
        <div className='Footer-content-logins'>
          <h1 className='footerTitle' href='mailto:gilles@howtofly.be'>
            CONTACT US
          </h1>
          <a className='loginButton' href='mailto:gilles@howtofly.be'>
            MAIL
          </a>
          <a href='https://calendly.com/howtofly' className='appointmentButton'>
            APPOINTMENT
          </a>
        </div>
      </div>
      <hr className='footerDeviderLine' />
      <div className='Footer-copyrightAndSocials'>
        <div className='Footer-copyrightAndSocials-copyright'>
          <p className='copyright-text'>
            Copyright &copy; {today.getFullYear()} All Rights Reserved by
            HowToFly
          </p>
        </div>
        <div className='Footer-copyrightAndSocials-socials'>
          <a href='https://instagram.com/howtofly.be/'>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href='https://www.linkedin.com/company/howtofly'>
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href='mailto:gilles@howtofly.be'>
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

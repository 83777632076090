import React, { useEffect } from 'react';
import './BlackboxDashboard.css';

const BlackboxDashboard = () => {
  const targetAudiences = [
    { name: 'Audience 1', facts: 5, finction: 3, solutions: 12, hypotheses: 11, ongoing: 5, completed: 3 },
    { name: 'Audience 2', facts: 8, fiction: 5, solutions: 8, hypotheses: 7, ongoing: 4, completed: 3 },
    { name: 'Audience 3', facts: 15, fiction: 13, solutions: 15, hypotheses: 11, ongoing: 5, completed: 8 },
    { name: 'Audience 4', facts: 9, fiction: 13, solutions: 11, hypotheses: 18, ongoing: 5, completed: 3 },
    { name: 'Audience 5', facts: 3, fiction: 3, solutions: 10, hypotheses: 13, ongoing: 6, completed: 2 },
  ];

  const chartConfigs = [
    { 
      title: 'Facts and Believes', 
      columnLabels: ['Challenge', 'Facts', 'Fiction'], 
      dataRow: (challenge) => [challenge.name, challenge.facts, challenge.fiction] 
    },
    { 
      title: 'Solutions', 
      columnLabels: ['Challenge', 'Solutions'], 
      dataRow: (challenge) => [challenge.name, challenge.solutions] 
    },
    { 
      title: 'Hypotheses', 
      columnLabels: ['Challenge', 'Hypotheses'], 
      dataRow: (challenge) => [challenge.name, challenge.hypotheses] 
    },
    { 
      title: 'Experiments', 
      columnLabels: ['Challenge', 'Ongoing', 'Completed'], 
      dataRow: (challenge) => [challenge.name, challenge.ongoing, challenge.completed] 
    }
  ];

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.gstatic.com/charts/loader.js";
    script.onload = () => {
      // Google Charts loaded, draw chart
      window.google.charts.load('current', {'packages':['corechart']});
      window.google.charts.setOnLoadCallback(drawChart);
    }
    document.body.appendChild(script);

    function drawChart() {
      const options = {
        backgroundColor: 'rgb(41, 41, 51)',
        legend: { position: 'bottom', textStyle: { color: 'white' } },
        hAxis: { textStyle: { color: 'white' } },
        vAxis: { title: 'Total Amount', textStyle: { color: 'white' }, titleTextStyle: { color: 'white' } },
        isStacked: true,
      };

      chartConfigs.forEach((config, index) => {
        const dataRows = targetAudiences.map(challenge => config.dataRow(challenge));
        const chartData = drawChartFor(config.title, config.columnLabels, dataRows, `chart_div${index}`, options);
        addSelectEventListener(chartData);
      });
    }

    function drawChartFor(title, columnLabels, dataRows, elementId, options) {
      const data = new window.google.visualization.DataTable();
      data.addColumn('string', columnLabels.shift()); // Add 'Challenge' column as 'string'
      columnLabels.forEach(label => data.addColumn('number', label)); // Add remaining columns as 'number'
      data.addRows(dataRows);
      const chartElement = document.getElementById(elementId);
      const chart = new window.google.visualization.ColumnChart(chartElement);
      chart.draw(data, options);
      return { chart, data }; // return data along with the chart
    }

    function addSelectEventListener(chartData) {
      const { chart, data } = chartData;
      window.google.visualization.events.addListener(chart, 'select', () => {
        const selection = chart.getSelection();
        if (selection.length) {
          const { row, column } = selection[0];
          const challenge = data.getValue(row, 0);
          const type = data.getColumnLabel(column);
          const value = data.getValue(row, column);
          alert(`Under construction: here we will add a description of ${challenge}`);
        }
      });
    }

  }, []);

  return (
    <div className="BlackboxDashboard">
      <h1 style={{fontWeight:"bold"}}>Activity Dashboard</h1>
      <div className="dashboard-container">
        {chartConfigs.map((config, index) => (
          <div className="tile" key={index}>
            <h2 style={{fontSize: '16px', fontWeight:'bold'}}>{config.title} by Target Audience</h2>
            <div className="chart-container" id={`chart_div${index}`}></div>
          </div>
        ))}
        {/* other charts */}
      </div>
    </div>
  );
};

export default BlackboxDashboard;

import React , {useState} from 'react';
import {  Card, Button, Container, Row, Col, Dropdown, DropdownButton  } from 'react-bootstrap';
import NavbarWebTop from '../components/NavbarWebTop';
import './HowToPage.css';
import Modal from 'react-modal';
import { saveAs } from 'file-saver';

// Tutorial Images ///////
import HowToPrototypeTeaserVideo from '../assets/webp/HowToPrototypeAIEnhancedTeaserVideo.webp';
import HowToPringlesCan from '../assets/webp/HowToMockUpAPringlesCan.webp';
import HowToBeveragCan from '../assets/webp/HowToCreateAsimpleWebsite.webp';
import HowToCustomizeLLMS from '../assets/webp/HowToCustomizeLLMS.webp';
import HowToRun5DaySprintWorkshops from '../assets/webp/HowToRun5DaySprintWorkshops.webp';
import HowToRunABExperiments from '../assets/webp/HowToRunAB-Experiments.webp';
import HowToRunAProblemTreeAnalysis from '../assets/webp/HowToRunAProblemTreeAnalysis.webp';
import HowToRunIdeaRoulette from '../assets/webp/HowToRunIdeaRoulette.webp';
import HowToTrainACustomAIGenerator from '../assets/webp/HowToTrainACustomAIGenerator.webp';
import HowToGetFundingForYourIdea from '../assets/webp/HowToGetFundingForYourIdea.webp';

// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faPersonDigging , faHammer, faFlaskVial, faRocket, faBrain, faClock, faUsers } from '@fortawesome/free-solid-svg-icons';


//import firebase database
import {db} from '../firebase/config';

// Dummy tutorials data
const tutorials = [
  { id: 1, type: 'Build your vision', typeIcon: faHammer, title: 'How to create an AI-enhanced teaser movie', image: HowToPrototypeTeaserVideo, launched: true },
  { id: 2, type: 'Build your vision', typeIcon: faHammer, title: 'How to create a realistic mock-up of a beverage can', image: HowToBeveragCan, launched: false },
  { id: 3, type: 'Identify needs & solutions', typeIcon: faLightbulb, title: 'How to customize a large language model for your brand', image: HowToCustomizeLLMS, launched: false },
  { id: 4, type: 'Identify needs & solutions', typeIcon: faLightbulb, title: 'How to run a 5-day design sprint workshop', image: HowToRun5DaySprintWorkshops, launched: false },
  { id: 5, type: 'Test your vision', typeIcon: faFlaskVial, title: 'How to run disciplined A/B experiments', image: HowToRunABExperiments, launched: false },
  { id: 6, type: 'Identify needs & solutions', typeIcon: faLightbulb, title: 'How to run a problem tree analysis', image: HowToRunAProblemTreeAnalysis, launched: false },
  { id: 7, type: 'Launch your vision', typeIcon: faRocket, title: 'How to get funding for your idea', image: HowToGetFundingForYourIdea, launched: false },
  { id: 8, type: 'Build your vision', typeIcon: faLightbulb, title: 'How to train a custom AI image generator', image: HowToTrainACustomAIGenerator, launched: false },
  // ... continue this pattern for all 9 tutorials
];

// This line is needed for accessibility purposes.
Modal.setAppElement('#root'); 

function HowToPage() {

    const [modalIsOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [filter, setFilter] = useState('All');
    const [tutorial, setTutorial] = useState('');
  
    function openModal(e) {
        setTutorial(e.target.name);
        setIsOpen(true);
    }
  
    function closeModal() {
      setIsOpen(false);
    }
  
    function handleDownload() {
      const url = 'https://firebasestorage.googleapis.com/v0/b/howtofly-a47a3.appspot.com/o/HowToFly_HowToMockUpABreakingNewsVideo.pdf?alt=media&token=e21ecca7-789e-45c3-a213-fd41cb93beff'; // Replace with your pdf link
      saveAs(url, 'tutorial.pdf');
    }
  
    function handleEmailChange(e) {
      setEmail(e.target.value);
    }

    const uploadToFirestore = async (email,tutorial) => {
        try {
          await db.collection('tutorialRequests').add({
            email,
            tutorial,
            timestamp: Date()
          });
          console.log('Data successfully uploaded to firestore!');
        } catch (error) {
          console.error('Something went wrong, when trying to upload the data to firestore', error);
        }
      };
  
   
    const handleEmailSubmit = async (e) => {
      e.preventDefault();
      console.log(email, tutorial); // Here, handle the email submitted. Send it to your API or save it in your state.
      await uploadToFirestore(email,tutorial);
      setEmail('');
      closeModal();
    }

    // Handle filter selection
    const handleFilterChange = (filterType) => {
        setFilter(filterType);
    };

    // Filter tutorials
    const filteredTutorials = filter === 'All' ? tutorials : tutorials.filter(tutorial => tutorial.type === filter);

    // Get unique tutorial types
    const tutorialTypes = ['All', ...new Set(tutorials.map(tutorial => tutorial.type))];

  return (
    <div className="HowToPage">
        <nav className='HowToPage-navbar'>
            <NavbarWebTop />
        </nav>
        <Container className="d-flex justify-content-center mt-5 HowToPage-content">
            <div style={{width: '100%'}}>
                <Row className="HowToPage-introductionRow">
                    <Row>
                        <Col>
                            <h1 className="mb-4 HowToPage-content-title">How To Tutorials</h1>
                        </Col>
                        <Col>
                            <DropdownButton className="dropdown-button" id="dropdown-basic-button" title={filter} onSelect={handleFilterChange}>
                            {tutorialTypes.map(type => (
                                <Dropdown.Item eventKey={type} key={type}>{type}</Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </Col>
                    </Row>
                    <p className="HowToPage-content-introductionText">With our "How To" tutorials, our aim is to empower entrepreneurs, innovators, visionaries, and dreamers of all types to quickly identify, build, test, and launch innovative ideas without spending a fortune.</p>
                </Row>
                <Row xs={1} md={4} className="g-4">
                {filteredTutorials.map((tutorial) => (
                    <Col key={tutorial.id}>
                        <Card>
                            <Card.Header className="HowToPage-cardHeader">
                                <Card.Text>
                                    <FontAwesomeIcon icon={tutorial.typeIcon} className="typeIcon" />
                                    {tutorial.type}
                                </Card.Text>
                            </Card.Header>
                            <Card.Img variant="middle" src={tutorial.image} style={{aspectRatio: '1'}} alt={tutorial.title} />
                            <Card.Body>
                                {tutorial.launched ? (
                                <Button variant="primary" style={{background:'black', borderColor:"black"}} onClick={handleDownload}>Download</Button>
                                ) : (
                                <Button variant="secondary" name={tutorial.title} onClick={openModal}>Coming Soon</Button>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
                </Row>
            </div>
        </Container>
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Enter Email Modal"
        className="ModalTutorialUnderConstruction"
        style={{
            content: {
             boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
        <h1 className="modalConstructionIcon"><FontAwesomeIcon icon={faPersonDigging} /></h1>
        <h2 className="modalText">Sorry, this tutorial is still under construction<br/>Enter your email to get notified when the tutorial launches</h2>
        <form className="modalForm" onSubmit={handleEmailSubmit}>
            <input type="email" className="modalFormInput" value={email} onChange={handleEmailChange} placeholder="Email" required />
            <Button className="modalSubmitButton" style={{background:"black", borderColor:"black"}} type="submit">Submit</Button>
        </form>
    </Modal>
    </div>
  );
}

export default HowToPage;
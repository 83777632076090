import React from "react";

import { db } from "../../firebase/config.js";
import { competitorSKUs } from "./competitorSKUs.js";

export default function uploadDataToFirebase() {
  async function uploadData() {
    const collectionRef = db.collection("ciCompetitorSkus");

    for (const sku of competitorSKUs) {
      await collectionRef.add(sku);
    }

    console.log("Data uploaded successfully!");
  }

  uploadData().catch((error) => {
    console.error("Error uploading data:", error);
  });
  return (
    <div>
      <button onClick={uploadData}>upload</button>
    </div>
  );
}

import React, { useState } from "react";

// import css style sheets
import "./BlackboxCIKnowledgeBaseCheezItSKUs.css";

const packFormats = ["1.5", "3", "7", "12", "12.4", "18", "21"];
const countryOptions = [
  {
    country: "USA",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_the_United_States_%28DoS_ECA_Color_Standard%29.svg",
  },
  {
    country: "Canada",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/1200px-Flag_of_Canada_%28Pantone%29.svg.png",
  },
  {
    country: "LATAM",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/False_flag_of_Latin_America.jpg/320px-False_flag_of_Latin_America.jpg",
  },
];

export default function BlackboxCIKnowledgeBaseRetailerItem({
  chosenKnowledgeBaseItem,
}) {
  return (
    <div className='BlackboxCIKnowledgeBaseItem'>
      <h5 className='BlackboxCIKnowledgeBaseItem-title'>
        {chosenKnowledgeBaseItem.cardTitle} |{" "}
        {chosenKnowledgeBaseItem.cardSubTitle}
      </h5>
      <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow'>
        <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn'>
          <img
            src={chosenKnowledgeBaseItem.imageUrl}
            alt={`image of ${chosenKnowledgeBaseItem.cardTitle}`}
            className='BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn-image cheezItSKU'
          />
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn'>
          <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-title'>
            Global Presence (NSV):
          </div>
          {countryOptions.map((country, index) => (
            <div
              key={index}
              className={
                chosenKnowledgeBaseItem.countryPresence.includes(
                  country.country
                )
                  ? "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item countryTiles"
                  : "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item countryTiles inactive"
              }
            >
              <img
                src={country.flag}
                alt={`flag of ${country.country}`}
                className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-flag'
              />
              <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-country'>
                {country.country} (
                {chosenKnowledgeBaseItem.countrySalesMillions[index]} $M)
              </div>
            </div>
          ))}
        </div>
      </div>
      {chosenKnowledgeBaseItem.formats && (
        <div className='BlackboxCIKnowledgeBaseItem-packFormatRow'>
          {chosenKnowledgeBaseItem.formats.map(
            (packFormat, packFormatIndex) => (
              <div
                className='BlackboxCIKnowledgeBaseItem-packFormatRow-packFormatCard'
                key={packFormatIndex}
              >
                <div className='packSize'>{packFormat}</div>
                <div className='ozText'>oz</div>
              </div>
            )
          )}
        </div>
      )}
      <div className='BlackboxCIKnowledgeBaseItem-infoCard productDescription'>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-title'>
          Product Description
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-content productDescription'>
          {chosenKnowledgeBaseItem.productDescription.map(
            (description, index) => (
              <div key={index}>{description}</div>
            )
          )}
        </div>
      </div>
      {chosenKnowledgeBaseItem.productIngredients && (
        <div className='BlackboxCIKnowledgeBaseItem-infoCard productIngredients'>
          <div className='BlackboxCIKnowledgeBaseItem-infoCard-title'>
            Product Ingredients
          </div>
          <div className='BlackboxCIKnowledgeBaseItem-infoCard-content productIngredients'>
            {chosenKnowledgeBaseItem.productIngredients.map(
              (ingredient, index) => (
                <div key={index}>{ingredient}</div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
}

// import image assets

import retailer1 from "../../assets/webpKB/retailer1.webp";
import retailer2 from "../../assets/webpKB/retailer2.webp";
import retailer3 from "../../assets/webpKB/retailer3.webp";
import retailer4 from "../../assets/webpKB/retailer4.webp";
import retailer5 from "../../assets/webpKB/retailer5.webp";
import retailer6 from "../../assets/webpKB/retailer6.webp";
import retailer7 from "../../assets/webpKB/retailer7.webp";
import retailer8 from "../../assets/webpKB/retailer8.webp";
import retailer9 from "../../assets/webpKB/retailer9.webp";
import retailer10 from "../../assets/webpKB/retailer10.webp";

const retailerProfilesIntro = {
  title: "Grocery & Away-From-Home Channel Profiles",
  description: [
    "If we want to create added value for grocery retailers and food wholesalers, we have to understand their ambition. Take a look at the biggest retailers and wholesalers in our major target countries and try to identify how our Cheez-It brand can create added value to help them achieve their ambitions.",
  ],
};

const retailerProfiles = [
  {
    cardTitle: "Tesco (UK)",
    cardType: "Retailer Profile",
    cardSubTitle: "Hyper, Super, Express, Metro, Extra, One Stop",
    imageUrl: retailer1,
    country: "UK",
    totalCountryRevenueInBillion: 58.1,
    numberOfCountryStores: 3.961,
    retailerType: [
      { Hyper: true },
      { Super: true },
      { Discount: false },
      { Convenience: true },
      { Online: true },
      { Wholesale: false },
    ],
    description: [
      "Tesco's USP is focused on providing value to customers through low prices and a wide range of products. They also emphasize their commitment to sustainability and reducing food waste.",
    ],
    companyMotto: [
      `"Every little helps: Serving our customers, communities and planet a little better every day"`,
    ],
    mainTargetCustomers: ["Budget conscious shoppers", "Families"],
    strategicPriorities: [
      {
        title: "1. Magnetic Value for Customers",
        description: [
          "Even in challenging times such as now, Tesco marks itself as a competitor to discounters Aldi and Lidl by consistently promising good value compared to the rest of the market.",
          "Improve quality perceptions, particularly in fresh food, and by renewing the focus on premium products and innovating at pace with supplier partners.",
          "Tesco will clamp down on brands that are not delivering on its sustainability agenda, having recently asked suppliers to move faster in scrapping plastic and using recyclables. The retailer also reminded suppliers of its right to delist SKUs failing to comply with its ESG framework.",
        ],
      },
      {
        title: "2. I Love My Clubcard",
        description: [
          "Clubcard pricing has been a game-changer in UK grocery retail. All Tesco promotions are now on Clubcard and the price campaign has cemented Tesco as the industry leader in loyalty promotions.",
          "Clubcard is also part of a wider pricing strategy as Tesco also continues to elevate Aldi Price Match alongside pushing Low Everyday Prices (LEP) across channels. Tesco’s renovated price campaign further demonstrates its intentions to lead the market on price and compete aggressively with the discounters.",
        ],
      },
      {
        title: "3. Easily The Most Convenient",
        description: [
          "Tesco continues to focus on serving customers whenever and wherever by pursuing emerging channel growth and expanding the physical network. This includes investing in emerging online channels like quick-commerce.",
          "Outside of Clubcard pricing, Tesco continue to elevate the digital offer to benefit both customers and suppliers. Tesco launched a ‘Media & Insight’ division powered by dunnhumby (Tesco’s data-science business), its customer data science partner. The aim of the new platform is to help suppliers and agencies engage more effectively with Tesco customers.",
        ],
      },
    ],
    EuropeanPresenceBeyondCountry: [
      "Ireland",
      "Slovakia",
      "Hungary",
      "Czechia",
    ],
    sources: [
      { link: "IGD 2023 : Retailer Profiles EU sales", url: "" },
      {
        link: 'Statista, "Grocery market share in Great Britain 2017-2023"',
        url: "",
      },
      {
        link: 'Retail Economics, "Top 10 UK Food, Grocery & Supermarket Retailers"',
        url: "",
      },
      {
        link: 'ESM Magazine, "Top 10 Supermarket Retail Chains In The UK"',
        url: "",
      },
      {
        link: 'Wikipedia, "List of supermarket chains in the United Kingdom"',
        url: "",
      },
      {
        link: `LinkedIn, "What Does 'USP' Mean? Does Every Business Need One?"`,
        url: "",
      },
      {
        link: `Shopify, "Win Sales With a Unique Selling Proposition +10 Examples"`,
        url: "",
      },
      {
        link: `Constant Contact, "What is a Unique Selling Proposition?"`,
        url: "",
      },
      {
        link: `New Breed Marketing, "How to Define Your Unique Selling Proposition (USP)"`,
        url: "",
      },
      {
        link: `MarketSplash, "Unique Selling Proposition: The What, The Why & The How"`,
        url: "",
      },
    ],
  },
  {
    cardTitle: "Sainsbury's (UK)",
    cardType: "Retailer Profile",
    cardSubTitle: "Super, Local, Online",
    imageUrl: retailer2,
    country: "UK",
    totalCountryRevenueInBillion: 28.8,
    numberOfCountryStores: 1400,
    retailerType: [
      { Hyper: false },
      { Super: true },
      { Discount: false },
      { Convenience: true },
      { Online: true },
      { Wholesale: false },
    ],
    description: [
      "Sainsbury's is a leading UK supermarket chain that focuses on providing high-quality products and services to its customers.",
    ],
    companyMotto: ["Live Well For Less"],
    mainTargetCustomers: ["Families", "Health-conscious shoppers"],
    strategicPriorities: [
      {
        title: "1. Food First",
        description: [
          "Accelerating the pace of innovation: new product launches will be tripled and brought to market 30% faster. Priority will be for premium, organic and healthier lines.",
          "Expand physical points of distribution: Neighborhood and convenience store openings will double to 25-30 per year focused on residential areas.",
          "Deliver ‘Plan for Better’ commitment by hitting targets for carbon emissions, healthy and sustainable diets, reducing food waste and plastics.",
          "Value is an important element is their food first strategy with the intention to go with personalized and targeted promotions. However, as inflation continues to rise, long-term value will remain an issue if Sainsbury's is to compete with Tesco and the discounters.",
        ],
      },
      {
        title: "2. Elevating Omnichannel",
        description: [
          "Grow online capacity and extend routes to market: extra capacity will be supported by increased efficiency, dynamic pricing, extended delivery slot windows more click & collect and partnerships with quick-commerce providers.",
          "Sainsbury's continues to expand its on-demand network with Deliveroo, Uber Eats and through its own Chop Chop service. Sainsbury’s partnership with Deliveroo has reached 336 stores as the retailer continues to expand its on-demand and Q-commerce reach to be able to offer more products to customers where and when they need them.",
        ],
      },
      {
        title: "3. Managing Inflation",
        description: [
          "Sainsbury’s is dedicated to support its customers’ purchasing power in this difficult inflationary times through different programs like Price Lock, which includes a package of everyday products with held prices for a specific period, and Sainsbury’s Quality Aldi Price Match both in-store and online. ",
          "In line with their core strategy, Private Label will also take more of a leading role in communicating value as consumers look to switch from brands to save money because of their decreased purchasing power.",
        ],
      },
    ],
    EuropeanPresenceBeyondCountry: ["No shops in Europe outside of UK"],
    sources: [
      { link: "IGD 2023 : Retailer Profiles EU sales", url: "" },
      {
        link: 'Statista, "Grocery market share in Great Britain 2017-2023"',
        url: "",
      },
      {
        link: 'Retail Economics, "Top 10 UK Food, Grocery & Supermarket Retailers"',
        url: "",
      },
      {
        link: 'ESM Magazine, "Top 10 Supermarket Retail Chains In The UK"',
        url: "",
      },
      {
        link: 'Wikipedia, "List of supermarket chains in the United Kingdom"',
        url: "",
      },
      {
        link: `Sainsbury's, "Our Sustainability Plan"`,
        url: "",
      },
      {
        link: `Sainsbury's, "Annual Report and Financial Statements 2022"`,
        url: "",
      },
    ],
  },
  {
    cardTitle: "Rewe (Germany)",
    cardType: "Retailer Profile",
    cardSubTitle: "Super, Discount, Convenience",
    imageUrl: retailer3,
    country: "Germany",
    totalCountryRevenueInBillion: 75.9,
    numberOfCountryStores: 3700,
    retailerType: [
      { Hyper: false },
      { Super: true },
      { Discount: true },
      { Convenience: true },
      { Online: true },
      { Wholesale: false },
    ],
    description: [
      "Rewe is one of Germany's leading food retail companies with over 3,700 stores across the country. The company operates supermarkets, consumer stores, specialist stores, discount stores, and drug stores.Rewe's business model is based on quality and great service, as well as price.",
      "The business is split between full range and discount stores. The full range business focuses on supermarkets, but also includes convenience stores and hypermarkets. Banners include Rewe and Nahkauf in Germany, Billa and Adeg in Austria. All discount stores, in Germany and across Europe, operate under the Penny fascia.",
    ],
    companyMotto: [
      "“Besser leben“",
      "“To achieve the best performance – for customers, retailers, and employees”",
    ],
    mainTargetCustomers: ["Families", "Health-conscious shoppers"],
    strategicPriorities: [
      {
        title: "1. Optimizing Convenience Channel",
        description: [
          "REWE’s new Convenience business division headed by Lekkerland bundles all units serving on-the-go consumption.",
          "A new REWE express trial is now underway with EG Group, hinting at a new strategy being sought. Meanwhile, a new avenue for convenience is rail locations, with a trial opened at Dresden in collaboration with travel food specialist SSP. In addition capex for FY2022 is set at EUR2.3 billion, on a par with last year. Much of this is predicted to be funnelled towards the Lekkerland division",
        ],
      },
      {
        title: "2. Expanding Private Label Ranges and Focus on Penny",
        description: [
          "Rewe is continuing to overhaul Penny's private label ranges and rolling out the Penny-to-go range further.",
          "As inflation has risen, discounters have been gaining additional shoppers in search of bargains. The head of Penny, Stefan Magel, stated that Penny will make concessions on margin to share cost increases with customers and manufacturers and is prepared for a drop in sales.",
        ],
      },
      {
        title: "3. E-commerce & Digitalization",
        description: [
          "REWE’s aim is to offer the best online shopping experience via tailor-made offers and attractive digital features for easy and quick shopping from start to finish. REWE is building a robust infrastructure which includes eGrocery hubs and pickup points. REWE says it achieved total ecommerce sales of ~EUR1 billion last in 2021 from all operations at home and abroad.",
          "In June 2021, Rewe partnered with – and took a minority stake (7%) in - Q-commerce startup Flink. The alliance is increasing its visibility in the retailer’s everyday business. Numerous REWE private label SKUs are now offered via the Flink app, specifically organic lines and items from the ‘Beste Wahl’ convenience line. Flink sells REWE brands at prices identical to those of its online supermarket.",
        ],
      },
    ],
    EuropeanPresenceBeyondCountry: [
      "Austria",
      "Netherlands",
      "Czech Republic",
      "Belgium",
      "Romania",
      "Spain",
    ],
    brandsPerType: [
      { SuperStores: ["REWE", "REWE Center", "TEMMA"] },
      { Discount: ["Penny"] },
      {
        ConvenienceStores: [
          "REWE CITY",
          "REWE To Go",
          "nahkauf",
          "Billa",
          "Adeg",
        ],
      },
    ],
    sources: [
      { link: "IGD November 2022 : Retailer Profiles EU sales", url: "" },
      {
        link: "GlobalData - REWE Group Overview",
        url: "https://www.globaldata.com/company-profile/rewe-group/",
      },
      {
        link: "Statista - Rewe brand profile Germany 2022",
        url: "https://www.statista.com/statistics/1335745/rewe-grocery-stores-brand-profile-in-germany/",
      },
      {
        link: "Statista - Number of Rewe Group store branches in Germany 2007-2021",
        url: "https://www.statista.com/statistics/515956/rewe-group-store-branches-germany/",
      },
      {
        link: "Rewe Group - Home",
        url: "https://www.rewe-group.com/en/",
      },
      {
        link: "Rewe Group - Structure and Sales Lines",
        url: "https://www.rewe-group.com/en/company/structure-and-saleslines/rewe/",
      },
    ],
  },
  {
    cardTitle: "Intermarché",
    cardType: "Retailer Profile",
    cardSubTitle: "Hyper, Super, Express, Contact, Ecomarché, Netto",
    imageUrl: retailer4,
    country: "UK",
    totalCountryRevenueInBillion: "XX",
    numberOfCountryStores: "1800",
    retailerType: [
      { Hyper: true },
      { Super: true },
      { Discount: false },
      { Convenience: true },
      { Online: true },
      { Wholesale: false },
    ],
    description: [
      "Intermarché strives to differentiate from rivals with its strength in the fresh offer, high-quality private label and local/regional produce sourced via tight relationships with producers. It stores stores are independently owned under the overall Intermarché organization.",
    ],
    companyMotto: [
      "“Better Eating”",
      "“Helping our customers in their daily lives, allowing them to buy at the fairest price, anchoring themselves in local life, by forging partnerships and supporting associations.”",
    ],
    mainTargetCustomers: [
      "Families",
      "Health-conscious shoppers",
      "Variety Seekers",
    ],
    strategicPriorities: [
      {
        title: "1. Sustainability as a Genuine Differentiator",
        description: [
          "Being closer to its direct supply chain than many, Intermarché will work with the greater Agromousquetaires business to achieve tangible results. Its local production is another strong card in its hand, and it will look to paint a picture of its sustainability initiatives as part of its broader support for French producers.",
          "In-store visibility will manifest in new and expanded vrac stations offering a wider assortment of categories, as well as more space given to SME brands from stores’ local catchments. While these will not be able to deliver volumes at A-brand level, they may still occupy a larger share of shelf, especially in Supers and Express.",
        ],
      },
      {
        title: "2. Being a Price Leader",
        description: [
          "With inflation hitting in 2022, Intermarché is committed to protect shopper purchasing power and deter switching to perceived better-value alternatives. In terms of pricing, the retailer is most comparable to Système U. It is more expensive than Leclerc (consistently the most affordable mainstream grocery retailer in France), but on average is cheaper than Carrefour, Casino and Auchan.",
          "It recently revamped its loyalty offer to grant additional price-savings for low-income families shopping both in-store and via the Drive. Such schemes may be extended if household budgets face prolonged pressure into 2023.",
        ],
      },
      {
        title: "3. Digital Direction and In-store Experience",
        description: [
          "Under its Trade and Transformation strategy agreed in 2019, the implementation of the FabMag and POP store upgrades for Intermarché, respectively, are well underway, albeit slightly delayed due to the Covid crisis.",
          "Focus on Gen Z and younger shoppers. This is more pronounced now that rivals and ecommerce pure players can address those consumers via digital channels without need of a physical presence in traditional Intermarché heartlands like rural or provincial towns and regions.",
          "Initiatives like Franco-Score appear to have landed well with core customers. . It has made healthy eating a central pillar of its store and marketing transformation.",
        ],
      },
    ],
    brandsPerType: [
      { HyperStores: ["Intermarché Hyper"] },
      { SuperStores: ["Intermarché Super"] },
      {
        ConvenienceStores: ["Intermarché Contact", "Intermarché Express"],
      },
    ],
    EuropeanPresenceBeyondCountry: ["Belgium", "Portugal", "Poland", "Monaco"],
    sources: [
      { link: "Kantar 2022 : Retailer Profiles EU sales", url: "" },
      {
        link: "Article - Doing it better with Intermarché",
        url: "https://franchising.eu/article/89/doing-it-better-with-intermarche/",
      },
      {
        link: "Case Study - The success story of the supermarket chain “Intermarche”",
        url: "https://www.strategix.eu/the-success-story-of-the-supermarket-chain-intermarche/",
      },
    ],
  },
  {
    cardTitle: "Carrefour Groupe",
    cardType: "Retailer Profile",
    cardSubTitle: "Hyper, Super, Convenience, Online",
    imageUrl: retailer5,
    country: "FR",
    totalCountryRevenueInBillion: "XX",
    numberOfCountryStores: 10323,
    retailerType: [
      { Hyper: true },
      { Super: true },
      { Discount: false },
      { Convenience: true },
      { Online: true },
      { Wholesale: true },
    ],
    storesPerType: [
      { HyperStores: 703 },
      { SuperStores: 6605 },
      { ConvenienceStores: 2869 },
      { Wholesale: 146 },
    ],
    brandsPerType: [
      { HyperStores: ["Carrefour Hypermarket"] },
      { SuperStores: ["Carrefour Market"] },
      {
        ConvenienceStores: [
          "Carrefour Express",
          "Carrefour City",
          "Carrefour Contact",
          "Proxi",
          "Carrefour Bio",
        ],
      },
      { Wholesale: ["Promocash"] },
    ],
    description: [
      "Carrefour operates hypermarkets, supermarkets, convenience, discount, organic specialist stores and online. Carrefour offers a wide range of products, including groceries, electronics, clothing, and household items, catering to a wide range of customers from various demographics and income levels",
      "Carrefour's success is mainly attributed to its ability to offer excellent customer service and to align its products to market needs",
    ],
    companyMotto: [
      "Providing our customers with quality services, products and food that is accessible to all across all distribution channels",
    ],
    mainTargetCustomers: ["Families", "Everyday Consumers"],
    strategicPriorities: [
      {
        title: "1. Creating a Distinctive Offer",
        description: [
          "Carrefour aims to build a distinctive offer for shoppers, reflecting its ‘raison d’être’: to be the leader in Food Transition for all.",
          "Carrefour private label aims to represent 40% of food sales by 2026. In 2022 the figure was 33%.",
          "The removal of 2,600 tons of sugar, 250 tons of salt and 20 new controversial substances by 2026.",
          "Carrefour plans to support sustainable agriculture and more responsible consumption trends. To support these improvements, it will increase the number of partner producers to 50,000 by 2026.",
        ],
      },
      {
        title: "2. Omnichannel",
        description: [
          "As part of its transformation plan, the Carrefour Group is merging its physical stores with its online offering within a fluid, intuitive omnichannel ecosystem for its customers.",
          "By combining its global network of over 12,000 stores with an online shopping website unique to each country, we are enabling each customer to do their shopping when they want, where they want and how they want.",
          "In the Carrefour world, customers can place their orders from a PC or smartphone, get their shopping at the Drive pick-up point or by Click&Collect , or even have it delivered to their homes within the hour. With the new online services available in stores, customers can compare the prices and quality of products, reduce the time they spending waiting at the check-out and try out the new mobile payment solution, Carrefour Pay.",
          "Committed to expanding in the convenience and ecommerce channels. Carrefour plans to open 2,400 convenience stores by 2026, mainly through franchises.",
          "It aims to achieve €10 bn ecommerce GMV in 2026 and a €200m increase in recurring operating income from ecommerce activities in 2026 vs. 2021.",
          "The group’s objective is for ‘omnichannel customers to represent 30% of all Carrefour shoppers by 2026’.",
        ],
      },
      {
        title: "3. Food Transition",
        description: [
          "50% reduction in food waste in stores by 2025, versus 2016.",
          "100% reduction of in-store waste will be recovered by 2025.",
          "100% of private label’s products’ packaging to be reusable, recyclable or compostable by 2025.",
          "80% of catalogues in France will be digital by 2024.",
          "Becoming carbon neutral by 2040 and in 2030 for its ecommerce business.",
        ],
      },
    ],
    EuropeanPresenceBeyondCountry: [
      "France",
      "Spain",
      "Italy",
      "Belgium",
      "Poland",
      "Romania",
    ],
    sources: [
      {
        link: "Carrefour Store Types",
        url: "https://www.carrefour.com/en/group/stores",
      },
    ],
  },
  {
    cardTitle: "Auchan",
    cardType: "Retailer Profile",
    cardSubTitle: "Hyper, Super, Convenience, Online",
    imageUrl: retailer6,
    country: "FR",
    totalCountryRevenueInBillion: 67.9,
    numberOfCountryStores: 1350,
    retailerType: [
      { Hyper: true },
      { Super: true },
      { Discount: false },
      { Convenience: true },
      { Online: true },
      { Wholesale: false },
    ],
    storesPerType: [
      { HyperStores: 703 },
      { SuperStores: 6605 },
      { ConvenienceStores: 2869 },
      { Wholesale: 146 },
    ],
    brandsPerType: [
      { HyperStores: ["Auchan, Auchan Retail"] },
      { SuperStores: ["Auchan Retail"] },
      {
        ConvenienceStores: ["Auchan Retail"],
      },
      { Online: ["Auchan Retail"] },
    ],
    description: [
      "Auchan is a retail and distribution group that has been committed to retail for 55 years. The company is privately owned by the founding Mulliez family, and its shares are also open to its employees, who own 15% of the company. Auchan has a variety of company-owned brands, which are priced significantly lower than competing national and international brands. The company has developed new kinds of partnerships with small- and medium-sized companies, the agricultural sector, and the transportation sector. Auchan values sharing knowledge, sharing authority, and sharing the results. The company has a strong worldwide presence in over 12 countries, including France, Spain, Portugal, Luxembourg, Poland, Romania, Hungary, and Ukraine.",
    ],
    companyMotto: [
      `"Et la vie, vous l'aimez comment?" which translates to "How do you like life?"`,
      "Auchan is committed to helping its customers live better and providing food products that people in all the countries in which they operate can trust.",
    ],
    mainTargetCustomers: ["Families", "Everyday Consumers"],
    strategicPriorities: [
      {
        title: "1. Adapting to New Market Needs",
        description: [
          "Auchan has a business plan called 'Auchan 2022' which aims to adapt its model, offer, and organization to the new expectations of consumers and citizens.",
          "The organization and working methods are being adapted to be more attentive to new market needs and expectations.",
          "Auchan is committed to helping its customers live better and to providing food products that people in all the countries in which they operate can trust.",
        ],
      },
      {
        title: "2. Channel Aggregation",
        description: [
          "Auchan Holding's business operations include consolidating numerous distribution routes into one to achieve greater economic efficiency.",
        ],
      },
      {
        title: "3. Optimized On-Shelf Availability",
        description: [
          "Auchan has successfully trialed Neurolabs's “ReShelf” technology to monitor its shelves in real-time for optimized On-Shelf Availability (OSA).",
          "Auchan takes a very proactive approach when it comes to choosing the right system for improving OSA in their stores.",
        ],
      },
      {
        title: "4. Sustainable Practices",
        description: [
          "Auchan is committed to supporting sustainable agriculture and more responsible consumption trends.",
          "Auchan aims to reduce food waste in stores by 50% by 2025, versus 2016.",
          "Auchan aims to become carbon neutral by 2040 and in 2030 for its ecommerce business.",
          "Auchan aims to have 100% of private label’s products’ packaging to be reusable, recyclable or compostable by 2025.",
        ],
      },
    ],
    EuropeanPresenceBeyondCountry: [
      "France",
      "Spain",
      "Portugal",
      "Luxembourg",
      "Poland",
      "Romania",
      "Hungary",
      "Ukraine",
    ],
    sources: [
      {
        link: "Auchan's Commitments",
        url: "https://www.auchan-retail.com/en/our-commitments/",
      },
      {
        link: "Auchan's Values",
        url: "https://www.auchan-retail.com/en/",
      },
      {
        link: "Auchan 2022 Business Plan",
        url: "https://www.auchan-retail.com/en/with-its-auchan-2022-business-plan-auchan-retail-is-starting-to-adapt-its-model-its-offer-and-its-organisation-to-the-new-expectations-of-consumers-and-citizens/",
      },
      {
        link: "CASE STUDY: Auchan Innovates with Automated On-Shelf Availability",
        url: "https://neurolabs.medium.com/case-study-auchan-4a261834e339",
      },
      {
        Link: "Books - The Indignity of Grocery Shopping",
        url: "https://www.theatlantic.com/books/archive/2023/05/look-at-the-lights-my-love-annie-ernaux-book-review/674235/",
      },
    ],
  },
  {
    cardTitle: "ASDA (UK)",
    cardType: "Retailer Profile",
    cardSubTitle: "Super, Superstore, Living, Essentials, George",
    imageUrl: retailer7,
    country: "UK",
    totalCountryRevenueInBillion: 22.9,
    numberOfCountryStores: 633,
    retailerType: [
      { Super: true },
      { Superstore: true },
      { Discount: false },
      { Convenience: false },
      { Online: true },
      { Wholesale: false },
    ],
    description: [
      "ASDA is a UK supermarket chain that focuses on providing customers with quality products at affordable prices.",
    ],
    companyMotto: ["“Get the Asda price feeling”"],
    mainTargetCustomers: ["Budget-conscious shoppers", "Families"],
    strategicPriorities: [
      {
        title: "1. Expansion and Growth",
        description: [
          "ASDA aims to increase its number of conventional and online stores, and expand its online presence to reach more customers.",
          "The company is accelerating its push into the convenience market, with plans for 300 Express stores by 2026.",
          "ASDA is also focusing on enhancing customer experience, expanding its online presence, and improving store formats.",
        ],
      },
      {
        title: "2. Price Reduction",
        description: [
          "ASDA's business model consists of everyday low prices (EDLP) with the business focused on selling volume.",
          "The company is seeking more control over production/manufacturing and will dive into vertical integration if economy of scale benefits are evident.",
        ],
      },
      {
        title: "3. Environmental, Social, and Governance (ESG) Priorities",
        description: [
          "ASDA has identified five strategic priorities related to ESG issues, which are energy and climate change mitigation, food waste, plastic and packaging, healthy and sustainable diets, and community engagement.",
        ],
      },
      {
        title: "4. Membership Growth",
        description: [
          "ASDA aims to grow its membership through increased member engagement and satisfaction to inspire an invested membership.",
        ],
      },
      {
        title: "5. Digital Capabilities",
        description: [
          "ASDA is investing in a standardized IT infrastructure project across all operational markets to establish the basics of a platform that could eventually support an ecommerce division as well as underpin a digital loyalty system.",
          "The company is expanding its digital operations and fulfillment to attract more shoppers and missions.",
          "ASDA is using its digital platforms and social media presence to redefine its image and promote brand value to new audiences.",
        ],
      },
    ],
    EuropeanPresenceBeyondCountry: [
      "Ireland",
      "Scotland",
      "Wales",
      "Northern Ireland",
    ],
    sources: [
      { link: "IGD 2023 : Retailer Profiles EU sales", url: "" },
      {
        link: 'Statista, "Grocery market share in Great Britain 2017-2023"',
        url: "",
      },
      {
        link: 'Retail Economics, "Top 10 UK Food, Grocery & Supermarket Retailers"',
        url: "",
      },
      {
        link: 'ESM Magazine, "Top 10 Supermarket Retail Chains In The UK"',
        url: "",
      },
      {
        link: 'Wikipedia, "List of supermarket chains in the United Kingdom"',
        url: "",
      },
      {
        link: 'ASDA, "Corporate Responsibility Report 2022"',
        url: "",
      },
      {
        link: 'ASDA, "Annual Report and Financial Statements 2022"',
        url: "",
      },
    ],
  },
  {
    cardTitle: "Morrisons (UK)",
    cardType: "Retailer Profile",
    cardSubTitle: "Supermarket, Convenience, Online",
    imageUrl: retailer8,
    country: "UK",
    totalCountryRevenueInBillion: 17.6,
    numberOfCountryStores: 497,
    retailerType: [
      { Hyper: false },
      { Super: true },
      { Discount: false },
      { Convenience: true },
      { Online: true },
      { Wholesale: false },
    ],
    description: [
      "Morrisons is a UK supermarket chain that focuses on providing high-quality products and services to its customers.",
    ],
    companyMotto: ["Fresh Choice For You"],
    mainTargetCustomers: ["Families", "Foodies"],
    strategicPriorities: [
      {
        title: "1. Be more competitive",
        description: [
          "Morrisons has been focusing on cutting shoppers' prices to become more competitive.",
          "The company has been expanding its range of healthy food options, organic products, and products catering to specific dietary needs.",
        ],
      },
      {
        title: "2. Serve customers better",
        description: [
          "Morrisons has been focusing on listening to customers, improving capability, and stabilizing like-for-like sales.",
          "The company has been expanding its online capacity and extending routes to market, including partnerships with quick-commerce providers.",
        ],
      },
      {
        title: "3. Local solutions",
        description: [
          "Morrisons has been doubling the number of neighborhood and convenience store openings to 25-30 per year, focused on residential areas.",
        ],
      },
      {
        title: "4. Popular and useful services",
        description: [
          "Morrisons has been delivering on its 'Plan for Better' commitment by hitting targets for carbon emissions, healthy and sustainable diets, reducing food waste, and plastics.",
          "The company has been expanding its range of popular and useful services.",
        ],
      },
      {
        title: "5. Simply and speed up",
        description: [
          "Morrisons has been focusing on simplifying and speeding up its operations.",
        ],
      },
      {
        title: "6. Making core supermarkets strong again",
        description: [
          "Morrisons has been making its core supermarkets strong again.",
        ],
      },
      {
        title: "7. Sustainability",
        description: [
          "Morrisons has been committed to sustainability and has been delivering on its 'Plan for Better' commitment.",
        ],
      },
    ],
    EuropeanPresenceBeyondCountry: ["Gibraltar", "Spain"],
    sources: [
      { link: "IGD 2023 : Retailer Profiles EU sales", url: "" },
      {
        link: 'Statista, "Grocery market share in Great Britain 2017-2023"',
        url: "",
      },
      {
        link: 'Retail Economics, "Top 10 UK Food, Grocery & Supermarket Retailers"',
        url: "",
      },
      {
        link: 'ESM Magazine, "Top 10 Supermarket Retail Chains In The UK"',
        url: "",
      },
      {
        link: 'Wikipedia, "List of supermarket chains in the United Kingdom"',
        url: "",
      },
      {
        link: 'Morrisons, "Corporate Responsibility Report 2022"',
        url: "",
      },
      {
        link: 'Morrisons - Strategy"',
        url: "https://www.morrisons-corporate.com/about-us/strategy/",
      },
      {
        link: "Essay - Morrisons-company-current-strategies",
        url: "https://ivypanda.com/essays/morrisons-company-current-strategies/",
      },
      {
        link: "Article - Morrisons-makes-good-progress-on-six-priorities",
        url: "https://www.foodmanufacture.co.uk/Article/2016/09/29/Morrisons-makes-good-progress-on-six-priorities",
      },
    ],
  },
  {
    cardTitle: "Lidl (UK)",
    cardType: "Retailer Profile",
    cardSubTitle: "Discount, Online",
    imageUrl: retailer9,
    country: "UK",
    totalCountryRevenueInBillion: 6.9,
    numberOfCountryStores: 860,
    retailerType: [
      { Hyper: false },
      { Super: false },
      { Discount: true },
      { Convenience: false },
      { Online: true },
      { Wholesale: false },
    ],
    description: [
      "Lidl is a discount supermarket chain that focuses on providing customers with quality products at affordable prices.",
      "Lidl focuses on a low-price, high-quality proposition to deliver outstanding satisfaction to customers. Lidl’s assortment includes both own private label (~80%) and well-known branded goods (~20%) from the food and non-food sector. The model and high reliance on private label enables it to offer prices well below those of competitors.",
    ],
    companyMotto: [
      "“Big on Quality, Lidl on Price“",
      "“Enhancing the lives of our customers, by providing quality products at market leading value, whilst ensuring that customer satisfaction is at the heart of everything we do“",
    ],
    mainTargetCustomers: ["Budget-conscious shoppers", "Families"],
    strategicPriorities: [
      {
        title: "1. Improved Online Offering",
        description: [
          "Accelerating its ecommerce developments, Lidl's non-grocery online offer continues to grow, but Lidl still seems no nearer to an online grocery offer.",
          "Lidl offers grocery ecommerce in the US and tests concepts in some European markets but only very limited tests in partnership with locally-based specialists.",
          "As its value credentials are widely known, it will focus on driving shoppers to stores more often through impactful seasonal events, personalized offers and by leveraging its Lidl Plus app.",
        ],
      },
      {
        title: "2. Network Expansion and Modernization",
        description: [
          "The physical store remains Lidl’s most important asset. As such, it attracts continued investment from the retailer.",
          "Lidl continues to exert dominance across the European grocery market and has expanded across the Baltics into Estonia. In addition to the Baltics, the Western Balkans is also a targeted growth region. Target markets include Albania, Bosnia & Herzegovina, Kosovo, North Macedonia and Montenegro.",
          "In addition to evolving markets, Lidl will continue to look for growth and push presence in its more developed markets including Italy and France. Market development will also come from format diversification innovation i.e. urban stores.",
          "Lidl is updating stores and adding innovations like digital signage and ESLs to improve both the shopper experience and operational management.",
        ],
      },
      {
        title: "3. More Purposeful Assortment",
        description: [
          "The group leverages its businesses to not only maintain leadership in green retail but to develop innovative solutions that improve profitability, from owning the product packaging lifecycle (development of closed-loop systems) to enhancing its banners’ USP for conscious consumption.",
          "Lidl is becoming more responsible and encouraging customers to make more considered choices. Including developing healthier assortments, to local sourcing of goods and clear labelling to inform shoppers about product health attributes (Nutri-Score) or environmental impact (Eco-Score).",
        ],
      },
    ],
    EuropeanPresenceBeyondCountry: [
      "Ireland",
      "Spain",
      "Portugal",
      "France",
      "Belgium",
      "Netherlands",
      "Luxembourg",
      "Switzerland",
      "Denmark",
      "Sweden",
      "Norway",
      "Finland",
      "Poland",
      "Czechia",
      "Slovakia",
      "Hungary",
      "Romania",
      "Bulgaria",
      "Croatia",
      "Serbia",
      "Slovenia",
      "Italy",
      "Greece",
      "Cyprus",
      "Malta",
      "Lithuania",
      "Latvia",
      "Estonia",
    ],
    sources: [
      { link: "IGD 2023 : Retailer Profiles EU sales", url: "" },
      {
        link: 'Statista, "Grocery market share in Great Britain 2017-2023"',
        url: "",
      },
      {
        link: 'Retail Economics, "Top 10 UK Food, Grocery & Supermarket Retailers"',
        url: "",
      },
      {
        link: 'ESM Magazine, "Top 10 Supermarket Retail Chains In The UK"',
        url: "",
      },
      {
        link: 'Wikipedia, "List of supermarket chains in the United Kingdom"',
        url: "",
      },
      {
        link: 'Lidl, "Corporate Responsibility Report 2022"',
        url: "",
      },
      {
        link: 'Lidl, "Annual Report and Financial Statements 2022"',
        url: "",
      },
    ],
  },
  {
    cardTitle: "Aldi (UK)",
    cardType: "Retailer Profile",
    cardSubTitle: "Discount, Online",
    imageUrl: retailer10,
    country: "UK",
    totalCountryRevenueInBillion: 13.5,
    numberOfCountryStores: 920,
    retailerType: [
      { Hyper: false },
      { Super: false },
      { Discount: true },
      { Convenience: false },
      { Online: true },
      { Wholesale: false },
    ],
    description: [
      "Aldi is a discount supermarket chain that focuses on providing customers with quality products at affordable prices. Aldi targets a broad spectrum of shoppers. The business model and high reliance on private label enables it to offer prices well below those of competitors. Low prices are attractive to less affluent income groups, while high-quality non-food promotions still attract more affluent 'treasure hunt' shoppers.",
    ],
    companyMotto: [
      "“Everyday Amazing”",
      "“Providing value and quality to our customers by being fair and efficient in all we do.”",
    ],
    mainTargetCustomers: ["Budget-conscious shoppers", "Families"],
    strategicPriorities: [
      {
        title: "1. Differentiate and Maintain Productivity",
        description: [
          "Aldi’s strategic priority remains sustaining growth. To achieve this, it will look to both differentiate and defend its offer as competitive pressure increases, while maintaining its productivity model.",
          "Heightened internal synergies and international purchasing will enable a more uniform structure across all markets, making cross-border business easier and gaining synergies in marketing, quality control, purchasing, logistics and sourcing.",
          "~90% of Aldi’s portfolio is private label. This helps keep costs low and allows flexibility on pricing and margins. Aldi is seeking more control over production/manufacturing and will dive into vertical integration if economy of scale benefits are evident.",
        ],
      },
      {
        title: "2. Store Expansion and Urban Focus",
        description: [
          "Current market conditions are ripe for Aldi expansion as shoppers globally seek 'complete value’ from shopping trips.",
          "Aldi is sourcing sites to bring its offer to busy inner urban areas. Assortments are tailored to local missions, often smaller with more grab-and-go in the mix.",
          "Aldi is adopting a more flexible branch concept, so it can seamlessly fit into different living environments - whether as a standalone POS, in a retail park or in inner-city locations.",
          "Aldi will continue to consider M&A for quick store expansion as new space in mature markets is scarce.",
        ],
      },
      {
        title: "3. Digital Capabilities",
        description: [
          "Aldi is investing EUR1.5 billion into a standardized IT infrastructure project across all operational markets. The program aims to establish the basics of a platform that could eventually support an ecommerce division as well as underpin a digital loyalty system akin to the Lidl Plus app.",
          "Expansion of digital operations and fulfilment to attract more shoppers and missions. Fulfilment is a big part of Aldi’s nascent ecommerce agenda, and it is continuing to seek out third parties to provide online delivery.",
          "Aldi is using its digital platforms and social media presence to redefine its image and promote brand value to new audiences.",
        ],
      },
    ],
    EuropeanPresenceBeyondCountry: [
      "Ireland",
      "Spain",
      "Portugal",
      "France",
      "Belgium",
      "Netherlands",
      "Luxembourg",
      "Switzerland",
      "Denmark",
      "Sweden",
      "Norway",
      "Finland",
      "Poland",
      "Czechia",
      "Slovakia",
      "Hungary",
      "Romania",
      "Bulgaria",
      "Croatia",
      "Serbia",
      "Slovenia",
      "Italy",
      "Greece",
      "Cyprus",
      "Malta",
      "Lithuania",
      "Latvia",
      "Estonia",
    ],
    sources: [
      { link: "IGD 2023 : Retailer Profiles EU sales", url: "" },
      {
        link: 'Statista, "Grocery market share in Great Britain 2017-2023"',
        url: "",
      },
      {
        link: 'Retail Economics, "Top 10 UK Food, Grocery & Supermarket Retailers"',
        url: "",
      },
      {
        link: 'ESM Magazine, "Top 10 Supermarket Retail Chains In The UK"',
        url: "",
      },
      {
        link: 'Wikipedia, "List of supermarket chains in the United Kingdom"',
        url: "",
      },
      {
        link: 'Aldi, "Corporate Responsibility Report 2022"',
        url: "",
      },
      {
        link: 'Aldi, "Annual Report and Financial Statements 2022"',
        url: "",
      },
    ],
  },
];

export { retailerProfiles, retailerProfilesIntro };

import React, { useState } from "react";
import "./ReferenceCaseGenerator.css";

const ReferenceCaseGenerator = () => {
  const [company, setCompany] = useState("your organization");
  const [flightPlanSteps1to3, setFlightPlanSteps1to3] = useState([
    {
      stepNumber: "1.",
      stepTitle: "Challenge Definition",
      stepDescription: `First, we set a clear project end-goal to assure the right focus throughout the entire project. Additionally, clear evaluation criteria are defined to prioritize the identified solutions.`,
    },
    {
      stepNumber: "2.",
      stepTitle: "Market Research",
      stepDescription: `Then, internal data is gathered and analyzed to avoid unnecessary double work. After an initial analysis, existing knowledge gaps are identified and closed through additional web research and expert interviews.`,
    },
    {
      stepNumber: "3.",
      stepTitle: "Inspiration Creation",
      stepDescription: `Once research is done, all collected data is translated into inspiring videos, personas, consumer journeys, market profiles, technology overviews, interactive quizzes, and custom LLMs capable of informing and inspiring the ideation target audience.`,
    },
  ]);
  const [flightPlanSteps4to6, setFlightPlanSteps4to6] = useState([
    {
      stepNumber: "4.",
      stepTitle: "Online Ideation",
      stepDescription: `Then, a 3-week online brainstorm is launched which engages a wide range of colleagues - each with their unique set of expertise across ${company}’s value chain - resulting in a relevant set of ideas, capable of the tackling the predefined challenge.`,
    },
    {
      stepNumber: "5.",
      stepTitle: "1-Day Design Sprint",
      stepDescription: `During a focused 1-day design sprint, 
      the number of ideas gets further extended and later reduced to the most relevant concepts, each with a clear plan to turn these concepts into a proof-of-concept.`,
    },
    {
      stepNumber: "6.",
      stepTitle: "POC Development & Testing",
      stepDescription: `After the 1-day design sprint, the created proof-of-concept plans get transformed into tangible proof-of-concepts of goldilocks quality, which can be tested with the relevant target users.`,
    },
  ]);
  return (
    <div className='referenceCaseContainer'>
      <div className='referenceCaseFlightPlan'>
        <div className='referenceCaseFlightPlan-titleBlock'>
          <div className='referenceCaseFlightPlan-titleBlock-content'>
            <h1>
              Our
              <br />
              Methodology
            </h1>
            <p>
              Let's walk you through our step-wise approach to unlock the full
              creative potential of your entire organization.
            </p>
            <div className='titleBlockLineContainer'>
              <div className='horizontalLine'></div>
            </div>
          </div>
        </div>
        <div className='referenceCaseFlightPlan-steps step1to3'>
          <div className='verticalDottedLine step1to3 line1'></div>
          <div className='horizontalDottedLine step1to3 line2'></div>
          <div className='verticalDottedLine step1to3 line3'></div>
          <div className='horizontalDottedLine step1to3 line4'></div>
          <div className='verticalDottedLine step1to3 line5'></div>
          {flightPlanSteps1to3.map((step, index) => (
            <div
              className='referenceCaseFlightPlan-steps-stepContainer'
              key={index}
            >
              <div className='referenceCaseFlightPlan-steps-stepContainer-stepNumber'>
                {step.stepNumber}
              </div>
              <div className='referenceCaseFlightPlan-steps-stepContainer-stepDeviderLine'></div>
              <div className='referenceCaseFlightPlan-steps-stepContainer-stepContent'>
                <h5>{step.stepTitle}</h5>
                <p>{step.stepDescription}</p>
              </div>
            </div>
          ))}
        </div>
        <div className='referenceCaseFlightPlan-steps step4to6'>
          <div className='horizontalDottedLine step1to3 line6'></div>
          <div className='verticalDottedLine step1to3 line7'></div>
          {flightPlanSteps4to6.map((step, index) => (
            <div
              className='referenceCaseFlightPlan-steps-stepContainer'
              key={index}
            >
              <div className='referenceCaseFlightPlan-steps-stepContainer-stepNumber'>
                {step.stepNumber}
              </div>
              <div className='referenceCaseFlightPlan-steps-stepContainer-stepDeviderLine'></div>
              <div className='referenceCaseFlightPlan-steps-stepContainer-stepContent'>
                <h5>{step.stepTitle}</h5>
                <p>{step.stepDescription}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReferenceCaseGenerator;
